import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SideMenuItemPops } from "utils/types";
import { SideMenuLink } from "./SideMenuLink";
import { Wrapper } from "./styles";

interface SideMenuProps {
	list: SideMenuItemPops[];
	commonPath: string;
}

export const SideMenu = ({ list, commonPath }: SideMenuProps) => {
	const [isActive, setIsActive] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		setIsActive(pathname === commonPath ? true : false);
	}, [pathname, commonPath]);

	return (
		<Wrapper>
			{list.map(({ id, url, ...other }, index) => (
				<SideMenuLink
					key={id}
					url={`${commonPath + url}`}
					active={index === 0 && isActive}
					{...other}
				/>
			))}
		</Wrapper>
	);
};
