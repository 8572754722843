import { FormSectionDataProps } from "utils/types";
export const employeeInfo: FormSectionDataProps[] = [
	{
		id: "employee",
		title: "Данные сотрудника",
		inputs: [
			{
				id: 81,
				value: "",
				field: "fullName",
				label: "Ф.И.О",
				required: true,
			},
			{
				id: 82,
				value: "",
				field: "phone",
				label: "Телефон",
				mask: "+7 999 999 99 99",
				required: true,
			},
			{
				id: 83,
				value: "",
				field: "email",
				type: "email",
				label: "Почта",
				required: true,
			},
			{
				id: 84,
				value: "",
				field: "position",
				label: "Должность",
			},
			{
				id: 85,
				value: "",
				field: "department",
				label: "Отдел",
			},
		],
	},
	{
		id: "roles",
		title: "Роль",
		inputs: [
			{
				id: 31,
				value: [],
				field: "roles",
				type: "select",
				multiple: true, // @ts-ignore
				replaceName: true,
				placeholder: "Выберете роль",
				options: [
					{
						id: 1,
						value: 1,
						name: "Администратор",
					},
					{
						id: 2,
						value: 2,
						name: "Офис-менеджер",
					},
					{
						id: 3,
						value: 3,
						name: "Директор",
					},
					{
						id: 4,
						value: 4,
						name: "Член контрольной комиссии",
					},
					{
						id: 5,
						value: 5,
						name: "Секретарь заседания",
					},
					{
						id: 6,
						value: 6,
						name: "Член президиума",
					},
					{
						id: 7,
						value: 7,
						name: "Начальник юридического отдела",
					},
				],
				required: true,
			},
		],
	},
	{
		id: "contactPerson",
		title: "Данные руководителя",
		inputs: [
			{
				id: 91,
				value: "",
				field: "fullName",
				label: "Ф.И.О",
			},
			{
				id: 95,
				value: "",
				field: "phone",
				label: "Телефон",
				mask: "+7 999 999 99 99",
			},
		],
	},
];
