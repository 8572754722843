import { styled } from "@mui/material";
import { Box } from "@mui/material";

export const Container = styled(Box)`
	display: grid;
	gap: 30px;
	height: 100%;
`;

export const Content = styled(Box)`
	min-height: calc(100vh - 130px - 40px - 30px);
`;
