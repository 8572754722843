import { useState, useLayoutEffect, useEffect, SyntheticEvent } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { addTitlePage } from "utils/helpers";
import { useRequest } from "utils/hooks";
import { ErrorResult } from "components/ErrorResult";
import { NoАccess } from "components/NoАccess";
import { Preloader } from "components/Preloader";
import { Input, Button, Checkbox } from "components/FormElements";
import { BackIconButton } from "components/BackIconButton";
import { WhiteLayer } from "styles/common";
import { setRoleContent, useRoleContent } from "utils/store";
import { baseApiUrl } from "utils/constants";
import {
	InputDataProps,
	InputOnChange,
	FormSectionDataProps,
} from "utils/types";
import { ArrowIcon } from "assets/icons";
import {
	Сontent,
	NotFoundWrapper,
	ButtonWrapper,
	Header,
	CheckedList,
	EmptyList,
	InputWrapper,
	Permissions,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	ApplicationItem,
	Radio,
	CheckboxList,
} from "./styles";

export const RoleDetailPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(null);
	const [isNoAccess, setIsNoAccess] = useState(false);
	const dispatch = useDispatch();
	const content = useRoleContent();
	const request = useRequest;
	const { pathname } = useLocation();
	const linkParamId = pathname.split("/").at(-1);
	const apiUrl = `${baseApiUrl}/admin/users/roles/${linkParamId}/`;
	const [sectionsProps, setSectionsProps] = useState<FormSectionDataProps[]>(
		[],
	);
	const { enqueueSnackbar } = useSnackbar();
	const [expanded, setExpanded] = useState<string | false>("");
	const [activePanel, setActivePanel] = useState("");
	const [value, setValue] = useState("");

	useLayoutEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		sendRequest();
	}, []);

	useEffect(() => {
		setDefoultValues();
	}, [content]);

	const sendRequest = () => {
		request(apiUrl).then((res) => {
			const { data, status } = res;
			if (status < 400) {
				const newContent = {
					...content,
					...data,
				};
				addTitlePage(pathname, data.name);
				dispatch(setRoleContent(newContent));
				setTimeout(() => {
					setIsLoading(false);
				}, 250);
			} else {
				if (status === 404 || status === 500) {
					setIsError(status);
				} else {
					setIsNoAccess(true);
				}
				dispatch(setRoleContent(null));
			}
		});
	};

	const changeValue: InputOnChange = ({ field, value }) => {
		setSectionsProps(
			sectionsProps.map((section) => ({
				...section,
				inputs: section.inputs.map((item) =>
					field === item.field ? { ...item, value } : item,
				),
			})),
		);
	};

	const handleChange =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			if (panel !== activePanel) {
				const value = isExpanded ? panel : false;
				setExpanded(value);
				if (value) {
					setActivePanel(panel);
				}
			} else {
				setExpanded(false);
				setActivePanel("");
			}
		};

	const setDefoultValues = () => {
		if (content) {
			setValue(content.name);
			setSectionsProps(
				content.options.map((option) => ({
					id: option.codeName,
					title: option.name,
					inputs: option.permissions.map(({ id, codename, name, checked }) => ({
						id,
						label: name,
						field: codename,
						value: checked,
					})),
				})),
			);
		}
	};

	const sendChanges = () => {
		setIsLoading(true);
		let permissions: number[] = [];

		sectionsProps.map(({ inputs }) => {
			inputs.map(({ value, id }) => (value ? permissions.push(id) : value));
		});

		if (content)
			request(`${baseApiUrl}/admin/users/roles/${content.id}/`, {
				method: "PUT",
				data: {
					name: value,
					permissions,
				},
			})
				.then((res) => {
					const {
						data: { errors },
						status,
					} = res;
					if (status >= 200 && status < 300) {
						enqueueSnackbar("Данные успешно изменены!", {
							variant: "success",
						});
					} else {
						enqueueSnackbar("При отправке запроса возникла ошибка!", {
							variant: "error",
							description: errors[0] ? errors[0].message : "",
							autoHideDuration: null,
						});
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
	};

	return (
		<WhiteLayer>
			<Сontent>
				<Preloader active={isLoading}>
					{content ? (
						<>
							<Header>
								<BackIconButton />
								<Typography variant='h2' component='h2'>
									Редактировать роль
								</Typography>
							</Header>
							<InputWrapper>
								<Input
									id={1}
									field='name'
									value={value}
									onChange={({ value }) => setValue(String(value))}
								/>
							</InputWrapper>
							<Permissions>
								{sectionsProps.map((option) => (
									<Accordion
										expanded={expanded === option.id}
										onChange={handleChange(String(option.id))}>
										<AccordionSummary
											expandIcon={
												option.inputs.length > 0 ? <ArrowIcon /> : undefined
											}>
											<ApplicationItem
												control={
													<Radio
														disableRipple
														checkedIcon={<></>}
														icon={<></>}
													/>
												}
												value={option.id}
												label={
													<Typography variant='h4'>{option.title}</Typography>
												}
											/>
										</AccordionSummary>
										<AccordionDetails>
											<CheckboxList>
												{option.inputs.length > 0 &&
													option.inputs.map(({ field, id, label, value }) => (
														<Checkbox
															key={id}
															id={id}
															field={field}
															label={label} // @ts-ignore
															checked={value}
															onChange={changeValue}
														/>
													))}
											</CheckboxList>
										</AccordionDetails>
									</Accordion>
								))}
							</Permissions>
							<ButtonWrapper>
								<Button
									preloader={{ loading: isLoading }}
									disabled={isLoading}
									onClick={sendChanges}>
									Сохранить изменения
								</Button>
								<Button variant='outlined' onClick={setDefoultValues}>
									Отменить
								</Button>
							</ButtonWrapper>
						</>
					) : (
						<NotFoundWrapper>
							{isError ? (
								<ErrorResult code={isError} url={`/`} />
							) : (
								<NoАccess url={`/`} />
							)}
						</NotFoundWrapper>
					)}
				</Preloader>
			</Сontent>
		</WhiteLayer>
	);
};

export default RoleDetailPage;
