import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProps } from "utils/types";
import { RootState } from "../generateState";

interface UserSliceProps {
	userDetail: UserProps | null;
}

const initialState: UserSliceProps = {
	userDetail: null,
};

const slice = createSlice({
	name: "userDetail",
	initialState,
	reducers: {
		setUserDetail: (state, { payload }: PayloadAction<UserProps | null>) => {
			state.userDetail = payload;
		},
	},
});

export const { setUserDetail } = slice.actions;

export const selectUserDetail = (state: RootState) => state.userDetail;

export default slice.reducer;
