import { styled } from "@mui/material/styles";
import { Box, Link as LinkMui } from "@mui/material";
import { robotoMedium, robotoRegular } from "styles/fonts";
import { background, black, blue, grey, line } from "styles/colors";
import { rgba } from "styles/mixins";

export const Wrapper = styled(Box)`
	padding: 24px;
	background: ${background};
	border-radius: 10px;
`;

export const Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Content = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

export const TopBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const BottomBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const ImageWrapper = styled(Box)`
	position: relative;
	width: 38px;
	height: 38px;
	display: flex;
  flex-shrink: 0;
`;

export const Title = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 15px;
	line-height: 130%;
	color: ${black};
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 12px;
	line-height: 140%;
	color: ${grey};
`;

export const FilesList = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
	position: relative;
	margin-top: 12px;
`;

export const Link = styled(LinkMui)`
	&:hover {
		.link-title {
			&::before {
				background: ${blue};
			}
		}
	}
`;

export const Comment = styled(Box)`
	padding: 10px 14px;
	background: ${line};
	border-radius: 12px;
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${black};
	word-break: break-word;
`;
