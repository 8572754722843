import { ListItemContentProps } from "utils/types";
import { ArrowIcon, TrashcanIcon } from "assets/icons";
import { Status } from "components/Status";
import { IconButton } from "components/IconButton";
import { ApplicationItemInfo } from "components/ApplicationsList/ApplicationItemInfo";
import { Container, RightBlock, ChildLink } from "./styles";
import { UserItemInfo } from "components/UsersList/UserItemInfo";

interface ListItemContentComponentProps extends ListItemContentProps {
	size?: "small";
	light?: boolean;
	outdated?: boolean;
}

export const ListItemContent = ({
	date,
	url,
	status,
	id,
	content,
	size,
	type,
	light,
	outdated,
}: ListItemContentComponentProps) => {
	const {
		// @ts-ignore
		name, // @ts-ignore
		creator, // @ts-ignore
		fullName, // @ts-ignore
		email, // @ts-ignore
		phone, // @ts-ignore
		avatar, // @ts-ignore
		color, // @ts-ignore
		role, // @ts-ignore
		result,
	} = content;

	const info = { creator, name, id, date, status, size, result, outdated };
	const user = {
		fullName,
		email,
		phone,
		avatar,
		date,
		id,
		status,
		color,
		role,
	};
	const removeElement = (value: any) => {
		console.log("remove response", value);
	};

	return (
		<Container>
			{type === "user" ? (
				<UserItemInfo {...user} />
			) : (
				<ApplicationItemInfo {...info} light={light} />
			)}
			<RightBlock>
				{result && (
					<Status status={result} type='standart' size={size} rounded />
				)}
				{/* {remove && (
					<IconButton color='error' onClick={removeElement}>
						<TrashcanIcon />
					</IconButton>
				)} */}
				{url && (
					<IconButton className='arrow-button'>
						<ArrowIcon />
					</IconButton>
				)}
			</RightBlock>
		</Container>
	);
};
