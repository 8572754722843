import { profileAddresses } from "assets/data";
import { UserPageProps } from "utils/types";
import { ProfileForm } from "components/ProfileForm";

export const UserAddresses = ({ detail }: UserPageProps) => {
	return (
		<ProfileForm name='address' content={profileAddresses} detail={detail} />
	);
};

export default UserAddresses;
