import { ListLayout } from "components/ListLayout";

const ApplicationsPage = () => {
	return (
		<ListLayout
			title='Реестр заявок'
			url='applications'
			api='applications'
			type='applications'
		/>
	);
};

export default ApplicationsPage;
