import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface FormProps {
	layout?: string;
}

export const FormContent = styled(Box)``;

export const InputsContainer = styled(Box)`
	display: grid;
	grid-template-columns: ${({ layout }: FormProps) =>
		layout ? layout : `repeat(1, 1fr)`};
	grid-row-gap: 40px;
	grid-column-gap: 33px;
`;

export const ButtonContainer = styled(Box)`
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 24px;
`;
