import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addTitlePage } from "utils/helpers";
import { useAuthData } from "utils/store/hooks";
import { VerificationForm } from "components/Auth/VerificationForm";

const VerificationPage = () => {
	const authData = useAuthData();
	const navigate = useNavigate();
	const { state } = useLocation();
	const isRedirect = !authData || !state || !state.smsCodeActionTime;

	useEffect(() => {
		if (isRedirect) {
			navigate("/registration");
		}
		addTitlePage("/verification");
	}, []);

	return (
		<>
			{!isRedirect ? (
				<VerificationForm
					link='/registration'
					buttonName='Продолжить'
					params={{
						...authData,
						smsCodeActionTime: state.smsCodeActionTime,
						smsText: state.smsText,
					}}
				/>
			) : null}
		</>
	);
};

export default VerificationPage;
