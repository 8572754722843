import { Button } from "components/FormElements/Button";
import image404 from "assets/images/404.svg";
import image500 from "assets/images/500.svg";
import { Container, ImageWrapper, Text } from "./styles";
import { Typography } from "@mui/material";
import { LinkWrapper } from "components/Wrappers";

interface ErrorResultProps {
	url: string;
	title?: string;
	buttonName?: string;
	code: number;
}

export const ErrorResult = ({ url, code, title, buttonName }: ErrorResultProps) => {

	return (
	<Container>
		<ImageWrapper>
			<img src={code === 404? image404 : image500} alt='404' />
		</ImageWrapper>
		<Typography component='h2' variant='h2'>
			{title ? title : code === 404 ? "Этой страницы не существует!" : "Сервер не отвечает!"}
		</Typography>
		<Text>
			{code === 404
				? "Запрашиваемой страницы не существует! Возможно, она была удалена или в запросе был указан неверный адрес страницы"
				: "К сожалению, сервер не отвечает. Попробуйте чуть позже."
			}
		</Text>
		<LinkWrapper url={url}>
			<Button>{buttonName ? buttonName : "На главную"}</Button>
		</LinkWrapper>
	</Container>
)};

export default ErrorResult;
