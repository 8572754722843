import { FormSectionDataProps } from "utils/types";
export const profileInfo: FormSectionDataProps[] = [
	{
		id: "applicant",
		title: "Данные заявителя",
		inputs: [
			{
				id: 81,
				value: "",
				field: "fullName",
				label: "Ф.И.О представителя",
				required: true,
			},
			{
				id: 82,
				value: "",
				field: "email",
				type: "email",
				label: "Почта",
				required: true,
			},
			{
				id: 83,
				value: "",
				field: "phone",
				label: "Телефон кандидата",
				mask: "+7 999 999 99 99",
				required: true,
			},
		],
	},
	{
		id: "contactPerson",
		title: "Данные контактного лица",
		inputs: [
			{
				id: 91,
				value: "",
				field: "fullName",
				label: "ФИО контактного лица",
				required: true,
			},
			{
				id: 92,
				value: "",
				field: "position",
				label: "Должность контактного лица",
				required: true,
			},
			{
				id: 93,
				value: "",
				field: "cityPhone",
				label: "Городской телефон",
				mask: "+7 999 999 99 99",
			},
			{
				id: 94,
				value: "",
				field: "mobilePhone",
				label: "Мобильный телефон",
				mask: "+7 999 999 99 99",
			},
			{
				id: 95,
				value: "",
				field: "managerPhone",
				label: "Телефон руководителя",
				mask: "+7 999 999 99 99",
			},
		],
	},
];
