import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { Menu as MenuMui, MenuItem, Badge, Box } from "@mui/material";
import { robotoMedium, robotoRegular } from "styles/fonts";
import {
	white,
	black,
	background,
	liteGrey,
	red,
	line,
	grey,
	blue,
} from "styles/colors";

export const Wrapper = styled(Link)`
	border-bottom: 1px solid ${line};
	text-decoration: none;
	display: flex;
	width: 100%;
	position: relative;

	&:last-child {
		border-bottom: none;
	}
`;

export const Conteiner = styled(MenuItem)`
	display: grid;
	grid-template-columns: calc(100% - 44px) 34px;
	padding: 17px;
	width: 100%;
	position: relative;
	gap: 10px;

	&:hover {
		background: transparent;

		.list-item-content {
			color: ${blue};
		}

		.arrow-button {
			/* background: ${background}; */

			svg {
				stroke: ${blue};
			}
		}
	}
`;

export const LeftBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	position: relative;
`;

export const LeftTopBlock = styled(Box)`
	display: grid;
	grid-template-columns: auto auto;
	justify-content: flex-start;
	gap: 8px;
`;

export const Title = styled(Box)`
	font-family: ${robotoMedium};
	font-size: 15px;
	line-height: 20px;
	color: ${black};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	transition: color 0.25s ease-in-out;
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 20px;
	color: ${grey};
`;
