import { FormSectionDataProps } from "utils/types";
export const addEmployeeForm: FormSectionDataProps[] = [
	{
		id: "applicant",
		title: "",
		inputs: [
			{
				id: 11,
				value: "",
				field: "fullName",
				label: "ФИО",
				required: true,
			},
			{
				id: 12,
				value: "",
				field: "phone",
				label: "Телефон",
				mask: "+7 999 999 99 99",
				required: true,
			},
			{
				id: 13,
				value: "",
				field: "email",
				type: "email",
				label: "Электронная почта",
				required: true,
			},
			{
				id: 14,
				value: "",
				field: "position",
				label: "Должность",
			},
			{
				id: 15,
				value: "",
				field: "department",
				label: "Отдел",
			},
		],
	},
	{
		id: "password",
		title: "Пароль",
		inputs: [
			{
				id: 21,
				field: "password",
				placeholder: "Пароль",
				type: "password",
				required: true,
				value: "",
			},
			{
				id: 22,
				field: "repeatedPassword",
				placeholder: "Повторите пароль",
				type: "password",
				required: true,
				value: "",
				helperText: "Пароли не совпадают",
				invalid: false,
			},
		],
	},
	{
		id: "roles",
		title: "Роль",
		inputs: [
			{
				id: 31,
				value: [],
				field: "role",
				type: "select",
				multiple: true, // @ts-ignore
				replaceName: true,
				placeholder: "Выберете роль",
				options: [
					{
						id: 1,
						value: 1,
						name: "Администратор",
					},
					{
						id: 2,
						value: 2,
						name: "Офис-менеджер",
					},
					{
						id: 3,
						value: 3,
						name: "Директор",
					},
					{
						id: 4,
						value: 4,
						name: "Член контрольной комиссии",
					},
					{
						id: 5,
						value: 5,
						name: "Секретарь заседания",
					},
					{
						id: 6,
						value: 6,
						name: "Член президиума",
					},
					{
						id: 7,
						value: 7,
						name: "Начальник юридического отдела",
					},
				],
				required: true,
			},
		],
	},
];
