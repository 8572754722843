import { useEffect } from "react";
import { useCountdown } from "utils/hooks";

interface CountdownTimerProps {
	targetTime: number;
	beforeExpiry: () => void;
}

export const CountdownTimer = ({
	targetTime,
	beforeExpiry,
}: CountdownTimerProps) => {
	const [minutes, seconds] = useCountdown(targetTime);
	const fullSeconds = seconds < 10 ? `0${seconds}` : seconds;

	useEffect(() => {
		if (minutes + seconds === 0) {
			beforeExpiry();
		}
	}, [minutes, seconds]);

	return (
		<>
			{minutes > 0 ? minutes : 0}:{seconds > 0 ? fullSeconds : "00"}
		</>
	);
};
