import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import "@csstools/normalize.css";
import store from "utils/store/generateState";
import { Alert } from "components/Alert";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

declare module "notistack" {
	interface VariantOverrides {
		success: {
			description?: string;
		};
		error: {
			description?: string;
		};
	}
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Provider store={store}>
		<SnackbarProvider
			Components={{
				success: Alert,
				error: Alert,
			}}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			maxSnack={4}>
			<App />
		</SnackbarProvider>
	</Provider>,
);

reportWebVitals();
