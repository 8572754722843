import { ReactNode, useState } from "react";
import ScrollbarCustom from "react-scrollbars-custom";
import type { ScrollState } from "react-scrollbars-custom/dist/types/types";
import { liteGrey } from "styles/colors";
import { rgba } from "styles/mixins";

interface ScrollbarProps {
	children: ReactNode;
	height: number;
	width?: string;
	noScrollX?: boolean;
	inside?: boolean;
	noContentHeight?: boolean;
	onScrollEnd?: () => void;
}

export const Scrollbar = ({
	children,
	height,
	width,
	inside,
	noContentHeight,
	onScrollEnd,
	...other
}: ScrollbarProps) => {
	const [isContentHeight, setIsContentHeight] = useState<undefined | true>(
		undefined,
	);

	const onUpdate = (
		scrollValues: ScrollState,
		prevScrollValues: ScrollState,
	) => {
		if (prevScrollValues.scrollHeight !== scrollValues.scrollHeight) {
			setIsContentHeight(scrollValues.scrollHeight > height ? undefined : true);
		}
	};

	const onScrollStop = (scrollValues: ScrollState) => {
		const { scrollHeight, clientHeight, scrollTop } = scrollValues;
		if (scrollTop === scrollHeight - clientHeight) {
			if (onScrollEnd) onScrollEnd();
		}
	};

	return (
		<ScrollbarCustom
			onUpdate={noContentHeight ? undefined : onUpdate}
			onScrollStop={onScrollEnd ? onScrollStop : undefined}
			style={{
				width: width ? width : "100%",
				height,
			}}
			className={inside ? "inside" : ""}
			trackYProps={{
				style: {
					width: 6,
					height: "100%",
					top: 0,
					right: inside ? 2 : -6,
					background: rgba(liteGrey, 0.1),
				},
			}}
			translateContentSizeYToHolder={isContentHeight}
			thumbYProps={{ style: { background: liteGrey } }}
			{...other}>
			{children}
		</ScrollbarCustom>
	);
};

export default Scrollbar;
