import { useState } from "react";
import { Typography } from "@mui/material";
import { CloseIcon } from "assets/icons";
import { ParticipantProps, StatusTypes } from "utils/types";
import { declinationOfNumber } from "utils/helpers";
import { Popup } from "components/Popup";
import { ProtocolsPopup } from "components/ProtocolsPopup";
import { MarksIndicator } from "components/MarksIndicator";
import { AvatarLink } from "components/Avatar";
import {
	Conteiner,
	LeftBlock,
	RightBlock,
	AddButton,
	Participants,
	ParticipantItem,
	ImageWrapper,
} from "./styles";

interface MeetingParticipantsProps {
	participants: ParticipantProps[];
	maxCount?: number;
	id?: number;
	protocolType?: "commission_controls" | "presidium";
	isHeadExpert: boolean;
	statusType?: StatusTypes;
}

export const MeetingParticipants = ({
	participants,
	maxCount,
	id,
	protocolType,
	isHeadExpert,
	statusType,
}: MeetingParticipantsProps) => {
	const [showPopup, setShowPopup] = useState(false);
	const countParticipants = participants.length;

	return (
		<Conteiner>
			<LeftBlock>
				<Typography component='h3' variant='h3'>
					Участники заседания
				</Typography>
				<MarksIndicator
					count={participants.length}
					maxCount={maxCount}
					name={declinationOfNumber(participants.length, [
						"участник",
						"участника",
						"участников",
					])}
				/>
			</LeftBlock>
			<RightBlock>
				{countParticipants > 0 && (
					<Participants>
						{participants.map(({ avatar, color, id, fullName }) => (
							<ParticipantItem key={id}>
								<ImageWrapper title={fullName}>
									<AvatarLink
										image={avatar}
										color={color}
										name={fullName}
										id={id}
									/>
								</ImageWrapper>
							</ParticipantItem>
						))}
					</Participants>
				)}
				{isHeadExpert && (
					<>
						{(statusType === "commission_control_created" ||
							statusType === "commission_control_pending" ||
							statusType === "presidium_created" ||
							statusType === "presidium_pending") && (
							<AddButton onClick={() => setShowPopup(true)}>
								<CloseIcon />
							</AddButton>
						)}
						<Popup active={showPopup} afterClose={setShowPopup}>
							<ProtocolsPopup
								title='Добавить участников'
								id={id}
								countChecked={participants.length}
								maxCount={maxCount}
								url='participants'
								isMembers
								afterClose={setShowPopup} // @ts-ignore
								type={`${protocolType}`}
							/>
						</Popup>
					</>
				)}
			</RightBlock>
		</Conteiner>
	);
};

export default MeetingParticipants;
