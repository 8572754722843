export const black = "rgb(45, 49, 60)";
export const grey = "rgb(124, 133, 156)";
export const liteGrey = "rgb(181, 189, 208)";
export const line = "rgb(229, 232, 236)";
export const background = "rgb(243, 246, 250)";
export const white = "rgb(255, 255, 255)";
export const blue = "rgb(35, 95, 243)";
export const dopBlue = "rgb(75, 124, 244)";
export const liteBlue = "rgb(99, 170, 235)";
export const green = "rgb(98, 187, 66)";
export const red = "rgb(212, 68, 68)";
export const yellow = "rgb(211, 191, 3)";
export const purple = "rgb(187, 101, 255)";
