import { RoleProps } from "utils/types";

export const formatBytes = (bytes: number, decimals = 2) => {
	if (bytes === 0) {
		return "0";
	} else {
		var k = 1024;
		var dm = decimals < 0 ? 0 : decimals;
		var sizes = ["байт", "КБ", "МБ", "ГБ", "ТБ"];
		var i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	}
};

export const getPosition = (role: RoleProps[]) => {
	let position = "";

	role.map(({ name }, index) => {
		if (index === role.length - 1) {
			position = `${position} ${name}`;
		} else {
			position = `${position} ${name},`;
		}
	});

	return position;
};

export const declinationOfNumber = (number: number, wordForms: string[]) => {
	let n = Math.abs(number) % 100;
	let n1 = n % 10;

	if (n > 10 && n < 20) {
		return wordForms[2];
	}
	if (n1 > 1 && n1 < 5) {
		return wordForms[1];
	}
	if (n1 === 1) {
		return wordForms[0];
	}
	return wordForms[2];
};

export const convertDateToFormat = (date: string) => {
	const dateJs = new Date(date);
	const dateInFormat = dateJs.toLocaleDateString();

	return dateInFormat;
};

export const convertTimeToFormat = (date: string) => {
	const dateJs = new Date(date);
	const dateInFormat = dateJs.toLocaleTimeString([], {
		hour: "2-digit",
		minute: "2-digit",
	});

	return dateInFormat;
};
