import { profileInfo } from "./profileInfo";
import { profileMenu, detailMenu, adminMenu } from "./profileMenu";
import { profilePassword } from "./profilePassword";
import { profileAddresses } from "./profileAddresses";
import { profileCompany } from "./profileCompany";
import { employeeInfo } from "./employeeInfo";

export {
	profileMenu,
	detailMenu,
	adminMenu,
	profileInfo,
	profilePassword,
	profileAddresses,
	profileCompany,
	employeeInfo,
};
