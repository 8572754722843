import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { liteGrey } from "styles/colors";
import { robotoRegular } from "styles/fonts";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const ImageWrapper = styled(Box)`
	width: 100%;
	height: 287px;
	position: relative;
	margin-bottom: 56px;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Text = styled(Typography)`
	font-family: ${robotoRegular};
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: ${liteGrey};
	width: 480px;
	margin: 19px 0 24px;
`;
