import { useNavigate } from "react-router-dom";
import { BackIcon } from "assets/icons";
import { IconButton } from "components/IconButton";

interface BackIconButtonProps {
	link?: string;
}

export const BackIconButton = ({ link }: BackIconButtonProps) => {
	const navigate = useNavigate();
	const path = link ? link : -1;

	return (
		// @ts-ignore
		<IconButton className='arrow-button' onClick={() => navigate(path)}>
			<BackIcon />
		</IconButton>
	);
};
