import { NavLink } from "./styles";

interface NavMenuLink {
	url: string;
	title: string;
	icon: any;
}

export const NavMenuLink = ({ url, title, icon }: NavMenuLink) => {
	const Icon = icon;

	return (
		<NavLink to={url}>
			<Icon />
			{title}
		</NavLink>
	);
};
