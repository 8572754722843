import { robotoMedium } from "styles/fonts";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { blue, grey, black } from "styles/colors";

interface PolicyProps {
	auth: string;
}

export const Container = styled(Box)`
	padding: 0 30px;
	${({ auth }: PolicyProps) =>
		auth === "true" ? `width: 100%;` : "width: calc(100vw - 60px - 280px * 2);"}
`;

export const Content = styled(Box)`
	margin-top: 40px;
	color: ${grey};
	font-size: 15px;
	line-height: 130%;

	p {
		margin: 24px 0;
	}

	a {
		color: ${blue};
	}

	h3,
	h4,
	h5 {
		font-family: ${robotoMedium};
		font-weight: 500;
		margin-top: 24px;
		color: ${black};
	}
`;
