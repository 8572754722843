import { useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Typography } from "@mui/material";
import { ApplicationProps, StatusTypes } from "utils/types";
import { ApplicationsList } from "components/ApplicationsList";
import { AddButton } from "components/AddButton";
import { Popup } from "components/Popup";
import { ProtocolsPopup } from "components/ProtocolsPopup";
import { Text } from "styles/common";
import { Container, ListWrapper } from "./styles";

interface RelatedApplicationsProps {
	list: ApplicationProps[];
	isProtocol?: boolean;
	id?: number;
	protocolType: "commission_controls" | "presidium";
	showVote: boolean;
	isHeadExpert: boolean;
	statusType?: StatusTypes;
}

export const RelatedApplications = ({
	list,
	isProtocol,
	id,
	protocolType,
	showVote,
	isHeadExpert,
	statusType,
}: RelatedApplicationsProps) => {
	const [showPopup, setShowPopup] = useState(false);
	const isPresidium = protocolType === "presidium";

	return (
		<Container>
			<Typography component='h3' variant='h3'>
				{isPresidium ? "Вопросы повестки" : "Связанные заявки"}
			</Typography>
			<SwitchTransition mode='out-in'>
				<CSSTransition
					key={list.length > 0 ? "list" : "empty"}
					timeout={300}
					classNames='fade'>
					{list.length > 0 ? (
						<ListWrapper>
							<ApplicationsList
								content={list}
								protocol={
									protocolType
										? { type: protocolType, id, showVote }
										: undefined
								}
								size='small'
								light
								detail
							/>
						</ListWrapper>
					) : (
						<>{!isPresidium && <Text>Пока что нет связанных заявок</Text>}</>
					)}
				</CSSTransition>
			</SwitchTransition>

			{(isProtocol && isHeadExpert) && (
				<>
					{(statusType === "commission_control_created" || statusType === "presidium_created") && (
						<AddButton
							title={`Добавить ${isPresidium ? "ВП" : "заявку"}`}
							onClick={() => setShowPopup(true)}
						/>
					)}
					<Popup active={showPopup} afterClose={setShowPopup}>
						<ProtocolsPopup
							title={`Добавить ${isPresidium ? "вопросы повестки" : "заявки"}`}
							subtitle={`Список ${
								isPresidium ? "вопросов повестки" : "заявок"
							}`}
							id={id}
							search={isPresidium ? false : true}
							url={`${isPresidium ? "agenda-items" : "applications"}`}
							afterClose={setShowPopup}
							type={protocolType}
						/>
					</Popup>
				</>
			)}
		</Container>
	);
};

export default RelatedApplications;
