import { styled } from "@mui/material/styles";
import { Box, RadioGroup, Skeleton } from "@mui/material";
import { line, background, blue, black } from "styles/colors";
import { robotoMedium } from "styles/fonts";

export const Сontent = styled(Box)`
	width: 100%;
	min-height: calc(100vh - 130px - 60px);
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const NotFoundWrapper = styled(Сontent)`
	display: flex;
	align-items: center;
`;

export const Header = styled(Box)`
	display: flex;
	align-items: center;
	gap: 24px;
	margin-bottom: 32px;
`;

export const TitleSkeleton = styled(Skeleton)`
	height: 28px;
	width: 420px;
`;
export const Permissions = styled(RadioGroup)`
	display: flex;
	flex-direction: column;
	gap: 0;
`;

export const PermissionsWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px 16px;
	border-top: 1px solid ${line};

	.permission-title {
		.MuiFormControlLabel-label {
			font-family: ${robotoMedium} !important;
			font-size: 20px;
			line-height: 24px;
		}
	}
`;

export const Label = styled(Box)`
	color: ${black};
	font-family: ${robotoMedium};
	font-size: 14px;
	line-height: 130%;
`;

export const ListWrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	.notice-item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid ${line};

		.notice-item-wr {
			border-radius: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 20px 19px;

			svg {
				stroke: ${blue};
			}
		}

		&:first-of-type {
			.notice-item-wr {
				border-radius: 10px 10px 0 0;
			}
		}

		&:last-of-type {
			border-bottom: none;

			.notice-item-wr {
				border-radius: 0 0 10px 10px;
			}
		}
	}
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
	padding-top: 32px;
	border-top: 1px solid ${line};
`;
