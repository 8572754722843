import { FormSectionDataProps } from "utils/types";
export const applicationForm: FormSectionDataProps[] = [
	{
		id: "company",
		title: "Общие данные",
		dadata: "12",
		inputs: [
			{
				id: 12,
				value: "",
				field: "inn",
				type: "dadata",
				label: "ИНН",
				suggest: "party",
				minLength: 10,
				maxLength: 12,
				mask: "999999999999",
				required: true,
			},
			{
				id: 11,
				value: 1,
				field: "organizationForm",
				type: "radio",
				options: [
					{ id: 1, name: "Юр. лицо", value: 1 },
					{ id: 2, name: "ИП", value: 2 },
				],
			},
			{
				id: 14,
				value: "",
				field: "fullName",
				label: "Полное наименование юридического лица",
				required: true,
			},
			{
				id: 13,
				value: "",
				field: "managerPhone",
				label: "Телефон руководителя",
				mask: "+7 999 999 99 99",
				required: true,
			},
			{
				id: 15,
				value: "",
				field: "shortName",
				label: "Сокращенное наименование юридического лица",
				required: true,
				dateProps: {
					disableFuture: true,
				},
			},
			{
				id: 16,
				value: "",
				field: "ogrn",
				label: "ОГРН",
				minLength: 13,
				mask: "9999999999999",
				required: true,
			},
			{
				id: 17,
				value: "",
				field: "site",
				label: "Адрес сайта",
			},
		],
	},
	{
		id: "contactPerson",
		title: "Контактное лицо",
		inputs: [
			{
				id: 91,
				value: "",
				field: "fullName",
				label: "ФИО контактного лица",
				required: true,
			},
			{
				id: 92,
				value: "",
				field: "position",
				label: "Должность контактного лица",
				required: true,
			},
			{
				id: 93,
				value: "",
				field: "cityPhone",
				label: "Городской телефон контактного лица (с кодом)",
				mask: "+7 999 999 99 99",
			},
			{
				id: 94,
				value: "",
				field: "mobilePhone",
				label: "Мобильный телефон контактного лица",
				mask: "+7 999 999 99 99",
				required: true,
			},
		],
	},
	{
		id: "locationDocument",
		title: "Место нахождения согласно документам",
		inputs: [
			{
				id: 21,
				value: "",
				field: "region",
				label: "Регион / Район",
				required: true,
			},
			{
				id: 22,
				value: "",
				field: "city",
				label: "Город / Населенный пункт",
				required: true,
			},
			{
				id: 23,
				value: "",
				field: "street",
				label: "Улица (проспект, переулок и др.)",
				required: true,
			},
			{
				id: 24,
				value: "",
				field: "house",
				label: "Дом",
				required: true,
			},
			{
				id: 25,
				value: "",
				field: "office",
				label: "Офис",
			},
			{
				id: 26,
				value: "",
				field: "postcode",
				label: "Почтовый индекс",
				required: true,
			},
		],
	},
	{
		id: "locationMatches",
		title: "Фактическое местонахождение",
		inputs: [
			{
				id: 31,
				value: false,
				field: "locationMatches",
				type: "checkbox",
				label:
					"Фактическое местонахождение совпадает с местонахождением в докуметах",
			},
		],
	},
	{
		id: "locationActual",
		title: "Фактический адрес",
		dadata: "41",
		inputs: [
			{
				id: 41,
				value: "",
				field: "region",
				label: "Регион / Район",
				type: "dadata",
				suggest: "address",
				required: true,
			},
			{
				id: 42,
				value: "",
				field: "city",
				label: "Город / Населенный пункт",
				required: true,
			},
			{
				id: 43,
				value: "",
				field: "street",
				label: "Улица (проспект, переулок и др.)",
				required: true,
			},
			{
				id: 44,
				value: "",
				field: "house",
				label: "Дом",
				required: true,
			},
			{
				id: 45,
				value: "",
				field: "office",
				label: "Офис",
			},
			{
				id: 46,
				value: "",
				field: "postcode",
				label: "Почтовый индекс",
				required: true,
			},
		],
	},
	{
		id: "priceOneContract",
		title: "Стоимость осуществляемого строительства по одному договору",
		inputs: [
			{
				id: 51,
				value: [],
				field: "priceOneContract",
				type: "select",
				replaceName: true,
				placeholder: "",
				options: [],
				required: true,
			},
			{
				id: 52,
				value: false,
				field: "priceOneContractExtraParam",
				type: "checkbox",
				label:
					"в т.ч. особо опасных, технически сложных и уникальных объектов капитального строительства, за исключением объектов использования атомной энергии",
			},
		],
	},
	{
		id: "isParticipant",
		title:
			"Участие в заключении договоров строительного подряда с использованием конкурентных способов заключения договоров",
		inputs: [
			{
				id: 61,
				value: true,
				field: "isParticipant",
				type: "checkbox",
				label: "Принимать участие",
			},
		],
	},
	{
		id: "priceManyContract",
		title: "Стоимость договоров строительного подряда",
		inputs: [
			{
				id: 71,
				value: [],
				field: "priceManyContract",
				type: "select",
				placeholder: "",
				replaceName: true,
				options: [],
				required: true,
			},
			{
				id: 72,
				value: false,
				field: "priceManyContractExtraParam",
				type: "checkbox",
				label:
					"в т.ч. особо опасных, технически сложных и уникальных объектов капитального строительства, за исключением объектов использования атомной энергии",
			},
		],
	},
];

export const additionalApp = [
	{
		id: "date",
		title: "Дата оплаты",
		inputs: [
			{
				id: 11,
				value: "",
				field: "datePaid",
				label: "Дата оплаты",
				type: "date",
				required: true,
			},
		],
	},
	{
		id: "info",
		title: "Информация о взносах",
		inputs: [
			{
				id: 21,
				value: "",
				field: "entranceFee",
				label: "Вступительный взнос",
				// required: true,
			},
			{
				id: 22,
				value: "",
				field: "vvFee",
				label: "Взнос в компенсационный фонд ВВ",
				required: true,
			},
			{
				id: 23,
				value: "",
				field: "odoFee",
				label: "Взнос в компенсационный фонд ОДО",
				// required: true,
			},
			{
				id: 24,
				value: "",
				field: "monthlyFee",
				label: "Ежемесячный членский взнос",
				required: true,
			},
		],
	},
	{
		id: "comment",
		title: "Комментарий",
		inputs: [
			{
				id: 11,
				value: "",
				field: "comment",
				label: "Введите комментарий",
				textarea: { rows: 6, multiline: true },
				// required: true,
			},
		],
	},
];
