import { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import { InputOnChange, ListStandartProps } from "utils/types";
import { Select } from "components/FormElements";
import { Container, Adornment } from "./styles";

interface FilterProps {
	options: ListStandartProps[];
	name?: string;
	field: string;
	id: number;
	adornment?: ReactNode;
}

export const Filter = ({
	options,
	name,
	field,
	id,
	adornment,
}: FilterProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const filterValue = searchParams.get(field);
	const desiredOption = options.filter(({ value }) => value === filterValue);
	const defaultValue = desiredOption[0]?.name || options[0].name;

	const changeAppProps: InputOnChange = ({ value, field }) => {
		const param = JSON.parse(`${value}`).value;
		const currentParams = Object.fromEntries(
			// @ts-ignore
			[...searchParams].filter((item) => item[0] !== "page"),
		);
		setSearchParams({ ...currentParams, [field]: param });
	};

	return (
		<Container>
			<Select
				id={id}
				field={field}
				value={defaultValue}
				options={options}
				onChange={changeAppProps}
				startAdornment={name ? <Adornment>{name}</Adornment> : adornment}
				replaceName
				filter
			/>
		</Container>
	);
};

export default Filter;
