import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthDataProps } from "utils/types";
import { RootState } from "../generateState";

interface AuthDataSliceProps {
	authData: AuthDataProps | null;
}

const initialState: AuthDataSliceProps = {
	authData: null,
};

const slice = createSlice({
	name: "authData",
	initialState,
	reducers: {
		setAuthData: (state, { payload }: PayloadAction<AuthDataProps | null>) => {
			state.authData = payload;
		},
	},
});

export const { setAuthData } = slice.actions;

export const selectAuthData = (state: RootState) => state.authData;

export default slice.reducer;
