import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { formatBytes } from "utils/helpers";
import { BigCloseIcon, LoadUpIcon } from "assets/icons";
import { IconButton } from "components/IconButton";
import pdf from "assets/images/pdf.svg";
import docx from "assets/images/docx.svg";
import {
	Container,
	Content,
	LeftBlock,
	RightBlock,
	Title,
	Description,
	ImageWrapper,
	Text,
	ProgressValue,
	LinearProgress,
} from "./styles";

interface UploadItemProps {
	file: {
		type: string;
		name: string;
		size: number;
		date?: string;
		url?: string;
	};
	id: number;
	onRemove?: (id: number) => void;
	delay?: number;
}

export const UploadItem = ({
	file,
	id,
	onRemove,
	delay = 0,
}: UploadItemProps) => {
	const [progress, setProgress] = useState(0);
	const type = file.type.split("/").at(-1);
	const size = formatBytes(file.size, 1);
	let image;

	switch (type) {
		case "pdf":
			image = pdf;
			break;
		default:
			image = docx;
			break;
	}

	const removeElement = (event: any) => {
		if (onRemove) onRemove(id);
	};

	useEffect(() => {
		setTimeout(() => {
			setProgress((prevProgress) =>
				prevProgress < 100 ? prevProgress + 10 : 100,
			);
		}, delay + 300);
	}, []);

	useEffect(() => {
		if (progress < 100 && progress > 0) {
			setTimeout(() => {
				setProgress((prevProgress) => prevProgress + 10);
			}, 100);
		}
	}, [progress]);

	return (
		<Container delay={delay}>
			<Content>
				<LeftBlock>
					{image ? (
						<ImageWrapper>
							<img src={image} alt={file.name} />
						</ImageWrapper>
					) : null}
					<Description>
						<Title>{file.name}</Title>
						<Text show={`${progress >= 100}`}>
							<Typography>{size}</Typography>
						</Text>
					</Description>
				</LeftBlock>
				<RightBlock>
					<ProgressValue show={`${progress !== 100}`}>
						{progress}%
					</ProgressValue>
					{file.url ? (
						<IconButton>
							<LoadUpIcon />
						</IconButton>
					) : null}
					<IconButton color='error' onClick={removeElement}>
						<BigCloseIcon />
					</IconButton>
				</RightBlock>
			</Content>
			<LinearProgress
				variant='determinate'
				value={progress}
				show={`${progress <= 100}`}
			/>
		</Container>
	);
};
