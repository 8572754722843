import { DetailPageProps, TabsItemProps } from "utils/types";
import { ListItemDetail } from "components/ListItemDetail";

interface ApplicationDetailPageProps extends DetailPageProps {
	navigation?: TabsItemProps[];
}

export const ApplicationDetailPage = (props: ApplicationDetailPageProps) => {
	return <ListItemDetail type='application' {...props} />;
};

export default ApplicationDetailPage;
