import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useApplicationContent } from "utils/store/hooks";
import { useRequest } from "utils/hooks";
import { baseApiUrl } from "utils/constants";
import { convertDateToFormat, convertTimeToFormat } from "utils/helpers";
import { PencilIcon } from "assets/icons";
import { LinkWrapper } from "components/Wrappers";
import { IconButton } from "components/IconButton";
import { DatePopup } from "components/DatePopup";
import {
	Content,
	Table,
	TableItem,
	Title,
	Text,
	ValueLabel,
	TableItemValue,
} from "./styles";
import { getTypeByPath } from "utils/helpers";
import { setApplicationContent } from "utils/store";

export const DetailTabelInfo = () => {
	const { pathname } = useLocation();
	const { table, common, ...app } = useApplicationContent();
	const [showPopup, setShowPopup] = useState(false);
	const [popup, setPopup] = useState<{ date: string; title: string } | null>(
		null,
	);
	const [date, setDate] = useState("");
	const request = useRequest;
	const type = pathname.split("/");
	const dispatch = useDispatch();

	useEffect(() => {
		if (!showPopup) {
			setPopup(null);
		}
	}, [showPopup]);

	const getField = (value: string) => {
		let field;

		switch (value) {
			case "Время начала регистрации участников заседания":
				field = "register_start_time";
				break;
			case "Время окончания регистрации участников заседания":
				field = "register_end_time";
				break;
			case "Начало заседания":
				field = "start_time";
				break;
			case "Конец заседания":
				field = "end_time";
				break;
			default:
				field = "";
				break;
		}

		return field;
	};

	const changePopup = (date: string, title: string) => {
		setShowPopup(true);
		const newDate = date.replace(
			/(\d+).(\d+).(\d+) (\d+):(\d+)/,
			"$3.$2.$1 $4:$5",
		);
		setPopup({ date: newDate, title });
	};

	const sendDate = () => {
		if (popup && common) {
			const url = `${baseApiUrl}/${getTypeByPath(type[1])}/${common.id}/info/`;
			const field = getField(popup.title);

			let params = {
				method: "PATCH",
				data: {
					[field]: date,
				},
			};
			request(`${url}`, params).then((res) => {
				const {
					data: { table },
				} = res;

				setShowPopup(false);
				dispatch(setApplicationContent({ ...app, common, table }));
			});
		}
	};

	return (
		<Content>
			{table && table.length ? (
				<Table>
					{table.map(({ id, field, value, url, type }) => (
						<TableItem key={id}>
							<Title>{field}</Title>
							<Text
								className={url && `${value}`.length > 0 ? "link" : undefined}>
								<LinkWrapper
									url={
										url && `${value}`.length > 0
											? `https://${value}`
											: undefined
									}
									targetBlank>
									{typeof value !== "object" || value == null ? (
										<TableItemValue>
											{typeof value === "boolean" ? (
												<>{value ? "Да" : "Нет"}</>
											) : (
												<>
													{String(value).length > 0 &&
													value !== "-" &&
													value !== null
														? type === "datetime"
															? `${convertDateToFormat(
																	value,
															  )} ${convertTimeToFormat(value)}`
															: value
														: "—"}
												</>
											)}

											{type == "input" && (
												<IconButton onClick={() => changePopup(value, field)}>
													<PencilIcon />
												</IconButton>
											)}
										</TableItemValue>
									) : (
										value?.map((item) => (
											<div key={item.id}>
												<div>{item.name}</div>
												<ValueLabel>{item.label}</ValueLabel>
											</div>
										))
									)}
								</LinkWrapper>
							</Text>
						</TableItem>
					))}
					{popup && (
						<DatePopup
							title={popup.title}
							button='Сохранить'
							show={showPopup}
							afterClose={setShowPopup}
							date={popup.date}
							setDate={setDate}
							onSubmit={sendDate}
							hasTime={true}
						/>
					)}
				</Table>
			) : null}
		</Content>
	);
};

export default DetailTabelInfo;
