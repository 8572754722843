import styled from "styled-components";
import { mediumDesktop } from "styles/media";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 220px;

	@media ${mediumDesktop} {
		width: 252px;
	}
`;

export { Wrapper };
