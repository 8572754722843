import { Button } from "components/FormElements/Button";
import { LockFilledIcon } from "assets/icons";
import { Container, ImageWrapper } from "./styles";
import { Typography } from "@mui/material";
import { LinkWrapper } from "components/Wrappers";

interface NoАccessProps {
	url: string;
}

export const NoАccess = ({ url }: NoАccessProps) => (
	<Container>
		<ImageWrapper>
			<LockFilledIcon />
		</ImageWrapper>
		<Typography component='h2' variant='h2'>
			У вас нет прав доступа к этой странице!
		</Typography>
		<LinkWrapper url={url}>
			<Button>На главную</Button>
		</LinkWrapper>
	</Container>
);

export default NoАccess;
