import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { robotoMedium } from "styles/fonts";
import { background, green, grey } from "styles/colors";

export const Conteiner = styled(Box)`
	display: flex;
	align-items: center;

	&.indicator-chip {
		background: ${background};
		border-radius: 26px;
		padding: 10px 15px;
	}

	&.indicator-done {
		.indicator-text {
			color: ${green};
		}

		> svg {
			stroke: ${green};
			width: 12px;
			margin-right: 8px;
		}
	}

	> svg {
		stroke: ${grey};
		width: 0;
		transition: all 0.25s ease-in-out;
	}
`;

export const Text = styled(Box)`
	font-family: ${robotoMedium};
	font-size: 14px;
	line-height: 18px;
	color: ${grey};
	transition: all 0.25s ease-in-out;
`;
