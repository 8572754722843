import {
	NotificationsSettingsPage,
	NotificationDetailPage,
} from "./notificationsSettings";
import { RoleDetailPage, RolesPage } from "./roles";
import {
	ApplicationDetailPage,
	ApplicationCreatingPage,
	ApplicationsPage,
} from "./applications";
import { PageNotFound } from "./pageNotFound";
import { PrivacyPolicy } from "./privacyPolicy";
import {
	UserProfile,
	UserInfo,
	UserAddresses,
	UserPassword,
	UserCompany,
} from "./userProfile";

import MembersPage from "pages/members";
import Login from "pages/login";
import Registration from "pages/registration";
import PasswordRecovery from "pages/passwordRecovery";
import NewPasswordPage from "pages/newPassword";
import VerificationPage from "pages/verification";
import ActsPage from "./acts";
import AgendaPage from "./agenda";
import CandidatesPage from "./candidates";
import СommissionСontrolsPage from "./commissionСontrols";
import EmployeesPage from "./employees";
import ExpertOpinionsPage from "./expertOpinions";
import PresidiumPage from "./presidium";
import UserDetailPage from "./user";

export {
	ApplicationDetailPage,
	ApplicationCreatingPage,
	ApplicationsPage,
	PageNotFound,
	PrivacyPolicy,
	UserProfile,
	UserInfo,
	UserAddresses,
	UserPassword,
	UserCompany,
	MembersPage,
	Login,
	Registration,
	PasswordRecovery,
	PresidiumPage,
	NewPasswordPage,
	VerificationPage,
	ActsPage,
	AgendaPage,
	CandidatesPage,
	СommissionСontrolsPage,
	EmployeesPage,
	ExpertOpinionsPage,
	UserDetailPage,
	RolesPage,
	RoleDetailPage,
	NotificationsSettingsPage,
	NotificationDetailPage,
};
