import { FormEvent } from "react";
import { useRequest } from "utils/hooks";
import { ButtonPreloaderProps } from "utils/types";
import { Button } from "components/FormElements";
import { FormContent, InputsContainer, ButtonContainer } from "./styles";

interface FormProps {
	children: JSX.Element | JSX.Element[];
	buttonName?: string;
	layout?: string;
	afterSubmit: (data: any) => void;
	beforeSubmit?: () => void;
	values: any;
	url: string;
	method?: string;
	button?: JSX.Element | JSX.Element[];
	buttonProps?: {
		preloader?: ButtonPreloaderProps;
		disabled?: boolean;
		fullWidth?: boolean;
	};
	noSend?: boolean;
	autoComplete?: string;
}

export const Form = ({
	children,
	buttonName,
	layout,
	afterSubmit,
	values,
	url,
	method,
	button,
	beforeSubmit,
	buttonProps,
	noSend,
	autoComplete,
}: FormProps) => {
	const request = useRequest;

	const sendForm = (event: FormEvent<HTMLDivElement>) => {
		event.preventDefault();
		if (beforeSubmit) beforeSubmit();
		if (!noSend) {
			const options = {
				method: method ? method : "POST",
				data: values,
			};

			request(url, options).then((res) => {
				afterSubmit(res.data);
			});
		}
		if (noSend) {
			afterSubmit({});
		}
	};

	return (
		<FormContent
			component='form'
			onSubmit={(e) => sendForm(e)}
			// @ts-ignore
			autoComplete={autoComplete}>
			<InputsContainer layout={layout}>{children}</InputsContainer>

			{button ? (
				button
			) : (
				<ButtonContainer>
					<Button variant='contained' type='submit' {...buttonProps}>
						{buttonName}
					</Button>
				</ButtonContainer>
			)}
		</FormContent>
	);
};
