export { ReactComponent as BellIcon } from "./bell.svg";
export { ReactComponent as BellRingIcon } from "./bellring.svg";
export { ReactComponent as CaseIcon } from "./case.svg";
export { ReactComponent as ClipIcon } from "./clip.svg";
export { ReactComponent as EnvelopeIcon } from "./envelope.svg";
export { ReactComponent as EyeIcon } from "./eye.svg";
export { ReactComponent as HiddenEyeIcon } from "./hiddeneye.svg";
export { ReactComponent as LocationIcon } from "./location.svg";
export { ReactComponent as LockIcon } from "./lock.svg";
export { ReactComponent as LockFilledIcon } from "./lockfilled.svg";
export { ReactComponent as LoupeIcon } from "./loupe.svg";
export { ReactComponent as PencilIcon } from "./pencil.svg";
export { ReactComponent as PhotoIcon } from "./photo.svg";
export { ReactComponent as TrashcanIcon } from "./trashcan.svg";
