import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { line } from "styles/colors";

export const Container = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
`;

export const ListWrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	border-top: 1px solid ${line};
	border-bottom: 1px solid ${line};
`;
