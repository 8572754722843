import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { blue } from "styles/colors";
import { rgba } from "styles/mixins";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 32px;
`;

export const ImageWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 217px;
	height: 217px;
	border-radius: 50%;
	margin-bottom: 28px;
	background: ${rgba(blue, 0.1)};

	svg {
		width: 98px;
		height: 98px;
		stroke: ${blue};

		path:nth-of-type(1) {
			fill: ${blue};
		}
	}
`;
