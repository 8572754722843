import { TokensProps } from "utils/types";

export const setTokenFromStorage = (token: TokensProps) => {
	localStorage.setItem("token", JSON.stringify(token));
};

export const clearLocalStorage = () => localStorage.removeItem("token");

export const getTokensFromStorage = () => {
	const storage: string | null = localStorage.getItem("token");
	const token: TokensProps | null = storage ? JSON.parse(storage) : null;
	return token;
};
