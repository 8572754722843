import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { baseApiUrl } from "utils/constants";
import { ApplictionDetailInfoProps } from "utils/types";
import { useIsAppType, useIsRole } from "utils/hooks";
import { DownloadIcon } from "assets/icons";
import { Button } from "components/FormElements";
import { BackIconButton } from "components/BackIconButton";
import { Popup } from "components/Popup";
import { ApplicationItemInfo } from "components/ApplicationsList/ApplicationItemInfo";
import { ProtocolsPopup } from "components/ProtocolsPopup";
import { ResponsibleUser } from "components/ResponsibleUser";
import { PresidiumPopup } from "./PresidiumPopup";
import { Container, Content, LeftBlock } from "./styles";
import { Link } from "styles/common";

interface ApplicationCardHeaderProps
	extends ApplictionDetailInfoProps<"application"> {
	url: string;
	api: string;
}

export const ApplicationCardHeader = ({
	id,
	name,
	status,
	createdAt,
	fullName,
	curator,
	clerk,
	type,
	presidium,
	finalizePeriod,
	url,
	api,
	outdated,
}: ApplicationCardHeaderProps) => {
	const info = {
		id,
		creator: fullName,
		name,
		date: createdAt,
		status,
		type,
		finalizePeriod,
		outdated,
	};
	const [showPopup, setShowPopup] = useState(false);
	const [backLink, setBackLink] = useState("");
	const { state } = useLocation();
	const { isManager, isHeadExpert } = useIsRole();
	const {
		isCommissionProtocol,
		isAgenda,
		isPresidium,
		isVV,
		isOO,
		isODO,
		isTermination,
		isExtract,
	} = useIsAppType(type);
	const apiUrl = `${baseApiUrl}/${api}/${id}/get-pdf/`;

	const hasCurator = isODO || isOO || isVV || isTermination || isExtract;

	const getPdf = () => window.open(apiUrl, "_blank");

	useEffect(() => {
		setBackLink(state?.from ? state.from : `/${url}`);
	}, [state]);
	return (
		<Container>
			<BackIconButton link={backLink} />
			<Content>
				<ApplicationItemInfo {...info} titleSize='h2' />
				<LeftBlock>
					{hasCurator &&
						curator !== undefined &&
						!(curator === null && isManager) && (
							<ResponsibleUser user={curator} />
						)}
					{isAgenda && (
						<>
							{presidium ? (
								<Link
									to={`/protocols-presidium/${presidium.id}`}
									className='button-link'>
									{presidium.name}
								</Link>
							) : (
								<>
									<Button
										variant='outlined'
										onClick={() => setShowPopup(true)}
										secondary>
										Выбрать протокол президиума
									</Button>
									<Popup active={showPopup} afterClose={setShowPopup}>
										<PresidiumPopup afterClose={setShowPopup} id={id} />
									</Popup>
								</>
							)}
						</>
					)}
					{(isCommissionProtocol || isPresidium) && clerk && (
						<ResponsibleUser user={clerk} type='secretary' />
					)}
					<Button
						variant='outlined'
						icon={<DownloadIcon />}
						onClick={getPdf}
						secondary>
						PDF
					</Button>
					{hasCurator && isManager && curator === null && (
						<>
							<Button onClick={() => setShowPopup(true)} secondary>
								Назначить куратора
							</Button>
							<Popup active={showPopup} afterClose={setShowPopup}>
								<ProtocolsPopup
									afterClose={setShowPopup}
									id={id}
									title='Добавить куратора'
									type='applications'
									url='add-curator'
									isMembers
									listType='radio'
									defaultItem
								/>
							</Popup>
						</>
					)}
					{isPresidium && isHeadExpert && !clerk && (
						<>
							<Button onClick={() => setShowPopup(true)} secondary>
								Назначить секретаря
							</Button>
							<Popup active={showPopup} afterClose={setShowPopup}>
								<ProtocolsPopup
									afterClose={setShowPopup}
									id={id}
									title='Добавить секретаря'
									type={isPresidium ? "presidium" : "commission_controls"}
									url='clerk'
									listType='radio'
									defaultItem
									isMembers
								/>
							</Popup>
						</>
					)}
				</LeftBlock>
			</Content>
		</Container>
	);
};

export default ApplicationCardHeader;
