import jwtDecode, { JwtPayload } from "jwt-decode";
import { getTokensFromStorage } from "./localStorage";

interface JwtDecodeProps extends JwtPayload {
	user_id?: number;
}

export const getUserIdByJwt = (access?: string) => {
	const token = access ? access : getTokensFromStorage()?.access;
	let userId;

	if (token) {
		const data = jwtDecode<JwtDecodeProps>(token);
		if (data.user_id) {
			userId = data.user_id;
		}
	}

	return userId;
};
