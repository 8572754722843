import { UserAvatarProps } from "utils/types";
import { Avatar } from "components/Avatar";
import { User, Title, Text, NameBlock, Image } from "./styles";

interface ResponsibleUserProps {
	user: UserAvatarProps | null;
	type?: "curator" | "secretary";
}

export const ResponsibleUser = ({
	user,
	type = "curator",
}: ResponsibleUserProps) => {
	const getName = (name: string) => {
		const nameArr = name.split(" ");

		return `${nameArr[0]}${nameArr[1] ? ` ${nameArr[1][0]}.` : ""}${
			nameArr[2] ? ` ${nameArr[2][0]}.` : ""
		}`;
	};
	return (
		<User>
			<NameBlock>
				<Title>{user?.fullName ? getName(user.fullName) : "Не назначен"}</Title>
				<Text>{type === "curator" ? "Куратор" : "Секретарь заседания"}</Text>
			</NameBlock>
			<Image>
				<Avatar
					image={user ? user.avatar : null}
					name={user ? user.fullName : null}
					color={user ? user.color : undefined}
				/>
			</Image>
		</User>
	);
};

export default ResponsibleUser;
