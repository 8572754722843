import { useState, useEffect, useLayoutEffect, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Box, Typography } from "@mui/material";
import { baseApiUrl } from "utils/constants";
import { filterCheckedValues, declinationOfNumber } from "utils/helpers";
import { setApplicationContent } from "utils/store";
import { useRequest } from "utils/hooks";
import { CheckboxListTypes } from "utils/types";
import {
	Button,
	Search,
	CheckboxList,
	RadioGroup,
} from "components/FormElements";
import { Scrollbar } from "components/Scrollbar";
import { MarksIndicator } from "components/MarksIndicator";
import { Preloader } from "components/Preloader";
import {
	Container,
	CustomForm,
	ListWrapper,
	BottomBlock,
	Empty,
} from "./styles";
import { Text } from "styles/common";

interface ProtocolsPopupProps {
	title: string;
	subtitle?: string;
	countChecked?: number;
	maxCount?: number;
	search?: boolean;
	id?: number;
	url: string;
	afterClose: (value: boolean) => void;
	type?: "commission_controls" | "presidium" | "applications";
	listType?: "radio" | "checkbox";
	defaultItem?: boolean;
	buttonName?: string;
	isMembers?: boolean;
	method?: "PUT" | "POST";
	send?: (value: any) => void;
}

interface ValueProps {
	id: number | string;
}

export const ProtocolsPopup = ({
	title,
	subtitle,
	countChecked,
	search,
	id,
	url,
	afterClose,
	type,
	listType,
	maxCount,
	defaultItem,
	isMembers,
	buttonName = "Добавить",
	method = "PUT",
}: ProtocolsPopupProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isListLoading, setIsListLoading] = useState(false);
	const [list, setList] = useState<CheckboxListTypes | null>(null);
	const [countItems, setCountItems] = useState(0);
	const [values, setValues] = useState<ValueProps[]>([]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const request = useRequest;
	const indicator = countChecked !== undefined;
	const apiUrl = `${baseApiUrl}/${type ? `${type}/` : ""}${
		id ? `${id}/` : ""
	}${url}/`;
	const screenHeight = window.innerHeight;
	const isTermination = url === "users/sro/members/application";

	useLayoutEffect(() => {
		sendRequest(apiUrl);
		console.log("here");
	}, []);

	const sendRequest = (api: string, search?: boolean) => {
		setIsListLoading(true);
		request(api).then((res) => {
			const {
				data: { count, results },
			} = res;
			const newList = search || !list ? results : [...list, ...results];

			setCountItems(count);
			setList(newList);
			setValues(filterCheckedValues(newList, defaultItem));

			setTimeout(() => {
				setIsLoading(false);
				setIsListLoading(false);
			}, 250);
		});
	};

	const changeValue = (value: ValueProps[]) => {
		setValues(value);
	};

	const sendForm = (event: FormEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsButtonLoading(true);
		const options = {
			method,
			data: url === "clerk" || isTermination ? values[0] : values,
		};

		request(
			isTermination ? `${baseApiUrl}/applications/termination/` : apiUrl,
			options,
		).then((res) => {
			const { data, status } = res;
			closeSnackbar();

			if (status >= 200 && status < 300) {
				dispatch(setApplicationContent(data));

				setTimeout(() => {
					afterClose(false);
				}, 250);
			} else {
				const { errors } = data;

				enqueueSnackbar("При отправке запроса возникла ошибка!", {
					variant: "error",
					description: errors[0] ? errors[0].message : `Error ${status}`,
					autoHideDuration: null,
				});
			}
			setTimeout(() => {
				setIsButtonLoading(false);
			}, 250);
		});
	};

	const updateList = () => {
		if (list && countItems > list.length) {
			sendRequest(`${apiUrl}?offset=${list.length}`);
		}
	};

	const onSeach = (value: string) => {
		sendRequest(`${apiUrl}?search=${value}`, true);
	};

	return (
		<Container>
			<Box>
				<Typography component='h2' variant='h2' id='modal-title'>
					{title}
				</Typography>
			</Box>
			{search && (
				<Search
					onChange={onSeach}
					placeholder={`Введите номер или название ${
						isMembers ? "участника" : "заявки"
					}`}
				/>
			)}
			<Preloader active={isLoading}>
				<CustomForm component='form' onSubmit={(e) => sendForm(e)}>
					{subtitle && (
						<Typography component='h4' variant='h4'>
							{subtitle}
						</Typography>
					)}
					{list && (
						<Scrollbar
							height={screenHeight > 800 ? 430 : 390}
							onScrollEnd={updateList}
							noContentHeight>
							<ListWrapper>
								<Preloader active={isListLoading}>
									<SwitchTransition mode='out-in'>
										<CSSTransition
											key={list.length > 0 ? "list" : "empty"}
											timeout={300}
											classNames='fade'>
											{list.length > 0 ? (
												<>
													{listType === "radio" ? (
														<RadioGroup
															id={1}
															list={list}
															labelElement='member'
															onChange={({ value }) =>
																changeValue([{ id: Number(value) }])
															}
															field='curator'
														/>
													) : (
														<CheckboxList
															list={list}
															labelElement={`${
																isMembers ? "member" : "application"
															}`}
															maxCount={
																countChecked !== undefined
																	? maxCount
																	: undefined
															}
															onChange={changeValue}
														/>
													)}
												</>
											) : (
												<Empty>
													<Text style={{ textAlign: "center" }}>
														{`Подходящих ${
															isMembers ? "сотрудников" : "заявок"
														} не найдено`}
													</Text>
												</Empty>
											)}
										</CSSTransition>
									</SwitchTransition>
								</Preloader>
							</ListWrapper>
						</Scrollbar>
					)}
					<BottomBlock indicator={indicator ? `${true}` : undefined}>
						{indicator && (
							<MarksIndicator
								count={values.length}
								maxCount={maxCount}
								name={declinationOfNumber(values.length, [
									"участник",
									"участника",
									"участников",
								])}
								chip
							/>
						)}
						<Button
							type='submit'
							preloader={{ loading: isButtonLoading }}
							disabled={(list && list.length < 1) || isButtonLoading}>
							{buttonName}
						</Button>
					</BottomBlock>
				</CustomForm>
			</Preloader>
		</Container>
	);
};

export default ProtocolsPopup;
