import { useState, useLayoutEffect } from "react";
import {
	Outlet,
	useLocation,
	useParams,
} from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { layoutStyles } from "./styles";

const { Content } = layoutStyles;

interface LayoutContentProps {
	auth?: boolean;
}
export const LayoutContent = ({ auth }: LayoutContentProps) => {
	const [noTransition, setNoTransition] = useState(false);
	const location = useLocation();
	const params = useParams();
	const { key, hash, state, pathname } = location;
	const [prevPath, setPrevPath] = useState(pathname);

	useLayoutEffect(() => {
		const lastItem = pathname.split("/").at(-1);
		let transition =
			lastItem === "common" ||
			lastItem === "author" ||
			lastItem === "detail" ||
			lastItem === "related-applications" ||
			lastItem === "full-info" ||
			lastItem === "info" ||
			lastItem === "addresses" ||
			lastItem === "company" ||
			lastItem === "password";
		setNoTransition(hash.length > 0 || transition || params.id !== undefined);
		setPrevPath(pathname);
	}, [params]);

	return (
		<Content auth={auth}>
			<SwitchTransition>
				<CSSTransition
					key={key}
					classNames={noTransition ? "" : "fadeIn"}
					timeout={noTransition ? 0 : 250}>
					<Outlet />
				</CSSTransition>
			</SwitchTransition>
		</Content>
	);
};
