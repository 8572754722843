import { useState, useEffect } from "react";
import { useUserRole } from "utils/store";
import {
	memberRoleId,
	candidateRoleId,
	presidiumRoleId,
	commisionRoleId,
	headExpertRoleId,
	headLegalRoleId,
	managerRoleId,
	headAccountantRoleId,
	adminRoleId,
	curatorRoleId,
	monitoringSpecialistRoleId,
	clerkExpertRoleId,
	legalAdvisorRoleId,
} from "utils/constants";

export const useIsRole = () => {
	const roles = useUserRole();
	const [isEmployee, setIsEmployee] = useState(false);
	const [isMember, setIsMember] = useState(false);
	const [isCandidate, setIsCandidate] = useState(false);
	const [isHeadExpert, setIsHeadExpert] = useState(false);
	const [isHeadLegal, setIsHeadLegal] = useState(false);
	const [isHeadAccountant, setIsHeadAccountant] = useState(false);
	const [isMemberPresidium, setIsMemberPresidium] = useState(false);
	const [isMemberCommission, setIsMemberCommission] = useState(false);
	const [isCurator, setIsCurator] = useState(false);
	const [isManager, setIsManager] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isMonitoringSpecialist, setIsMonitoringSpecialist] = useState(false);
	const [isClerkExpert, setIsClerkExpert] = useState(false);
	const [isLegalAdvisor, setIsLegalAdvisor] = useState(false);

	useEffect(() => {
		if (roles)
			roles.map(({ id }) => {
				switch (id) {
					case memberRoleId:
						setIsMember(true);
						break;
					case candidateRoleId:
						setIsCandidate(true);
						break;
					case headExpertRoleId:
						setIsHeadExpert(true);
						break;
					case headLegalRoleId:
						setIsHeadLegal(true);
						break;
					case headAccountantRoleId:
						setIsHeadAccountant(true);
						break;
					case adminRoleId:
						setIsAdmin(true);
						break;
					case curatorRoleId:
						setIsCurator(true);
						break;
					case managerRoleId:
						setIsManager(true);
						break;
					case commisionRoleId:
						setIsMemberCommission(true);
						break;
					case presidiumRoleId:
						setIsMemberPresidium(true);
						break;
					case monitoringSpecialistRoleId:
						setIsMonitoringSpecialist(true);
						break;
					case clerkExpertRoleId:
						setIsClerkExpert(true);
						break;
					case legalAdvisorRoleId:
						setIsLegalAdvisor(true);
						break;
				}
			});
	}, [roles]);

	useEffect(() => {
		setIsEmployee(!(isCandidate || isMember));
	}, [isCandidate, isMember]);

	return {
		isEmployee,
		isHeadExpert,
		isHeadLegal,
		isHeadAccountant,
		isMemberPresidium,
		isMemberCommission,
		isCurator,
		isManager,
		isAdmin,
		isMember,
		isMonitoringSpecialist,
		isClerkExpert,
		isLegalAdvisor,
		isCandidate,
	};
};
