import { ListLayout } from "components/ListLayout";

const ExpertOpinionsPage = () => {
	return (
		<ListLayout
			title='Реестр экспертных заключений'
			url='expert-opinions'
			api='expert_opinions'
			type='applications'
		/>
	);
};

export default ExpertOpinionsPage;
