import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { mediumDesktop } from "styles/media";

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 100%;
`;

export const Content = styled(Box)`
	display: flex;
	gap: 30px;
	width: 100%;
	position: relative;
`;
export const Container = styled(Box)`
	width: calc(100% - 30px - 220px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media ${mediumDesktop} {
		width: calc(100% - 30px - 252px);
	}
`;
