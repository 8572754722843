import { styled } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { white, grey, line, liteGrey } from "styles/colors";
import { mediumDesktop } from "styles/media";

const Wrapper = styled(Box)`
	width: calc(100vw - 265px);
	padding: 7px 30px;
	background: ${white};
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${line};
	position: fixed;
	z-index: 100;

	@media ${mediumDesktop} {
		width: calc(100vw - 280px);
	}
`;

const ButtonsContainer = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Icon = styled(Box)`
	position: absolute;
	transform: translateX(3px);
	display: flex;

	svg {
		transition: all 0.25s ease-in-out;
		stroke: ${grey};
	}
`;

const Button = styled(IconButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 48px;
	height: 48px;
	background: ${white};
	padding: 0;
	border: 1px solid ${line};
	border-radius: 10px;
	transition: all 0.25s ease-in-out;
	cursor: pointer;

	&:hover {
		border: 1px solid ${liteGrey};
		background: white;
	}
`;

export { Wrapper, ButtonsContainer, Button, Icon };
