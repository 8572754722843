import { useEffect, useState } from "react";

export const useCountdown = (targetTime: number) => {
	const countDownDate = new Date(targetTime).getTime();

	const [countDown, setCountDown] = useState(
		countDownDate - new Date().getTime(),
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

	return [minutes, seconds];
};

export default useCountdown;
