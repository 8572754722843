import { RoleItem } from "components/RoleItem";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { blue, line, liteGrey } from "styles/colors";
import { robotoMedium } from "styles/fonts";

export const Container = styled(Box)`
	width: 100%;
	height: 100%;
`;

export const Empty = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const ImageWrapper = styled(Box)`
	width: 292px;
	height: 292px;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Text = styled(Typography)`
	font-family: ${robotoMedium};
	font-size: 18px;
	line-height: 130%;
	text-align: center;
	color: ${liteGrey};
	margin: 8px 0 32px;
`;

export const NotFoundWrapper = styled(Box)`
	display: flex;
	align-items: center;
	min-height: calc(100vh - 130px - 60px);
`;

export const TopBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
`;

export const ListWrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	.role-item {
		border-bottom: 1px solid ${line};

		.role-item-wr {
			border-radius: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 20px 19px;

			svg {
				stroke: ${blue};
			}
		}

		&:first-of-type {
			.role-item-wr {
				border-radius: 10px 10px 0 0;
			}
		}

		&:last-of-type {
			border-bottom: none;

			.role-item-wr {
				border-radius: 0 0 10px 10px;
			}
		}
	}
`;

export const Filters = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;
`;
