import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { formatBytes } from "utils/helpers";
import { FileAttachedProps } from "utils/types";
import { useRequest } from "utils/hooks";
import { baseApiUrl } from "utils/constants";
import { downloadResponse } from "utils/helpers";
import { BigCloseIcon, LoadUpIcon } from "assets/icons";
import { IconButton } from "components/IconButton";
import { LinkWrapper } from "components/Wrappers";
import pdf from "assets/images/pdf.svg";
import docx from "assets/images/docx.svg";
import {
	Container,
	Content,
	LeftBlock,
	RightBlock,
	Title,
	Description,
	ImageWrapper,
	Text,
	ProgressValue,
	LinearProgress,
} from "./styles";

interface UploadItemProps extends FileAttachedProps {
	type?: "download" | "uploade" | "link";
	onRemove?: (id: number) => void;
	delay?: number;
}

export const FileItem = ({
	id,
	name,
	size,
	createdAt,
	url,
	onRemove,
	type = "download",
	delay = 0,
}: UploadItemProps) => {
	const [progress, setProgress] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const request = useRequest;
	const documentType = name.split(".").at(-1);
	const fileSize = formatBytes(size, 1);
	const date = createdAt ? new Date(createdAt).toLocaleDateString() : null;
	let image;

	switch (documentType) {
		case "pdf":
			image = pdf;
			break;
		default:
			image = docx;
			break;
	}

	const removeElement = (event: any) => {
		if (onRemove) onRemove(id);
	};

	const fileDownload = () => {
		const config = {
			data: {},
			responseType: "blob",
		};

		request(`${baseApiUrl}/download/${id}/`, config).then(async (res) => {
			console.log(res, name)
			if (res.status === 200) {
				downloadResponse(res, name);
			} else {
        const data = JSON.parse(await res.data.text());

				enqueueSnackbar("При отправке запроса возникла ошибка!", {
					variant: "error",
					description: data.errors[0] ? data.errors[0].message : `Error ${res.status}`,
					autoHideDuration: null,
				});
			}
		});
	}

	useEffect(() => {
		setTimeout(() => {
			setProgress((prevProgress) =>
				prevProgress < 100 ? prevProgress + 10 : 100,
			);
		}, delay + 300);
	}, []);

	useEffect(() => {
		if (progress < 100 && progress > 0) {
			setTimeout(() => {
				setProgress((prevProgress) => prevProgress + 10);
			}, 100);
		}
	}, [progress]);

	return (
		<Container delay={delay} className={type}>
			<Content>
				<LinkWrapper
					className="file-download"
					onClick={type !== "uploade" ? fileDownload : undefined}
					>
					<LeftBlock>
						{image ? (
							<ImageWrapper className={type}>
								<img src={image} alt={name} />
							</ImageWrapper>
						) : null}
						<Description className={type}>
							<Title className={`${type}-title`}>{name}</Title>
							<Text
								show={`${progress >= 100 || type !== "uploade"}`}
								className={type}>
								{fileSize}
							</Text>
							{type === "download" ? (
								<Text show='true' className={type}>
									{date}
								</Text>
							) : null}
						</Description>
					</LeftBlock>
				</LinkWrapper>
				<RightBlock>
					{type === "uploade" && (
						<ProgressValue show={`${progress !== 100}`}>
							{progress}%
						</ProgressValue>
					)}
					{type !== "link" && url ? (
						<IconButton onClick={fileDownload}>
							<LoadUpIcon />
						</IconButton>
					) : null}
					{type === "uploade" && (
						<IconButton color='error' onClick={removeElement}>
							<BigCloseIcon />
						</IconButton>
					)}
				</RightBlock>
			</Content>
			{type === "uploade" && (
				<LinearProgress
					variant='determinate'
					value={progress}
					show={`${progress <= 100}`}
				/>
			)}
		</Container>
	);
};

export default FileItem;
