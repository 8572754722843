import { useState, useEffect, ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { TabsItemProps } from "utils/types";
import { Container, OneTab, Wrapper } from "./styles";

interface TabsProps {
	children: ReactNode;
	tabs: TabsItemProps[];
	changeParamUrl: (param: string) => void;
	commonPath: string;
}

export const Tabs = ({
	children,
	tabs,
	changeParamUrl,
	commonPath,
}: TabsProps) => {
	const { pathname, state } = useLocation();
	const [active, setActive] = useState<any>(1);

	const defineActive = (path: string) => {
		let splittedPath = path.split("/");
		const lastItem = splittedPath.at(-1);
		const tab = tabs.filter(({ param }) => lastItem === param);
		const activeTab = tab.length ? tab[0] : tabs[0];
		setActive(activeTab.id);
		return activeTab;
	};

	const tabToggle = (path: string) => {
		const element = defineActive(path);
		changeParamUrl(element.param);
	};

	useEffect(() => {
		if (tabs.length > 0) {
			defineActive(pathname);
		}
	}, [tabs]);

	useEffect(() => {
		if (tabs.length > 0) {
			tabToggle(pathname);
		}
	}, [pathname]);

	return (
		<Wrapper>
			<Container>
				{tabs.map(({ id, title, param }) => (
					<NavLink
						key={id}
						to={`${commonPath}/${param}`}
						state={{ preloader: false, commonPath, from: state?.from }}>
						<OneTab className={`${active === id && "tabs-active"}`}>
							<span>{title}</span>
						</OneTab>
					</NavLink>
				))}
			</Container>
			{children}
		</Wrapper>
	);
};
