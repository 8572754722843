import { Chip, styled } from "@mui/material";
import { background, grey, line } from "styles/colors";
import { robotoRegular } from "styles/fonts";

interface ContainerProps {
	small?: string;
}

export const Container = styled(Chip)`
	padding: 2px 8px 1px !important;
	background: ${background} !important;
	border: 1px solid ${line} !important;
	border-radius: 8px !important;

	.MuiChip-label {
		font-family: ${robotoRegular};
		font-size: ${({ small }: ContainerProps) =>
			small === "true" ? "12px" : "14px"};
		line-height: 140%;
		color: ${grey};
		padding: 0;
	}
`;
