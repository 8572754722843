import { useState, useEffect } from "react";
import { memberRoleId, candidateRoleId, adminRoleId } from "utils/constants";
import { navigation, navigation2 } from "assets/data/navigation";
import { useUser, useUserRole } from "utils/store";
import { Logotype } from "components/Logotype";
import { NavMenuLink } from "./NavMenuLink";
import { Wrapper, Navigation } from "./styles";

export const NavMenu = () => {
	const [isEmployee, setIsEmployee] = useState(false);
	const [access, setAccess] = useState<string[]>([]);
	const user = useUser();
	const roles = useUserRole();
	const list = isEmployee ? navigation : navigation2;

	useEffect(() => {
		if (roles)
			roles.map(({ id }) =>
				id !== candidateRoleId && id !== memberRoleId
					? setIsEmployee(true)
					: setIsEmployee(false),
			);
	}, [roles]);



	useEffect(() => {
		if (user?.navigation) setAccess(user.navigation);
	}, [user?.navigation]);

	return (
		<Wrapper>
			<Logotype />
			{user ? (
				<Navigation>
					{list.map(({ id, codename, ...other }) => 
						access.map(item => {
							if (item === codename) {
								return (<NavMenuLink key={id} {...other} />)
							}
						}
						))}
				</Navigation>
			) : null}
		</Wrapper>
	);
};
