import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
`;
export const UploadList = styled(Box)`
	width: 100%;
`;
