import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { rgba } from "styles/mixins";
import { red, blue, green, liteGrey, background } from "styles/colors";

export const IconButtonMui = styled(IconButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 34px;
	transition: all 0.25s ease-in-out;
	padding: 8px !important;

	&:hover {
		background: ${({ color }) =>
			color === "error" ? `${rgba(red, 0.1)}` : `${background}`};
		svg {
			stroke: ${({ color }) => (color === "error" ? `${red}` : `${blue}`)};
		}
	}

	svg {
		stroke: ${liteGrey};
		transition: all 0.25s ease-in-out;
	}
`;
