import { useState, useEffect } from "react";
import { ErrorResult } from "components/ErrorResult";
import { Preloader } from "components/Preloader";
import { Wrapper, Container } from "./styles";

export const PageNotFound = () => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 250);
	}, []);

	return (
		<Wrapper>
			<Preloader active={isLoading}>
				<Container>
					<ErrorResult code={404} url='/' buttonName='На главную' />
				</Container>
			</Preloader>
		</Wrapper>
	);
};

export default PageNotFound;
