import logo from "assets/images/logo.svg";
import { Wrapper, Image, ImageLink } from "./styles";

export const Logotype = () => (
	<Wrapper>
		<ImageLink to='/'>
			<Image src={logo} alt='Логотип ГПС' />
		</ImageLink>
	</Wrapper>
);
