import { ApplicationDetailType } from "utils/types";
import { ColBlock, LeftBlock } from "components/UserItem/styles";
import { Container } from "components/ApplicationCard/ApplicationCardHeader/styles";
import { Button, Avatar, Chip1, Chip2, Title1, Title2 } from "./styles";

interface HeaderSkeletonProps {
	type: ApplicationDetailType;
}

export const HeaderSkeleton = ({ type }: HeaderSkeletonProps) => {
	return (
		<>
			{type === "user" ? (
				<LeftBlock>
					<Button variant='circular' />
					<Avatar variant='circular' />
					<ColBlock>
						<Title2 variant='rounded' />
						<LeftBlock>
							<Chip1 variant='rounded' />
							<Chip2 variant='rounded' />
						</LeftBlock>
					</ColBlock>
				</LeftBlock>
			) : (
				<Container>
					<Button variant='circular' />
					<ColBlock>
						<Title1 variant='rounded' />
						<LeftBlock>
							<Chip1 variant='rounded' />
							<Chip2 variant='rounded' />
						</LeftBlock>
					</ColBlock>
				</Container>
			)}
		</>
	);
};

export default HeaderSkeleton;
