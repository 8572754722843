import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { blue } from "styles/colors";
import { robotoRegular } from "styles/fonts";

export const Container = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0;
`;

export const RightBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const ChildLink = styled(Link)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${blue};
	text-decoration: none;
	position: relative;

	&::before {
		content: "";
		display: block;
		position: absolute;
		bottom: 1px;
		width: 100%;
		height: 1px;
		background: ${blue};
		opacity: 0;
		transition: all 0.25s ease-in-out;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}
`;
