export const titles = [
	{
		id: 1,
		title: "Кандидаты в члены СРО",
		url: "/candidates",
	},
	{
		id: 2,
		title: "Члены СРО",
		url: "/members",
	},
	{
		id: 3,
		title: "Заявки",
		url: "/applications",
	},
	{
		id: 4,
		title: "Сотрудники",
		url: "/staff",
	},
	{
		id: 5,
		title: "Экспертные заключения",
		url: "/expert-opinions",
	},
	{
		id: 6,
		title: "Протоколы контрольной коммисии",
		url: "/protocols-control-commission",
	},
	{
		id: 7,
		title: "Протоколы призидиума",
		url: "/protocols-presidium",
	},
	{
		id: 8,
		title: "Вопросы повестки",
		url: "/questions-agenda",
	},
	{
		id: 9,
		title: "Aкты для выписки",
		url: "/aсts-issued",
	},
	{
		id: 10,
		title: "Роли",
		url: "/roles",
	},
	{
		id: 11,
		title: "Уведомления",
		url: "/notifications",
	},
	{
		id: 12,
		title: "Профиль",
		url: "/profile",
	},
	{
		id: 13,
		title: "Вход",
		url: "/login",
	},
	{
		id: 14,
		title: "Регистрация",
		url: "/registration",
	},
	{
		id: 15,
		title: "Политика конфеденциальности",
		url: "/privacy-policy",
	},
	{
		id: 16,
		title: "Восстановление пароля",
		url: "/login/password-pecovery",
	},
	{
		id: 17,
		title: "Создать пароль",
		url: "/create-password",
	},
	{
		id: 18,
		title: "Подтверждение номера",
		url: "/verification",
	},
];
