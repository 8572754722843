import { UserProfile, UserInfo, UserPassword, UserDetailPage } from "pages";
import { DetailTabelInfo } from "components/DetailTabelInfo";
import { ListLayout } from "components/ListLayout";
import { PrivateRoute } from "../PrivateRoute";

export const staff = [
	{
		path: "/staff/:id",
		id: 131,
		element: (
			<PrivateRoute>
				<UserDetailPage url='staff' api='users/sro' />
			</PrivateRoute>
		),
		children: [
			{
				path: "related-applications",
				id: 131,
				element: (
					<PrivateRoute>
						<ListLayout
							type='applications'
							url='staff'
							api='users/sro'
							detail={true}
						/>
					</PrivateRoute>
				),
			},
			{
				path: "full-info",
				id: 132,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
];

export const staffForAdmin = [
	{
		path: "/staff/:id",
		id: 222,
		element: (
			<PrivateRoute>
				<UserProfile detail admin />
			</PrivateRoute>
		),
		children: [
			{
				path: "info",
				id: 2223,
				element: (
					<PrivateRoute>
						<UserInfo detail admin />
					</PrivateRoute>
				),
			},
			{
				path: "password",
				id: 2226,
				element: (
					<PrivateRoute>
						<UserPassword detail admin />
					</PrivateRoute>
				),
			},
			{
				path: "related-applications",
				id: 2226,
				element: (
					<PrivateRoute>
						<ListLayout
							type='applications'
							url='applications'
							api='users/sro'
							detail={true}
						/>
					</PrivateRoute>
				),
			},
		],
	},
];
