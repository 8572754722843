import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { robotoRegular } from "styles/fonts";
import { grey, black, blue, white, background, line } from "styles/colors";

export const NavLink = styled(RouterLink)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	text-decoration: none;
	color: ${grey};
	padding: 12px 30px;
	display: flex;
	align-items: center;
	background: ${white};
	position: relative;
	width: calc(100% + 1px);
	border-right: 1px solid ${line};
	margin-right: -1px;
	transition: all 0.25s ease-in-out;

	&:hover,
	&.active {
		color: ${black};
		border-right: 1px solid ${blue};

		svg {
			stroke: ${black};
		}
	}

	&.active {
		background: ${background};
	}

	svg {
		width: 20px;
		height: 20px;
		stroke: ${grey};
		margin-right: 8px;
		transition: all 0.25s ease-in-out;
	}
`;
