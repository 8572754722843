import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { robotoMedium } from "styles/fonts";
import { black } from "styles/colors";

export const Conteiner = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`;

export const Label = styled(Box)`
	color: ${black};
	font-family: ${robotoMedium};
	font-size: 14px;
	line-height: 130%;
`;

export const CheckboxList = styled(Box)`
	display: grid;
	width: calc(100% - 100% / 3);
	grid-template-columns: repeat(2, 1fr);
	gap: 6px 40px;
`;

export const RadioList = styled(Box)`
	.select-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 6px 40px;
	}
`;
