import { ResultProps, ResultType } from "utils/types";
import { PauseIcon, CloseIcon, CheckIcon } from "assets/icons";
import { Container, IconWrapper, Helper, Text, Block } from "./styles";

const setIconByType = (type: ResultType) => {
	let icon;

	switch (type) {
		case "deny":
		case "canceled":
			icon = CloseIcon;
			break;
		case "accept":
			icon = CheckIcon;
			break;
		default:
			icon = PauseIcon;
			break;
	}
	return icon;
};

export const Solution = ({ name, type }: ResultProps) => {
	const Icon = setIconByType(type);

	return (
		<Container className={type}>
			<Block>
				<IconWrapper>
					<Icon />
				</IconWrapper>
				<Text>{name}</Text>
			</Block>
			<Helper>Решение</Helper>
		</Container>
	);
};
