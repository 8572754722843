import { AddRoundIcon } from "assets/icons";
import { Button } from "components/FormElements/Button";

interface AddButtonProps {
	title: string;
	onClick?: (value: any) => void;
	disabled?: boolean;
}

export const AddButton = ({ title, disabled, onClick }: AddButtonProps) => {
	return (
		<Button
			icon={<AddRoundIcon />}
			onClick={onClick}
			disabled={disabled}
			secondary>
			{title}
		</Button>
	);
};
