import { useSnackbar } from "notistack";
import { useState, FormEvent } from "react";
import { useNavigate } from "react-router";
import { Typography, FormControl } from "@mui/material";
import { extractInputs } from "assets/data/applicationsTypes";
import { baseApiUrl } from "utils/constants";
import { useRequest } from "utils/hooks";
import { InputOnChange, InputDataProps } from "utils/types";
import { getValueFromArray, addedErrorOnField } from "utils/helpers";
import { Button, InputsSpreader } from "components/FormElements";
import { Container, CustomForm, ExtractContent } from "./styles";

export const CreationExtract = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [inputProps, setInputProps] = useState<InputDataProps[]>(extractInputs);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const request = useRequest;
	const url = `${baseApiUrl}/applications/member/`;

	const changeHandle: InputOnChange = ({ field, value }) => {
		const newInputProps = inputProps.map((item) =>
			field === item.field ? { ...item, value, invalid: false } : item,
		);

		setInputProps(newInputProps);
	};

	const sendForm = (event: FormEvent<HTMLDivElement>) => {
		event.preventDefault();

		setIsLoading(true); // @ts-ignore
		const values = getValueFromArray(inputProps);
		const options = {
			method: "POST",
			data: { type: "extract", ...values },
		};

		request(url, options).then((res) => {
			const { data, status } = res;
			console.log("here", status, data);
			if (status >= 200 && status < 300) {
				setTimeout(() => {
					navigate(`/applications/${data.id}`);
				}, 250);
			} else {
				const { errors } = data;
				if (errors && errors.length > 0) {
					if (errors[0].field === "non_field_errors") {
						enqueueSnackbar("При отправке запроса возникла ошибка!", {
							variant: "error",
							description: errors[0] ? errors[0].message : "",
							autoHideDuration: null,
						});
					} else {
						setInputProps(addedErrorOnField(errors, inputProps));
					}
				}
			}
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		});
	};

	return (
		<Container className='small'>
			<Typography component='h2' variant='h2' id='modal-title'>
				Заявка на получение выписки из реестра членов СРО
			</Typography>

			<CustomForm component='form' onSubmit={(e) => sendForm(e)}>
				<FormControl>
					<ExtractContent>
						{inputProps.map(({ id, ...other }) => (
							<InputsSpreader
								key={id}
								id={id}
								{...other}
								onChange={changeHandle}
							/>
						))}
					</ExtractContent>
				</FormControl>
				<Button
					type='submit'
					className='extract-btn'
					preloader={{ loading: isLoading }}
					disabled={isLoading}>
					Создать
				</Button>
			</CustomForm>
		</Container>
	);
};

export default CreationExtract;
