import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { ButtonContainer as ButtonContainerParent } from "components/Form/styles";
import { liteGrey } from "styles/colors";
import { robotoMedium } from "styles/fonts";
import { mediumDesktop } from "styles/media";

interface InputWrapperProps {
	width?: string;
}

export const Container = styled(Box)`
	width: 100%;
	height: 100%;
`;

export const Empty = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ImageWrapper = styled(Box)`
	width: 292px;
	height: 292px;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Text = styled(Typography)`
	font-family: ${robotoMedium};
	font-size: 18px;
	line-height: 130%;
	text-align: center;
	color: ${liteGrey};
	margin: 8px 0 32px;
`;

export const TitleBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const FormContent = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 32px;

	@media ${mediumDesktop} {
		width: calc(100% - 18% - 35px);
	}
`;

export const InputWrapper = styled(Box)`
	width: ${({ width }: InputWrapperProps) => (width ? width : "100%")};
`;

export const ButtonContainer = styled(ButtonContainerParent)`
	display: grid;
	grid-template-columns: 212px auto;
	align-items: center;
	gap: 60px;
`;

export const TopBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
`;

export const ListWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
