import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api: any = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL_DEV,
		prepareHeaders: (headers) => {
			return headers;
		},
	}),
	endpoints: (builder) => ({}),
});

export const {} = api;
