import { FormSectionDataProps } from "utils/types";
export const profileAddresses: FormSectionDataProps[] = [
	{
		id: "locationDocument",
		title: "Место нахождения согласно документам",
		dadata: "21",
		inputs: [
			{
				id: 21,
				value: "",
				field: "region",
				label: "Регион / Район",
				type: "dadata",
				suggest: "address",
				required: true,
			},
			{
				id: 22,
				value: "",
				field: "city",
				label: "Город / Населенный пункт",
				required: true,
			},
			{
				id: 23,
				value: "",
				field: "street",
				label: "Улица (проспект, переулок и др.)",
				required: true,
			},
			{
				id: 24,
				value: "",
				field: "house",
				label: "Дом",
				required: true,
			},
			{
				id: 25,
				value: "",
				field: "office",
				label: "Офис",
			},
			{
				id: 26,
				value: "",
				field: "postcode",
				label: "Почтовый индекс",
				required: true,
			},
		],
	},
	{
		id: "locationMatches",
		title: "Фактическое местонахождение",
		inputs: [
			{
				id: 31,
				value: false,
				field: "locationMatches",
				type: "checkbox",
				label:
					"Фактическое местонахождение совпадает с местонахождением в докуметах",
			},
		],
	},
	{
		id: "locationActual",
		title: "Почтовый адрес",
		dadata: "41",
		inputs: [
			{
				id: 41,
				value: "",
				field: "region",
				label: "Регион / Район",
				type: "dadata",
				suggest: "address",
				required: true,
			},
			{
				id: 42,
				value: "",
				field: "city",
				label: "Город / Населенный пункт",
				required: true,
			},
			{
				id: 43,
				value: "",
				field: "street",
				label: "Улица (проспект, переулок и др.)",
				required: true,
			},
			{
				id: 44,
				value: "",
				field: "house",
				label: "Дом",
				required: true,
			},
			{
				id: 45,
				value: "",
				field: "office",
				label: "Офис",
			},
			{
				id: 46,
				value: "",
				field: "postcode",
				label: "Почтовый индекс",
				required: true,
			},
		],
	},
];
