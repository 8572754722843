import { CheckIcon } from "assets/icons";
import { Conteiner, Text } from "./styles";

interface MeetingParticipantsProps {
	count: number;
	maxCount?: number;
	name?: string;
	chip?: boolean;
}

export const MarksIndicator = ({
	count,
	maxCount,
	name,
	chip,
}: MeetingParticipantsProps) => {
	return (
		<Conteiner
			className={`${chip ? "indicator-chip" : ""} ${
				count === maxCount ? "indicator-done" : ""
			}`}>
			<CheckIcon />
			<Text className='indicator-text'>{`${count} ${
				maxCount ? `из ${maxCount}` : ""
			}${name ? " " + name : ""}`}</Text>
		</Conteiner>
	);
};

export default MarksIndicator;
