import { ListLayout } from "components/ListLayout";

const MembersPage = () => {
	return (
		<ListLayout
			title='Члены СРО'
			url='members'
			api='users/sro/members'
			type='users'
		/>
	);
};

export default MembersPage;
