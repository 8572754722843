import axios, { AxiosResponse } from "axios";
import { baseApiUrl } from "../constants";
import {
	getTokensFromStorage,
	setTokenFromStorage,
	clearLocalStorage,
} from "../helpers";

interface HeadersRequestProps {
	Authorization?: string;
	Accept?: string;
	"Content-Type"?: string;
}

export interface RequestProps {
	method?: string;
	headers?: HeadersRequestProps;
	data: any;
	responseType?: string;
}

interface SimpleRequestProps extends RequestProps {
	headers: HeadersRequestProps;
	validateStatus: (status: number) => boolean;
}

interface RequestParamsProps {
	url: string;
	config: SimpleRequestProps;
}

type RequestFuncProps = (params: RequestParamsProps) => Promise<any>;

export const refreshToken: RequestFuncProps = async (
	forNewRequest: RequestParamsProps,
) => {
	const tokens = getTokensFromStorage();
	const url = `${baseApiUrl}/auth/refresh/`;

	if (tokens) {
		const config = {
			method: "POST",
			credentials: "include",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			data: { refresh: tokens.refresh },
			validateStatus: (status: number) => true,
		};

		// @ts-ignore
		const resp = await axios(url, config).then((res: AxiosResponse) => {
			const { status, data } = res;
			if (status === 200) {
				setTokenFromStorage(data);
				
				const newConfig = {
					...forNewRequest.config,
					headers: {
						...forNewRequest.config.headers,
						Authorization: `Bearer ${data.access}`,
					},
				};

				return request(forNewRequest.url, newConfig);
			} else {
				return clearLocalStorage();
			}
		});

		return resp;
	}
};

export const request = async (url: string, config: SimpleRequestProps) => {
	// @ts-ignore
	const resp = await axios(url, config).then((res: AxiosResponse) => {
		if (res.status === 401) {
			return refreshToken({ url, config });
		} else {
			return res;
		}
	});

	return resp;
};

export const useRequest = (
	url: string,
	params?: RequestProps,
	auth?: boolean,
) => {
	let config = {
		method: params && params.method ? params.method : "GET",
		headers: !auth
			? {
					Accept: "application/json",
					Authorization: `Bearer ${getTokensFromStorage()?.access}`,
					...params?.headers,
			  }
			: { Accept: "application/json" },
		data: params && params.data,
		responseType: params && params?.responseType,
		validateStatus: (status: number) => true,
	};

	return request(url, config);
};
