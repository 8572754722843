import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { robotoMedium, robotoRegular } from "styles/fonts";
import { white, red, green, liteGrey, background } from "styles/colors";

export const Container = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px;
	border-radius: 8px;

	&.postponed {
		background: ${liteGrey};

		svg {
			stroke: ${liteGrey};
		}
	}

	&.accept {
		background: ${green};

		svg {
			stroke: ${green};
		}
	}

	&.deny,
	&.canceled {
		background: ${red};

		svg {
			stroke: ${red};
		}
	}
`;

export const Block = styled(Box)`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const IconWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background-color: ${white};
	border-radius: 50%;
`;

export const Text = styled(Box)`
	font-family: ${robotoMedium};
	font-size: 18px;
	line-height: 23px;
	color: ${white};
`;

export const Helper = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 20px;
	color: ${white};
	opacity: 0.5;
`;
