import { useEffect, useState } from "react";
import { useRequest } from "utils/hooks";
import {
	Input,
	Button,
	Checkbox,
	RadioGroup,
	InputsSpreader,
} from "components/FormElements";
import { baseApiUrl } from "utils/constants";
import {
	InputDataProps,
	InputOnChange,
	NotificationAction,
	NotificationOption,
	CheckboxListTypes,
} from "utils/types";
import { Conteiner, Label, CheckboxList, RadioList } from "./styles";

interface PermissionsCreateOrDeleteProps {
	permission: NotificationAction;
	fieldType: string;
}

export const PermissionsCreateOrDelete = ({
	permission,
	fieldType,
}: PermissionsCreateOrDeleteProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const request = useRequest;
	const [inputProps, setInputProps] = useState<InputDataProps[]>();
	const [listProps, setListProps] = useState<CheckboxListTypes>();

	useEffect(() => {
		if (permission.options) {
			if (fieldType === "radio") {
				setListProps(
					// @ts-ignore
					permission.options.map(({ codeName, name, checked, id }) => ({
						name,
						label: name,
						id: id ? id : codeName,
						checked,
						value: codeName,
					})),
				);
			} else {
				setInputProps(
					// @ts-ignore
					permission.options.map(
						({ codeName, name, checked, value, id }, index) => ({
							id: id || codeName || index,
							type: checked !== undefined ? "checkbox" : undefined,
							value: value !== undefined ? value : checked,
							label: name,
							field: codeName || String(id),
						}),
					),
				);
			}
		} else {
			console.log(permission.name);
		}
	}, [permission]);

	const changeValue: InputOnChange = ({ field, value }) => {
		console.log(field, value);
		// if (listProps) {
		// 	const newInputProps = listProps.map((item) =>
		// 		field === String(item.id) ? { ...item, checked: value } : item,
		// 	); // @ts-ignore
		// 	setListProps(newInputProps);
		// }
	};

	return (
		<>
			<Conteiner>
				<Label>{permission.name}</Label>

				{listProps && fieldType === "radio" && (
					<RadioList>
						<RadioGroup
							id={1}
							list={listProps}
							field={permission.codeName}
							onChange={({ value }) => {}}
						/>
					</RadioList>
				)}
				{inputProps &&
					inputProps.map(({ id, type, field, value, label }) => (
						<InputsSpreader
							key={id}
							id={id}
							value={value}
							field={field}
							type={type}
							label={label}
							onChange={changeValue}
						/>
					))}
			</Conteiner>

			{/* <CheckboxList>
							 @ts-ignore
							{listProps.map(({ id, label, checked }) => (
								<Checkbox
									key={id}
									id={id}
									field={String(id)}
									label={label} // @ts-ignore
									checked={checked}
									onChange={changeValue}
								/>
							))}
						</CheckboxList>
						*/}
		</>
	);
};
