import { useEffect, useState, Suspense } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserIdByJwt } from "utils/helpers";
import { useUserUpdate } from "utils/hooks";
import { setUser } from "utils/store";
import { RoutesProps } from "utils/types";
import { Preloader } from "components/Preloader";
import { Layout } from "components/Layout";
import { pathsGenerator } from "./paths/generator";
import { useUser } from "utils/store/hooks";

export const Navigation = () => {
	const [paths, setPaths] = useState<RoutesProps[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useUser();
	const userUpdate = useUserUpdate;

	useEffect(() => {
		if (!user) {
			userUpdate(getUserIdByJwt()).then(({ data, status }) => {
				if (status >= 200 && status < 300) {
					dispatch(setUser({ ...data }));
					setPaths(pathsGenerator(data.role));
				} else {
					setPaths(pathsGenerator());
					if (pathname !== "/create-password") {
						navigate("/login");
					}
				}

				setIsLoading(false);
			});
		}
	}, []);

	useEffect(() => {}, [user]);

	return (
		<Suspense fallback={<Preloader active={true} />}>
			{!isLoading && paths.length > 0 && (
				<Routes>
					<Route element={<Layout />}>
						{paths.map(({ id, path, element, children }) =>
							children ? (
								<Route key={id} path={path} element={element}>
									<Route index element={children[0].element} />
									{children.map((child) => (
										<Route
											key={child.id}
											path={child.path}
											element={child.element}
										/>
									))}
								</Route>
							) : (
								<Route key={id} path={path} element={element} />
							),
						)}
					</Route>
				</Routes>
			)}
		</Suspense>
	);
};
