import { BrowserRouter } from "react-router-dom";
import { Navigation } from "utils/router/Navigation";
import { GlobalStyle } from "styles/global";

const App = () => {
	return (
		<BrowserRouter>
			<GlobalStyle />
			<Navigation />
		</BrowserRouter>
	);
};

export default App;
