import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { black, grey } from "styles/colors";
import { robotoMedium } from "styles/fonts";

export const Container = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const Name = styled(Box)`
	color: ${black};
	font-family: ${robotoMedium};
	font-size: 15px;
	line-height: 130%;
`;

export const Count = styled(Box)`
	color: ${grey};
	font-family: ${robotoMedium};
	font-size: 15px;
	line-height: 130%;
`;
