import {
	useState,
	SyntheticEvent,
	FormEvent,
	ChangeEvent,
	useLayoutEffect,
	useEffect,
} from "react";
import { useNavigate } from "react-router";
import { Box, Typography } from "@mui/material";
import { baseApiUrl } from "utils/constants";
import { useRequest } from "utils/hooks";
import {
	InputOnChange,
	SelectOptionProps,
	ResponsibilityLevelItemProps,
} from "utils/types";
import { applicationsTypes, odoLevel, odoLevelExtraParam } from "assets/data";
import { Button, Select, Checkbox } from "components/FormElements";
import { Popup } from "components/Popup";
import { CreationExtract } from "./CreationExtract";
import { Preloader } from "components/Preloader";
import {
	Container,
	CustomForm,
	Description,
	ApplicationsTypes,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	ApplicationItem,
	Radio,
	RadioLabel,
	Label,
	Text,
	FormControl,
} from "./styles";
import { ArrowIcon } from "assets/icons";

const getValue = (arr: any[]) => {
	let obj: { [name: string]: {} } = {};

	arr.map(({ value }) => {
		obj = { ...obj, [value]: { type: value } };
	});

	return obj;
};

export const ApplicationCreationPopup = () => {
	const [isPreLoading, setIsPreLoading] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [types, setTypes] = useState(applicationsTypes);
	const defaultAppType = types[0].value;
	const [expanded, setExpanded] = useState<string | false>(defaultAppType);
	const [activePanel, setActivePanel] = useState(defaultAppType);
	const [appType, setAppType] = useState(defaultAppType);
	const [appProps, setAppProps] = useState(getValue(types));
	const [showPopup, setShowPopup] = useState(false);
	const [responsibility, setResponsibility] = useState<{
		levelManyContract: SelectOptionProps[];
		levelOneContract: SelectOptionProps[];
	}>();
	const [odoLevelItem, setOdoLevelItem] = useState(odoLevel);
	const navigate = useNavigate();
	const request = useRequest;
	const url = `${baseApiUrl}/applications/member/`;
	const respUrl = `${baseApiUrl}/responsibility/`;

	useLayoutEffect(() => {
		request(respUrl).then((res) => {
			const {
				data: {
					responsibilityLevelManyContract,
					responsibilityLevelOneContract,
				},
			} = res;
			setResponsibility({
				levelManyContract: responsibilityLevelManyContract.map(
					({ id, label }: ResponsibilityLevelItemProps) => ({
						value: id,
						name: label,
					}),
				),
				levelOneContract: responsibilityLevelOneContract.map(
					({ id, label }: ResponsibilityLevelItemProps) => ({
						value: id,
						name: label,
					}),
				),
			});
			setTimeout(() => {
				setIsPreLoading(false);
			}, 250);
		});
	}, []);

	useEffect(() => {
		if (responsibility) {
			const newTypes = types.map(({ value, inputs, ...other }) => {
				let item = { value, inputs, ...other };
				if (value == "odo" || value == "vv") {
					// @ts-ignore
					item.inputs = inputs?.map(
						({ field, options, placeholder, ...other }) => {
							let resp =
								value == "odo"
									? responsibility.levelManyContract
									: responsibility.levelOneContract;
							let newItem = { field, placeholder, options, ...other };
							if (field === "level") {
								newItem = {
									field, // @ts-ignore
									options: resp,
									placeholder: resp[0].name,
									...other,
								};
							}

							if (value === "odo" && field === "level") {
								console.log("odo", newItem);
								// @ts-ignore
								setOdoLevelItem(newItem);
							}

							return newItem;
						},
					);
				}
				return item;
			});
			// @ts-ignore
			setTypes(newTypes);
		}
	}, [responsibility]);

	const changeAppType = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target;
		const type = target.value;
		const newTypes: any[] = [];

		types.map(({ value, inputs, ...other }) => {
			const newInputs: any[] = [];
			let newItem = { ...other, value, inputs };

			inputs?.map((item) =>
				newInputs.push({
					...item,
					required: value === type ? true : false,
				}),
			);

			if (newItem.inputs) newItem.inputs = newInputs;

			newTypes.push(newItem);
		});
		setAppType(type);
		setTypes(newTypes);
	};

	const changeAppProps: InputOnChange = ({ value, field, id }) => {
		let newTypes = [...types];

		if (appType === "odo" && field === "subtype") {
			let newInputs = newTypes[1].inputs;

			if (value === "Прекращение") {
				newInputs = newInputs?.filter(
					(item) =>
						item.field !== "level" &&
						item.field !== "priceManyContractExtraParam",
				);
			} else {
				if (newInputs && newInputs.length < 2) {
					console.log(odoLevelItem);
					newInputs?.splice(1, 0, odoLevelItem);
					newInputs.splice(2, 0, odoLevelExtraParam);
				}
			}

			newTypes[1].inputs = newInputs;
			setTypes(newTypes);
		} else {
			let newInputs = newTypes.map((type) =>
				type.value === appType && type.inputs
					? {
							...type,
							inputs: type.inputs.map((item) =>
								item.field === field ? { ...item, value } : item,
							),
					  }
					: type,
			);
			//@ts-ignore
			setTypes(newInputs);
		}

		setAppProps({
			...appProps,
			[appType]: { ...appProps[appType], [field]: value },
		});
	};

	const sendForm = (event: FormEvent<HTMLDivElement>) => {
		event.preventDefault();
		if (appType !== "extract") {
			let data = appProps[appType];

			setIsLoading(true);

			if (appType === "termination") {
				data = { ...data, subtype: "Добровольный выход" };
			}
			//@ts-ignore
			if (appType === "odo" && data?.subtype === "Прекращение") {
				//@ts-ignore
				data = { type: appType, subtype: data.subtype };
			}
			//@ts-ignore
			if (data?.level) {
				//@ts-ignore
				data = { ...data, level: JSON.parse(`${data.level}`)?.name };
			}

			const options = {
				method: "POST",
				data,
			};

			request(url, options).then((res) => {
				const { data } = res;
				setTimeout(() => {
					setIsLoading(false);
					navigate(`/applications/${data.id}`);
				}, 250);
			});
		} else {
			setShowPopup(true);
		}
	};

	const handleChange =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			if (panel !== activePanel) {
				const value = isExpanded ? panel : false;
				setExpanded(value);
				if (value) {
					setActivePanel(panel);
				}
			}
		};

	return (
		<>
			<Container>
				<Box>
					<Typography component='h2' variant='h2' id='modal-title'>
						Создать заявку
					</Typography>
					<Description>
						Выберите тип заявки, которую вы хотите создать
					</Description>
				</Box>
				<Preloader active={isPreLoading}>
					<CustomForm component='form' onSubmit={(e) => sendForm(e)}>
						<FormControl>
							<ApplicationsTypes
								defaultValue={defaultAppType}
								onChange={changeAppType}>
								{types.map(({ id, title, value, description, inputs }) => (
									<Accordion
										key={id}
										expanded={expanded === value}
										onChange={handleChange(value)}>
										<AccordionSummary
											expandIcon={
												inputs && inputs.length > 0 ? <ArrowIcon /> : undefined
											}>
											<ApplicationItem
												control={
													<Radio
														disableRipple
														checkedIcon={<></>}
														icon={<></>}
													/>
												}
												value={value}
												label={
													<RadioLabel className={description && "description"}>
														<Typography variant='h4'>{title}</Typography>
														{description && <Text>{description}</Text>}
													</RadioLabel>
												}
											/>
										</AccordionSummary>
										{inputs && (
											<AccordionDetails>
												{inputs.map(({ id, label, type, ...other }) =>
													type == "select" ? (
														<Box key={id}>
															<Label>{label}</Label>
															<Select
																id={id}
																{...other}
																onChange={changeAppProps}
															/>
														</Box>
													) : (
														<Checkbox
															key={id}
															id={id}
															label={label}
															{...other}
															checked={Boolean(other.value)}
															onChange={changeAppProps}
														/>
													),
												)}
											</AccordionDetails>
										)}
									</Accordion>
								))}
							</ApplicationsTypes>
						</FormControl>

						<Button
							fullWidth
							type='submit'
							preloader={{ loading: isLoading }}
							disabled={isLoading}>
							Создать
						</Button>
					</CustomForm>
				</Preloader>
			</Container>
			<Popup active={showPopup} afterClose={setShowPopup}>
				<CreationExtract />
			</Popup>
		</>
	);
};

export default ApplicationCreationPopup;
