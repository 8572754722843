import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { UserProps, ImageProps } from "utils/types";
import type { RootState } from "../generateState";

interface UserSliceProps {
	user: UserProps | null;
}

const initialState: UserSliceProps = {
	user: null,
};

const slice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, { payload }: PayloadAction<UserProps | null>) => {
			state.user = payload;
		},
		setUserAvatar: (state, { payload }: PayloadAction<ImageProps>) => {
			if (state.user) state.user = { ...state.user, avatar: payload };
		},
		setUserName: (state, { payload }: PayloadAction<string>) => {
			if (state.user) state.user = { ...state.user, fullName: payload };
		},
	},
});

export const { setUser, setUserAvatar, setUserName } = slice.actions;

export const selectUser = (state: RootState) => state.user;

export const selectUserAvatar = (state: RootState) =>
	state.user ? state.user.user?.avatar : null;

export const selectUserId = (state: RootState) =>
	state.user ? state.user.user?.id : null;

export const selectUserRole = (state: RootState) =>
	state.user ? state.user.user?.role : null;

export default slice.reducer;
