import { styled } from "@mui/material/styles";
import { Box, LinearProgress as LinearProgressMui } from "@mui/material";
import { robotoRegular } from "styles/fonts";
import { black, grey, line, blue, white } from "styles/colors";

interface ContainerProps {
	delay?: number;
}

interface ProgressProps {
	show: string;
}

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: all 0.25s ease-in-out;

	&.upload {
		margin: 20px 0;
		height: 52px;
	}

	&.link {
		padding: 0 6px;
		background: ${white};
		border-radius: 8px;
	}

	&.grow-enter {
		opacity: 0;
		height: 0;
		${({ delay }: ContainerProps) =>
			delay ? `transition-delay: ${delay}ms;` : `transition-delay: 0;`}
	}

	&.grow-enter.grow-enter-active {
		opacity: 1;
		height: 52px;
	}

	&.grow-exit {
		opacity: 0;
		height: 0;
		margin: 0;
	}

	&.grow-exit.fade-exit-active {
		opacity: 0;
		height: 0;
		margin: 0;
	}
`;

export const Content = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0;

	&.upload {
		padding: 8px 0;
	}
`;

export const LeftBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

export const RightBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

export const Title = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 15px;
	line-height: 20px;
	color: ${black};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.25s ease-in-out;

	&.upload-title {
		width: 100%;
	}

	&.link-title {
		color: ${blue};
		position: relative;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			background: transparent;
			transition: all 0.25s ease-in-out;
		}
	}
`;

export const Description = styled(Box)`
	display: flex;

	&.upload {
		flex-direction: column;
		gap: 3px;

		p {
			font-size: 14px;
		}
	}

	&.download,
	&.link {
		display: grid;
		gap: 8px;
	}

	&.download {
		grid-template-columns: auto 50px 60px;
	}

	&.link {
		display: grid;
		grid-template-columns: auto 50px;

		p {
			color: ${blue};
			font-size: 14px;
		}
	}
`;

export const ImageWrapper = styled(Box)`
	position: relative;

	&.download,
	&.link {
		width: 18px;
		height: 18px;
		margin-right: 8px;
	}

	&.upload {
		width: 34px;
		height: 34px;
		margin-right: 18px;
	}

	> img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 12px;
	line-height: 16px;
	color: ${grey};
	overflow: hidden;
	height: ${({ show }: ProgressProps) => (show === "true" ? "100%" : 0)};
	transition: all 0.25s ease-in-out;

	&.link,
	&.download {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		transform: translateY(1px);
	}
`;

export const ProgressValue = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${grey};
	margin-right: 12px;
	opacity: ${({ show }: ProgressProps) => (show === "true" ? 1 : 0)};
	transition: all 0.25s ease-in-out;
`;

export const LinearProgress = styled(LinearProgressMui)`
	height: 2px;
	background-color: ${line};
	border-radius: 4px;
	opacity: ${({ show }: ProgressProps) => (show === "true" ? 1 : 0)};
	transition: all 0.25s ease-in-out;

	.MuiLinearProgress-bar {
		background-color: ${blue};
	}
`;
