import { robotoRegular } from "styles/fonts";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { black, grey } from "styles/colors";
import { mediumDesktop } from "styles/media";

export const Container = styled(Box)`
	display: grid;
	grid-template-columns: 170px auto 120px;
	width: 100%;
	gap: 32px;

	@media ${mediumDesktop} {
		gap: 80px;
	}
`;
export const LeftBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
`;

export const CenterBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const RightBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 8px;
`;

export const AvatarWrapper = styled(Box)`
	width: 44px;
	height: 44px;
	position: relative;
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${black};
`;

export const TextSecondary = styled(Text)`
	color: ${grey};
`;

export const TextSecondarySmall = styled(TextSecondary)`
	font-size: 12px;
`;

export const DescriptionBlock = styled(Box)`
	display: flex;
	gap: 20px;
`;

export const RolesBlock = styled(Box)`
	display: flex;
	gap: 8px;
`;
