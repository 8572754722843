import { ReactNode, useState, useEffect } from "react";
import { Paper, Dialog } from "@mui/material";
import { IconButton } from "components/IconButton";
import { CloseIcon } from "assets/icons";
import { Modal, Content, Wrapper, CloseButton } from "./styles";

interface PopupProps {
	active: boolean;
	children: ReactNode;
	afterClose: (value: boolean) => void;
}

export const Popup = ({ active, children, afterClose }: PopupProps) => {
	const [open, setOpen] = useState(active);

	useEffect(() => setOpen(active), [active]);

	const handleClose = () => {
		setOpen(false);
		afterClose(false);
	};

	return (
		<Modal open={open} onClose={handleClose} aria-labelledby='modal-title'>
			<Paper>
				<Wrapper>
					<CloseButton onClick={handleClose}>
						<CloseIcon />
					</CloseButton>
					<Content>{children}</Content>
				</Wrapper>
			</Paper>
		</Modal>
	);
};
