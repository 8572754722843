import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { Menu, Badge, Box } from "@mui/material";
import { BellIcon } from "assets/icons";
import { baseWSSUrl, baseApiUrl, NOTICE_LIMIT } from "utils/constants";
import { getTokensFromStorage } from "utils/helpers";
import {
	useApplicationId,
	useUserId,
	useApplicationContent,
} from "utils/store";
import { useRequest } from "utils/hooks";
import { NoticeItemProps, ApplicationDetailProps } from "utils/types";
import { Scrollbar } from "components/Scrollbar";
import { NoticeItem } from "./NoticeItem/NoticeItem";
import {
	Button,
	Icon,
	Counter,
	Content,
	InfoBlock,
	Text,
	Status,
	Empty,
} from "./styles";

export const Notice = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [notices, setNotices] = useState<NoticeItemProps[]>([]);
	const userId = useUserId();
	const applicationId = useApplicationId();
	const content = useApplicationContent();
	const [count, setCount] = useState(0);
	const { pathname } = useLocation();
	const request = useRequest;
	const open = Boolean(anchorEl);
	const url = `${baseWSSUrl}/notifications/${userId}/`;
	const { sendJsonMessage } = useWebSocket(url, {
		queryParams: {
			token: `${getTokensFromStorage()?.access}`,
		},
		onOpen: () => {
			console.log("WebSocket connection opened.");
		},
		onClose: () => {
			console.log("WebSocket connection closed.");
		},
		shouldReconnect: (closeEvent) => {
			console.log("WebSocket connection reconected.", closeEvent);
			return true;
		},
		onMessage: (event: WebSocketEventMap["message"]) => processMessages(event),
	});

	useEffect(() => {
		getNoticeList();
	}, []);

	useEffect(() => {
		if (applicationId) {
			const config = {
				message: {
					userId,
					contentObjectId: applicationId,
					type:
						// @ts-ignore
						content?.common?.type == "primary" || // @ts-ignore
						content?.common?.type == "vv" || // @ts-ignore
						content?.common?.type == "odo" || // @ts-ignore
						content?.common?.type == "oo" || // @ts-ignore
						content?.common?.type == "extract" || // @ts-ignore
						content?.common?.type == "termination"
							? "application" // @ts-ignore
							: content?.common?.type,
				},
			};
			sendJsonMessage(config);
		}
	}, [content.common?.id]);

	const processMessages = (event: { data: string }) => {
		const response = JSON.parse(event.data);
		if (response.message) {
			getNoticeList([]);
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const getNoticeList = (list?: NoticeItemProps[]) => {
		const currentNotices = list ? list : notices;
		if (currentNotices.length < count || count === 0)
			request(
				`${baseApiUrl}/notifications/?limit=${NOTICE_LIMIT}&offset=${currentNotices.length}`,
			).then((res) => {
				const { data } = res;
				setCount(data.count);
				setNotices([...currentNotices, ...data.results]);
			});
	};

	return (
		<>
			<Button onClick={handleClick} className={open ? `active` : undefined}>
				<Icon className='icon'>
					<BellIcon />
				</Icon>
				<Counter
					badgeContent={count}
					style={{ transform: count > 0 ? `scale(1)` : `scale(0)` }}
					className='count'
				/>
			</Button>
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					style: {
						marginTop: 21,
						overflow: "unset",
						boxShadow: `0px 3px 20px rgba(0, 0, 0, 0.15)`,
						transform: `translateX(13px)`,
					},
				}}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
				<Content>
					{notices.length > 0 ? (
						<>
							<InfoBlock>
								<Text>Уведомления</Text>
								<Status>
									<Badge badgeContent={count} />
									<Box>{`${count}`.at(-1) === "1" ? "новое" : "новых"}</Box>
								</Status>
							</InfoBlock>
							<Scrollbar
								height={500}
								onScrollEnd={getNoticeList}
								inside
								noScrollX>
								{notices.map(
									({ id, contentObject, status, type, createdAt }) => (
										<NoticeItem
											key={id}
											id={id}
											contentObject={contentObject}
											status={status}
											type={type}
											createdAt={createdAt}
											onClick={handleClose}
										/>
									),
								)}
							</Scrollbar>
						</>
					) : (
						<Empty>
							На данный момент у вас отсутствуют активные уведомления
						</Empty>
					)}
				</Content>
			</Menu>
		</>
	);
};
