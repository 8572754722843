import { profileInfo, employeeInfo } from "assets/data";
import { UserPageProps } from "utils/types";
import { ProfileForm } from "components/ProfileForm";

export const UserInfo = ({ detail, admin }: UserPageProps) => {
	return (
		<ProfileForm
			name='info'
			content={admin ? employeeInfo : profileInfo}
			detail={detail}
			admin={admin}
		/>
	);
};

export default UserInfo;
