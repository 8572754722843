import { black, grey } from "styles/colors";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { robotoRegular } from "styles/fonts";

export const User = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-right: 10px;
`;

export const Title = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${black};
	white-space: nowrap;
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 12px;
	line-height: 140%;
	color: ${grey};
`;

export const NameBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const Image = styled(Box)`
	width: 40px;
	height: 40px;
	position: relative;
	display: flex;
  flex-shrink: 0;
`;
