import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import { background, grey, line, white } from "styles/colors";

export const Conteiner = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	width: 100%;
	border-bottom: 1px solid ${line};
	padding-bottom: 28px;
`;

export const LeftBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const RightBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
`;

export const List = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const AddButton = styled(Button)`
	width: 40px;
	height: 40px;
	background: ${background};
	border: 1px dashed ${line};
	border-radius: 50% !important;
	padding: 0 !important;
	min-width: 40px;

	&:hover {
		border: 1px dashed ${grey};
		background: ${background};
	}

	> svg {
		stroke: ${grey};
		width: 12px;
		height: 12px;
		transform: rotate(45deg);
	}
`;

export const Participants = styled(Box)`
	display: flex;
`;

export const ParticipantItem = styled(Box)`
	width: 20px;
	position: relative;

	&:last-of-type {
		width: 40px;
	}
`;

export const ImageWrapper = styled(Box)`
	width: 40px;
	height: 40px;
	border: 1px solid ${white};
	border-radius: 50%;
	position: relative;
`;
