import { SideMenuItemPops } from "utils/types";
import {
	PersonIcon,
	CaseIcon,
	LocationIcon,
	LockIcon,
	DocumentIcon,
} from "assets/icons";

export const profileMenu: SideMenuItemPops[] = [
	{
		id: 1,
		title: "Общая информация",
		url: "/info",
		icon: PersonIcon,
	},
	{
		id: 2,
		title: "Адреса",
		url: "/addresses",
		icon: LocationIcon,
	},
	{
		id: 3,
		title: "Компания",
		url: "/company",
		icon: CaseIcon,
	},
	{
		id: 4,
		title: "Пароль",
		url: "/password",
		icon: LockIcon,
	},
];

export const detailMenu: SideMenuItemPops[] = [
	...profileMenu,
	{
		id: 5,
		title: "Заявки",
		url: "/related-applications",
		icon: DocumentIcon,
	},
];

export const adminMenu: SideMenuItemPops[] = [
	{
		id: 1,
		title: "Общая информация",
		url: "/info",
		icon: PersonIcon,
	},
	{
		id: 2,
		title: "Пароль",
		url: "/password",
		icon: LockIcon,
	},
	{
		id: 3,
		title: "Заявки",
		url: "/related-applications",
		icon: DocumentIcon,
	},
];
