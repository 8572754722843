import { useEffect } from "react";
import { addTitlePage } from "utils/helpers";
import { RegForm } from "components/Auth/RegForm";

const Registration = () => {
	useEffect(() => {
		addTitlePage("/registration");
	}, []);

	return <RegForm />;
};

export default Registration;
