import { ListLayout } from "components/ListLayout";

const AgendaPage = () => {
	return (
		<ListLayout
			title='Реестр вопросов повестки'
			url='questions-agenda'
			api='agenda'
			type='applications'
		/>
	);
};

export default AgendaPage;
