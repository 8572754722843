import { ListLayout } from "components/ListLayout";

const СommissionСontrolsPage = () => {
	return (
		<ListLayout
			title='Реестр протоколов контрольной комиссии'
			url='protocols-control-commission'
			api='commission_controls'
			type='applications'
		/>
	);
};

export default СommissionСontrolsPage;
