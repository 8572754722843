import { useState, useEffect } from "react";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import image from "assets/images/folder.svg";
import { formatBytes } from "utils/helpers";
import { ScreenWrapper, IconWrapper, Text } from "./styles";

const DropDownIcon = () => (
	<IconWrapper>
		<img src={image} alt='folder' />
	</IconWrapper>
);

interface UploadScreenProps {
	maxCount?: number;
	accept?: string[];
	onChange: (newFile: any) => void;
	files: FileObject[];
	maxFileSize?: number;
}

export const UploadScreen = ({
	maxCount,
	onChange,
	accept,
	maxFileSize,
	files,
}: UploadScreenProps) => {
	const [fileObjects, setFileObjects] = useState<FileObject[]>(files);
	const printcount = maxCount ? maxCount : 5;
	const maxSize = maxFileSize ? maxFileSize : Math.pow(1024, 2) * 5;

	useEffect(() => {
		setFileObjects(files);
	}, [files]);

	const onAdd = (acceptedFiles: FileObject[]) => {
		onChange(acceptedFiles);
	};

	const onReject = (rejectedFile: File) =>
		`Файл ${rejectedFile.name} превышает допустимый размер в ${formatBytes(
			maxSize,
			1,
		)}`;

	const onExeed = () =>
		`Превышено максимально допустимое количество в ${printcount} файлов`;

	return (
		<ScreenWrapper>
			<Text>Вы можете прикрепить до {printcount} файлов</Text>
			<DropzoneAreaBase
				//@ts-ignores
				Icon={DropDownIcon}
				showAlerts={["error"]}
				alertSnackbarProps={{
					anchorOrigin: { vertical: "bottom", horizontal: "right" },
					autoHideDuration: null,
				}}
				onAdd={onAdd}
				getDropRejectMessage={onReject}
				getFileLimitExceedMessage={onExeed}
				maxFileSize={maxSize}
				useChipsForPreview
				showFileNames
				fileObjects={fileObjects}
				filesLimit={printcount}
				dropzoneText='Перетащите сюда файл или выберите на компьютере'
				acceptedFiles={accept}
			/>
		</ScreenWrapper>
	);
};
