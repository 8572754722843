import { adminRoleId } from "utils/constants";
import { RoleProps } from "utils/types";
import { basicPaths } from "./basic";
import { candidates, candidatesForAdmin } from "./candidates";
import { members, membersForAdmin } from "./members";
import { staff, staffForAdmin } from "./staff";

export const pathsGenerator = (roles?: RoleProps[]) => {
	let routePaths = [];
	let isAdmin = false;

	if (roles) roles.map(({ id }) => (isAdmin = id === adminRoleId));

	if (isAdmin) {
		routePaths = [
			...basicPaths,
			...candidatesForAdmin,
			...membersForAdmin,
			...staffForAdmin,
		];
	} else {
		routePaths = [...basicPaths, ...candidates, ...members, ...staff];
	}

	return routePaths;
};
