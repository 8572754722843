import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { robotoMedium, robotoRegular } from "styles/fonts";
import { grey, black, line, blue } from "styles/colors";

export const Content = styled(Box)`
	display: grid;
	gap: 40px;
`;

export const Table = styled(Box)`
	position: relative;
	width: 70%;
`;

export const TableItem = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	align-items: center;
	padding: 16px 0;
	border-bottom: 1px solid ${line};

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		padding-bottom: 0;
		border-bottom: none;
	}
`;

export const Title = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 15px;
	line-height: 130%;
	color: ${grey};
`;

export const Text = styled(Box)`
	font-family: ${robotoMedium};
	font-size: 15px;
	line-height: 130%;
	color: ${black};

	&.link {
		display: flex;
		justify-self: flex-start;
		color: ${blue};
		border-bottom: 1px solid transparent;
		transition: all 0.25s ease-in-out;

		&:hover {
			border-bottom: 1px solid ${blue};
		}
	}
`;

export const TableItemValue = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;

	svg {
		stroke: ${grey};
	}
`;

export const ValueLabel = styled(Box)`
	color: ${grey};
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
`;
