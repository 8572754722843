import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { red } from "styles/colors";
import { largeDesktop, mediumDesktop } from "styles/media";

interface TitleProps {
	required?: boolean;
}

export const Title = styled(Typography)`
	&::after {
		content: "*";
		display: ${({ required }: TitleProps) =>
			required ? "inline-block" : "none"};
		color: ${red};
		margin-left: 3px;
	}
`;
export const Section = styled(Box)`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 24px;

	.select-container {
		flex-direction: row;
	}
`;
export const InputsContainer = styled(Box)`
	display: grid;
	gap: 22px;

	.MuiCheckbox-root {
		padding: 0 9px;

		&:hover {
			background-color: transparent;
		}
	}

	&.section1 {
		grid-template:
			"first first"
			"second second"
			"third fourth"
			"fifth sixth";
		grid-template-columns: repeat(2, 1fr);

		.item1 {
			grid-area: first;
			width: calc(50% - 8px);
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
		}
		.item4 {
			grid-area: fourth;
		}
		.item5 {
			grid-area: fifth;
		}
		.item6 {
			grid-area: sixth;
			width: 304px;
		}
	}

	&.section2,
	&.additional1,
	&.additional2 {
		grid-template-columns: repeat(2, 1fr);
	}

	&.additional1,
	&.additional2 {
		margin-bottom: 32px;
	}

	&.section3,
	&.section5,
	&.address1,
	&.address3 {
		grid-template:
			"first first second second"
			"third third fourth fifth";
		grid-template-columns: repeat(4, 1fr);

		.item1 {
			grid-area: first;
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
		}
		.item4 {
			grid-area: fourth;
		}
		.item5 {
			grid-area: fifth;
		}
	}

	&.info1 {
		grid-template:
			"first first second second"
			"third third fourth fourth";
		grid-template-columns: repeat(4, 1fr);

		.item1 {
			grid-area: first;
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
			width: 100%;

			@media ${mediumDesktop} {
				width: 50%;
			}
		}
	}

	&.info2 {
		grid-template:
			"first first second second"
			"third fourth fifth fifth";
		grid-template-columns: repeat(4, 1fr);

		.item1 {
			grid-area: first;
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
		}
		.item4 {
			grid-area: fourth;
		}
		.item5 {
			grid-area: fifth;
			width: 100%;

			@media ${mediumDesktop} {
				width: 50%;
			}
		}
	}

	&.password1 {
		grid-template:
			"first first"
			"second third";
		grid-template-columns: repeat(2, 1fr);

		@media ${mediumDesktop} {
			width: 75%;
		}

		.item1 {
			grid-area: first;
			width: calc(50% - 8px);
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
		}
	}

	&.company1 {
		grid-template:
			"first first first first"
			"second second second second"
			"third third fourth fourth"
			"fifth sixth seventh seventh";
		grid-template-columns: repeat(4, 1fr);

		.item1 {
			grid-area: first;
			width: calc(50% - 8px);
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
		}
		.item4 {
			grid-area: fourth;
		}
		.item5 {
			grid-area: fifth;
		}
		.item6 {
			grid-area: sixth;
		}
		.item7 {
			grid-area: seventh;

			@media ${mediumDesktop} {
				width: calc(50% - 8px);
			}
		}
	}

	&.company2 {
		grid-template: "first first second second";
		grid-template-columns: repeat(4, 1fr);

		.item1 {
			grid-area: first;
		}
		.item2 {
			grid-area: second;
		}
	}

	&.employee1 {
		grid-template: "first first" "second third" "fourth fifth";
		grid-template-columns: repeat(2, 1fr);

		.item1 {
			grid-area: first;
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
		}
		.item4 {
			grid-area: fourth;
		}
		.item5 {
			grid-area: fifth;
		}
	}

	&.employee2 {
		grid-template-columns: repeat(2, 1fr);
	}

	&.employee3 {
		grid-template-columns: repeat(1, 1fr);
	}

	&.staff1 {
		grid-template:
			"first first first first"
			"second second third third"
			"fourth fourth fifth fifth";
		grid-template-columns: repeat(4, 1fr);

		@media ${mediumDesktop} {
			width: 75%;
		}

		.item1 {
			grid-area: first;
		}
		.item2 {
			grid-area: second;
		}
		.item3 {
			grid-area: third;
		}
		.item4 {
			grid-area: fourth;
		}
		.item5 {
			grid-area: fifth;
		}
	}

	&.staff2 {
		grid-template-columns: repeat(1, 1fr);
		position: relative;

		@media ${mediumDesktop} {
			width: 75%;
		}
	}

	&.staff3 {
		grid-template-columns: repeat(2, 1fr);

		@media ${mediumDesktop} {
			width: 75%;
		}
	}
`;
