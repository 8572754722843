import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Box } from "@mui/material";
import { line } from "styles/colors";

const Wrapper = styledMui(Box)`
	padding: 20px 30px 21px;
	border-bottom: 1px solid ${line};
`;

const ImageLink = styledMui(NavLink)`
	display: block;
	position: relative;
	width: 110px;
	height: 29px;
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

export { Wrapper, Image, ImageLink };
