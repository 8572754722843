import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { robotoRegular, robotoMedium } from "styles/fonts";
import { red, grey, line, blue, background } from "styles/colors";

export const ScreenWrapper = styled(Box)`
	width: 100%;

	.MuiDropzoneArea-root {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 160px;
		background: ${background};
		border: 1px dashed ${blue};
		border-radius: 8px;
		min-height: auto;
		padding: 40px 110px;
	}

	.MuiDropzoneArea-textContainer {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
	}

	.MuiDropzoneArea-text {
		font-family: ${robotoRegular};
		font-size: 14px;
		line-height: 140%;
		text-align: center;
		color: ${grey};
		margin: 8px 0 0;
	}

	.MuiSnackbarContent-root {
		padding: 16px;
		gap: 8px;
		background: #ffffff;
		box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.18);
		border-radius: 10px;
		font-family: ${robotoRegular};
		font-size: 14px;
		line-height: 20px;
		width: 400px;
		flex-wrap: nowrap;

		&.MuiDropzoneSnackbar-errorAlert {
			color: ${red};
		}
	}

	.MuiDropzoneSnackbar-message {
		font-family: ${robotoMedium};
		font-size: 15px;
		line-height: 19px;
		gap: 8px;
	}

	.MuiSnackbarContent-action {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		margin: 0;

		.MuiIconButton-root {
			padding: 8px;
			color: ${grey};

			&:hover {
				background: transparent;
				color: ${red};
			}
		}
	}

	.MuiSnackbarContent-message {
		padding: 0;
		margin-right: 12px;

		svg {
			margin: 0;
		}
	}

	.MuiDropzonePreviewList-root {
		display: none;
	}
`;

export const IconWrapper = styled(Box)`
	width: 40px;
	height: 40px;
	position: relative;

	> img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${grey};
	margin-bottom: 24px;
`;
