import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { applicationForm } from "assets/data";
import { getFormValues, addedErrorOnSections } from "utils/helpers";
import { baseApiUrl, candidateRoleId } from "utils/constants";
import {
	InputDataProps,
	InputOnChange,
	FormSectionDataProps,
	SelectOptionProps,
	ResponsibilityLevelItemProps,
} from "utils/types";
import { useRequest } from "utils/hooks";
import { useUserRole } from "utils/store";
import { Form } from "components/Form";
import { FormSection } from "components/FormSection";
import { Checkbox, Button, ConsentLabel } from "components/FormElements";
import { WhiteLayer } from "styles/common";
import { Preloader } from "components/Preloader";
import { FormContent, Wrapper, ButtonContainer, TitleBlock } from "./styles";
import { BackIconButton } from "components/BackIconButton";

const ApplicationCreatingPage = () => {
	const [sectionsProps, setSectionsProps] =
		useState<FormSectionDataProps[]>(applicationForm);
	const [formValues, setFormValues] = useState<any>();
	const [preloader, setPreloader] = useState(false);
	const [isPreLoading, setIsPreLoading] = useState(true);
	const [isConsent, setIsConsent] = useState(true);
	const [responsibility, setResponsibility] = useState<{
		levelManyContract: SelectOptionProps[];
		levelOneContract: SelectOptionProps[];
	}>();
	const request = useRequest;
	const formRef = useRef(null);
	const navigate = useNavigate();
	const roles = useUserRole();
	const url = `${baseApiUrl}/applications/`;
	const respUrl = `${baseApiUrl}/responsibility/`;

	useLayoutEffect(() => {
		request(respUrl).then((res) => {
			const {
				data: {
					responsibilityLevelManyContract,
					responsibilityLevelOneContract,
				},
			} = res;
			setResponsibility({
				levelManyContract: responsibilityLevelManyContract.map(
					({ id, label }: ResponsibilityLevelItemProps) => ({
						value: id,
						name: label,
					}),
				),
				levelOneContract: responsibilityLevelOneContract.map(
					({ id, label }: ResponsibilityLevelItemProps) => ({
						value: id,
						name: label,
					}),
				),
			});
			setTimeout(() => {
				setIsPreLoading(false);
			}, 250);
		});
	}, []);

	useEffect(() => {
		if (roles)
			roles.map(({ id }) => {
				if (id !== candidateRoleId) navigate("/applications");
			});
	}, [roles]);

	useEffect(() => {
		if (responsibility) {
			const newSections = sectionsProps.map(({ id, inputs, ...other }) => {
				let item = { id, inputs, ...other };
				if (id == "priceManyContract" || id == "priceOneContract") {
					item.inputs = inputs?.map(
						({ field, options, placeholder, ...other }) => {
							let resp =
								id == "priceManyContract"
									? responsibility.levelManyContract
									: responsibility.levelOneContract;

							let newItem = { field, placeholder, options, ...other };

							if (field === id) {
								newItem = {
									field,
									options: resp,
									placeholder: resp[0].name,
									...other,
								};
							}

							return newItem;
						},
					);
				}
				return item;
			});

			setSectionsProps(newSections);
		}
	}, [responsibility]);

	useEffect(() => {
		setFormValues(getFormValues(sectionsProps));
	}, [sectionsProps]);

	const scrollTo = (sections: FormSectionDataProps[]) => {
		if (formRef && formRef.current) {
			// @ts-ignore
			for (let i = 0; i < sections.length; i++) {
				const list = sections[i].inputs.find(({ invalid }) => invalid);

				if (list) {
					// @ts-ignore
					const section = formRef.current.querySelector(`#${sections[i].id}`);
					window.scrollTo({ top: section.offsetTop, behavior: "smooth" });
					break;
				}
			}
		}
	};

	const changeFormFields = (
		inputsList: InputDataProps[],
		nextOrgForm: string | number,
	) => {
		const isLegalOrgForm = nextOrgForm == 1;

		const changebleProps = {
			"2": isLegalOrgForm
				? { field: "fullName", label: "Полное наименование юридического лица" }
				: { field: "entrepreneursName", label: "Ф.И.О" },
			"4": isLegalOrgForm
				? {
						field: "shortName",
						label: "Сокращенное наименование юридического лица",
						type: undefined,
						invalid: false,
				  }
				: {
						field: "birthDate",
						label: "Дата рождения",
						type: "date",
						invalid: false,
				  },

			"5": isLegalOrgForm
				? { label: "ОГРН", minLength: 13, mask: "9999999999999" }
				: { label: "ОГРНИП", minLength: 15, mask: "999999999999999" },
		};

		let newInputList: InputDataProps[] = [];

		inputsList.map((item, index) => {
			let newItem = item;
			if (index === 2 || index === 4 || index === 5) {
				newItem = {
					...item,
					...changebleProps[index],
					value: "",
				};
			}
			newInputList.push(newItem);
		});

		return newInputList;
	};

	const sendForm = (data: any) => {
		if (data.errors) {
			const newSections = addedErrorOnSections(data.errors, sectionsProps);
			setSectionsProps(newSections);
			scrollTo(newSections);
		} else {
			navigate("/applications");
		}
		setTimeout(() => {
			setPreloader(false);
		}, 500);
	};

	const checkData = () => {
		let newSectionsProps: FormSectionDataProps[] = [];

		sectionsProps.map((section) => {
			let newInputs: InputDataProps[] = [];

			section.inputs.map(({ value, required, disabled, ...other }) => {
				let newItem = { value, required, disabled, ...other };

				if (required && String(value).length < 1 && !disabled) {
					newItem = {
						...newItem,
						invalid: true,
						helperText: "Это обязательное поле",
					};
				}

				newInputs.push(newItem);
			});

			newSectionsProps.push({ ...section, inputs: newInputs });
		});

		setSectionsProps(newSectionsProps);
		setTimeout(() => {
			scrollTo(newSectionsProps);
		}, 50);
	};

	const changeValue: InputOnChange = ({ field, value, id, suggestion }) => {
		const orgFormNameField = "organizationForm";
		const isSuggestion = suggestion && suggestion[orgFormNameField];
		let newSectionsProps: FormSectionDataProps[] = [];
		let isLocationMatches: any = sectionsProps[3].inputs[0].value;

		sectionsProps.map((section) => {
			let newInputs: InputDataProps[] = [];

			const inputsList =
				section.id === "company" && (field === orgFormNameField || isSuggestion)
					? changeFormFields(
							section.inputs,
							isSuggestion ? suggestion[orgFormNameField] : value,
					  )
					: section.inputs;

			inputsList.map((item) => {
				let newItem = item;

				if (field === item.field && id === `${item.field}-${item.id}`) {
					newItem = { ...item, value: value, invalid: false };
				}

				if (field === "locationMatches") {
					isLocationMatches = value;
				}

				if (section.id === "locationActual") {
					newItem.disabled = isLocationMatches;
					if (isLocationMatches) {
						newItem.value = "";
						newItem.invalid = false;
					}
				}

				if (suggestion) {
					if (
						(section.dadata && id.toString().includes(section.dadata)) ||
						(suggestion["organizationForm"] &&
							section.id !== "locationActual" &&
							section.id !== "contactPerson")
					) {
						newItem.value =
							suggestion[item.field] !== undefined
								? suggestion[item.field]
								: item.value;
						if (
							section.id !== "priceOneContract" &&
							section.id !== "priceManyContract"
						) {
							newItem.invalid =
								String(newItem.value).length == 0 &&
								newItem.required &&
								!newItem.disabled;
						}
					}
				}

				newInputs.push(newItem);
			});

			newSectionsProps.push({ ...section, inputs: newInputs });
		});

		setSectionsProps(newSectionsProps);
	};

	return (
		<WhiteLayer>
			<Wrapper>
				<TitleBlock>
					<BackIconButton link='/applications' />
					<Typography component='h2' variant='h2'>
						Создание заявки на вступление в члены СРО
					</Typography>
				</TitleBlock>
				<Preloader active={isPreLoading}>
					<Form
						url={url}
						values={formValues}
						afterSubmit={sendForm}
						beforeSubmit={() => setPreloader(true)}
						button={
							<ButtonContainer>
								<Button
									type='submit'
									preloader={{ loading: preloader, outside: true }}
									disabled={!isConsent || preloader}
									onClick={checkData}>
									Создать заявку
								</Button>
								<Checkbox
									id=''
									field='consent'
									label={<ConsentLabel centered />}
									checked={isConsent}
									onChange={({ value }) => setIsConsent(value)}
								/>
							</ButtonContainer>
						}>
						<FormContent ref={formRef}>
							{sectionsProps.map(({ id, ...other }, index) => (
								<FormSection
									key={id}
									id={id}
									index={index}
									{...other}
									onChange={changeValue}
								/>
							))}
						</FormContent>
					</Form>
				</Preloader>
			</Wrapper>
		</WhiteLayer>
	);
};

export default ApplicationCreatingPage;
