import { FormSectionDataProps } from "utils/types";
export const profilePassword: FormSectionDataProps[] = [
	{
		id: "password",
		title: "Смена пароля",
		inputs: [
			{
				id: 11,
				value: "",
				field: "oldPassword",
				type: "password",
				label: "Введите действующий пароль",
				required: true,
			},
			{
				id: 12,
				value: "",
				field: "password",
				type: "password",
				label: "Придумайте новый пароль",
				required: true,
			},
			{
				id: 13,
				value: "",
				field: "repeatedPassword",
				type: "password",
				label: "Повторите пароль",
				required: true,
			},
		],
	},
];
