import {
	UserProfile,
	UserInfo,
	UserAddresses,
	UserPassword,
	UserCompany,
	UserDetailPage,
} from "pages";
import { DetailTabelInfo } from "components/DetailTabelInfo";
import { ListLayout } from "components/ListLayout";
import { PrivateRoute } from "../PrivateRoute";

export const members = [
	{
		path: "/members/:id",
		id: 31,
		element: (
			<PrivateRoute>
				<UserDetailPage url='members' api='users/sro' />
			</PrivateRoute>
		),
		children: [
			{
				path: "related-applications",
				id: 331,
				element: (
					<PrivateRoute>
						<ListLayout
							type='applications'
							url='members'
							api='users/sro'
							detail={true}
						/>
					</PrivateRoute>
				),
			},
			{
				path: "full-info",
				id: 332,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
];

export const membersForAdmin = [
	{
		path: "/members/:id",
		id: 222,
		element: (
			<PrivateRoute>
				<UserProfile detail />
			</PrivateRoute>
		),
		children: [
			{
				path: "info",
				id: 2223,
				element: (
					<PrivateRoute>
						<UserInfo detail />
					</PrivateRoute>
				),
			},
			{
				path: "addresses",
				id: 2224,
				element: (
					<PrivateRoute>
						<UserAddresses detail />
					</PrivateRoute>
				),
			},
			{
				path: "company",
				id: 2225,
				element: (
					<PrivateRoute>
						<UserCompany detail />
					</PrivateRoute>
				),
			},
			{
				path: "password",
				id: 2226,
				element: (
					<PrivateRoute>
						<UserPassword detail />
					</PrivateRoute>
				),
			},
			{
				path: "related-applications",
				id: 2226,
				element: (
					<PrivateRoute>
						<ListLayout
							type='applications'
							url='applications'
							api='users/sro'
							detail={true}
						/>
					</PrivateRoute>
				),
			},
		],
	},
];
