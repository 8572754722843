import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { addEmployeeForm } from "assets/data";
import {
	getFormPureValues,
	addedPureErrorOnSections,
	hideErrorOnSections,
} from "utils/helpers";
import {
	InputOnChange,
	FormSectionDataProps,
	RoleItemProps,
	SelectOptionProps,
} from "utils/types";
import { useRequest } from "utils/hooks";
import { Form } from "components/Form";
import { Button } from "components/FormElements/Button";
import { FormSection } from "components/FormSection";
import { AddButton } from "components/AddButton";
import { Popup } from "components/Popup";
import { ButtonContainer } from "components/Form/styles";
import { Container } from "./styles";
import { baseApiUrl, candidateRoleId, memberRoleId } from "utils/constants";

export const AddEmployee = () => {
	const [sectionsProps, setSectionsProps] =
		useState<FormSectionDataProps[]>(addEmployeeForm);
	const [formValues, setFormValues] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [roles, setRoles] = useState<SelectOptionProps[]>([]);
	const request = useRequest;
	const navigate = useNavigate();
	const url = `${baseApiUrl}/users/employees/`;

	useLayoutEffect(() => {
		request(`${baseApiUrl}/admin/users/roles/`).then((res) => {
			const {
				data: { results },
				status,
			} = res;
			if (status < 400) {
				if (results && results.length > 0) {
					const rolesRes: SelectOptionProps[] = results
						.filter(
							(item: RoleItemProps) =>
								item.id !== candidateRoleId && item.id !== memberRoleId,
						)
						.map(({ name, id }: RoleItemProps) => ({ id, name, value: id }));

					setRoles(rolesRes);
				}
			}
		});
	}, []);

	useEffect(() => {
		setSectionsProps(
			sectionsProps.map((section) =>
				section.id === "roles"
					? {
							...section,
							inputs: section.inputs.map((item) =>
								item.field === "role" ? { ...item, options: roles } : item,
							),
					  }
					: section,
			),
		);
	}, [roles]);

	useEffect(() => {
		setFormValues(getFormPureValues(sectionsProps));
	}, [sectionsProps]);

	const changeValue: InputOnChange = ({ field, value, id }) => {
		setSectionsProps(
			sectionsProps.map((section) => ({
				...section,
				inputs: section.inputs.map((item) =>
					field === item.field ? { ...item, value } : item,
				),
			})),
		);
	};

	const sendForm = (data: any) => {
		let newSections = [];
		if (data.errors) {
			newSections = addedPureErrorOnSections(data.errors, sectionsProps);
		} else {
			newSections = hideErrorOnSections(sectionsProps);
			if (data) {
				navigate(`/staff/${data.id}`);
			}
		}
		setSectionsProps(newSections);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	return (
		<>
			<AddButton
				title='Добавить сотрудника'
				onClick={() => setShowPopup(true)}
			/>
			<Popup active={showPopup} afterClose={setShowPopup}>
				<Container>
					<Typography variant='h2' component='h2'>
						Добавление сотрудника
					</Typography>
					<Form
						url={url}
						values={formValues}
						afterSubmit={sendForm}
						beforeSubmit={() => setIsLoading(true)}
						button={
							<ButtonContainer>
								<Button
									type='submit'
									preloader={{ loading: isLoading, outside: true }}
									disabled={isLoading}>
									Сохранить
								</Button>
							</ButtonContainer>
						}>
						<Container>
							{sectionsProps.map(({ id, ...other }, index) => (
								<FormSection
									key={id}
									id={id}
									index={index}
									className='employee'
									{...other}
									onChange={changeValue}
								/>
							))}
						</Container>
					</Form>
				</Container>
			</Popup>
		</>
	);
};
