import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const Content = styled(Box)`
	display: flex;
	flex-direction: column;
	position: sticky;
	top: 171px;
	gap: 24px;
`;

export const Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const HistoryList = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 8px;
	position: relative;
`;
