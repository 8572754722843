import { ImageProps, RoleProps } from "utils/types";
import { getPosition } from "utils/helpers";
import { Avatar } from "components/Avatar";
import memberStyles from "./styles";

interface MemberProps {
	fullName: string;
	avatar: ImageProps | null;
	role: RoleProps[];
	id: number;
	color: string;
}

const { Wrapper, MemberInfo, Title, ImageWrapper, SubTitle } = memberStyles;

export const Member = ({ role, fullName, avatar, color }: MemberProps) => {
	const position = getPosition(role);
	return (
		<Wrapper>
			<ImageWrapper>
				<Avatar image={avatar} name={fullName} color={color} />
			</ImageWrapper>
			<MemberInfo>
				<Title>{fullName}</Title>
				{position && <SubTitle>{position}</SubTitle>}
			</MemberInfo>
		</Wrapper>
	);
};
