import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)`
	width: 100%;
	height: calc(100vh - 130px - 60px);
`;

export const Container = styled(Wrapper)`
	display: flex;
	align-items: center;
`;
