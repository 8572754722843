import {
	InputDataProps,
	FormSectionDataProps,
	InputsValueProps,
	ErrorProps,
	CheckboxListTypes,
} from "utils/types";

export const getInputsFromSections = (sections: FormSectionDataProps[]) => {
	let inputsData: InputDataProps[] = [];
	sections.map(({ inputs }) => inputs.map((item) => inputsData.push(item)));
	return inputsData;
};

export const setSectionsPropsFromInputs = (
	sections: FormSectionDataProps[],
	newValues: { [name: string]: any },
) => {
	let newSectionsProps: FormSectionDataProps[] = [];

	sections.map(({ inputs, ...other }) => {
		let newInputs: InputDataProps[] = [];
		inputs.map((item) =>
			newInputs.push(newValues[item.id] ? newValues[item.id] : item),
		);
		newSectionsProps.push({ ...other, inputs: newInputs });
	});

	return newSectionsProps;
};

export const getValueFromArray = (array: InputsValueProps[]) => {
	let values = {};
	array.map(({ field, value }) => {
		values = { ...values, [field]: value };
	});

	return values;
};

const errorcheck = (value: string) => {
	try {
		let parse = JSON.parse(`${value}`);
		return parse.name;
	} catch (err) {
		return value;
	}
};

export const getFormValues = (sections: FormSectionDataProps[]) => {
	const section = sections.find(({ id }) => id === "locationMatches");
	let locationMatches = section && section.inputs[0].value;
	let sectionsValues: { [key: string]: any } = {};
	let formValues: { [key: string]: {} } = {};

	sections.map(({ id, inputs }) => {
		sectionsValues = { ...sectionsValues, [id]: {} };

		inputs.map(({ field, value, replaceName }) => {
			if (field !== "repeatedPassword") {
				sectionsValues[id] = {
					...sectionsValues[id],
					[field]:
						replaceName && typeof value == "string" ? errorcheck(value) : value,
				};
			}
		});

		const isLocation = id.toString().includes("location");

		if (isLocation) {
			if (sectionsValues[id].locationMatches === undefined)
				if (locationMatches) {
					if (id !== "locationActual") formValues[id] = sectionsValues[id];
				} else {
					formValues[id] = sectionsValues[id];
				}
		} else {
			formValues[id] = sectionsValues[id];
		}
	});

	return formValues;
};

export const getFormPureValues = (sections: FormSectionDataProps[]) => {
	let formValues: { [key: string]: {} } = {};

	sections.map(({ inputs }) => {
		inputs.map(({ field, value, type, multiple }) => {
			if (field !== "repeatedPassword") {
				if (type === "select" && multiple) {
					// @ts-ignore
					let newVal = value.map((item) => JSON.parse(item).value);

					formValues[field] = newVal;
				} else {
					formValues[field] = value;
				}
			}
		});
	});

	return formValues;
};

export const addedErrorOnField = (
	errors: ErrorProps[],
	inputs: InputDataProps[],
) => {
	const newInputs = inputs.map((item) => {
		errors.map(({ field, message }) => {
			if (field === item.field) {
				item = {
					...item,
					helperText: message,
					invalid: true,
				};
			}
		});

		return item;
	});

	return newInputs;
};

export const addedErrorOnSections = (
	errors: ErrorProps[],
	sections: FormSectionDataProps[],
) => {
	const newSections: FormSectionDataProps[] = [];

	sections.map(({ inputs, id, ...other }) => {
		let newInputs: InputDataProps[] = [];

		inputs.map((item) => {
			let newItem = item;

			errors.map(({ field, message }) => {
				const fieldNames = field.split(".");

				if (fieldNames[0] === id && fieldNames[1] === item.field) {
					newItem = {
						...item,
						helperText: message,
						invalid: true,
					};
				}
			});

			newInputs.push(newItem);
		});

		newSections.push({ ...other, id, inputs: newInputs });
	});

	return newSections;
};

export const addedPureErrorOnSections = (
	errors: ErrorProps[],
	sections: FormSectionDataProps[],
) => {
	const newSections: FormSectionDataProps[] = [];

	sections.map(({ inputs, id, ...other }) => {
		let newInputs: InputDataProps[] = [];

		inputs.map((item) => {
			let newItem = item;

			errors.map(({ field, message }) => {
				if (field === item.field) {
					newItem = {
						...item,
						helperText: message,
						invalid: true,
					};
				}
			});

			newInputs.push(newItem);
		});

		newSections.push({ ...other, id, inputs: newInputs });
	});

	return newSections;
};

export const hideErrorOnSections = (sections: FormSectionDataProps[]) => {
	const newSections: FormSectionDataProps[] = [];
	sections.map(({ inputs, ...other }) => {
		let newInputs: InputDataProps[] = [];
		inputs.map((item) => {
			let newItem = item;
			if (item.invalid) {
				newItem = { ...newItem, invalid: false };
			}

			newInputs.push(newItem);
		});

		newSections.push({ ...other, inputs: newInputs });
	});

	return newSections;
};

export const filterCheckedValues = (
	array: CheckboxListTypes,
	defaultItem?: boolean,
) => {
	const values: { id: number | string }[] = [];

	console.log(array);

	array.map(({ id, checked }) => {
		if (checked) values.push({ id });
	});

	if (values.length == 0 && defaultItem) {
		values.push({ id: array[0].id });
	}

	return values;
};
