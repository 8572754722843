import { styled } from "@mui/material/styles";
import { Box, Modal as ModalMui } from "@mui/material";
import { IconButton } from "components/IconButton";
import { liteGrey } from "styles/colors";

export const Modal = styled(ModalMui)`
	display: flex;
	align-items: center;
	justify-content: center;

	.MuiPaper-root {
		margin-top: 0;
	}
`;

export const Wrapper = styled(Box)`
	position: relative;
	padding: 40px;
`;

export const Container = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	opacity: 0;

	&.active {
		transition: all 0.25s ease-in-out;
		opacity: 1;
	}

	.MuiCircularProgress-root {
		color: ${liteGrey};
	}
`;

export const Content = styled(Box)``;

export const CloseButton = styled(IconButton)`
	position: absolute;
	right: 8px;
	top: 8px;
`;
