import { Container, Content } from "./styles";

export const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<Container>
			<Content>{year} © Все права защищены</Content>
		</Container>
	);
};
