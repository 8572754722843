import { FormSectionDataProps, InputOnChange } from "utils/types";
import { InputsSpreader } from "components/FormElements";
import { Title, Section, InputsContainer } from "./styles";

interface FormSectionProps extends FormSectionDataProps {
	index: number;
	className?: string;
	onChange: InputOnChange;
	headlineSize?: "h3" | "h6";
}

export const FormSection = ({
	id,
	title,
	index,
	inputs,
	className,
	headlineSize = "h6",
	onChange,
}: FormSectionProps) => {
	const isRequiredTitle =
		id === "priceOneContract" || id === "priceManyContract";

	return (
		<Section>
			{title && (
				<Title
					// @ts-ignore
					component={headlineSize && headlineSize}
					variant={headlineSize && headlineSize}
					required={isRequiredTitle}>
					{title}
				</Title>
			)}
			<InputsContainer
				id={id ? String(id) : undefined}
				className={`${className ? className : "section"}${index + 1}`}>
				{inputs.map(({ id, ...other }, index) => (
					<InputsSpreader
						key={id}
						id={id}
						{...other}
						className={`item${index + 1}`}
						onChange={onChange}
					/>
				))}
			</InputsContainer>
		</Section>
	);
};
