import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { robotoRegular } from "styles/fonts";
import { grey, blue } from "styles/colors";

const NavLink = styled(RouterLink)`
	font-family: ${robotoRegular};
	font-size: 15px;
	line-height: 130%;
	text-decoration: none;
	color: ${grey};
	display: flex;
	align-items: center;
	padding: 16px;
	gap: 8px;
	position: relative;
	transition: all 0.25s ease-in-out;

	&:hover,
	&.active {
		color: ${blue};

		svg {
			stroke: ${blue};
		}
	}

	&.active {
		background: #e4eaf1;
		border-radius: 10px;
	}

	svg {
		width: 19px;
		height: 19px;
		stroke: ${grey};
		transform: translateY(-3px);
		transition: all 0.25s ease-in-out;
	}
`;

export { NavLink };
