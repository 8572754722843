import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Сontent = styled(Box)`
	min-height: calc(100vh - 130px - 60px);
`;

export const NotFoundWrapper = styled(Сontent)`
	display: flex;
	align-items: center;
`;

export const TabsContent = styled(Box)`
	min-height: calc(100vh - 190px - 65px - 72px - 36px);
`;
