import { useState, useLayoutEffect, useEffect, SyntheticEvent } from "react";
import { useLocation } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Typography } from "@mui/material";
import { useRequest } from "utils/hooks";
import { ErrorResult } from "components/ErrorResult";
import { NoАccess } from "components/NoАccess";
import { Preloader } from "components/Preloader";
import { Input, Button, Checkbox } from "components/FormElements";
import { PermissionsCreateOrDelete } from "components/PermissionsCreateOrDelete";
import { BackIconButton } from "components/BackIconButton";
import { WhiteLayer } from "styles/common";
import { baseApiUrl } from "utils/constants";
import {
	InputDataProps,
	InputOnChange,
	NotificationSettings,
	NotificationOption,
} from "utils/types";
import {
	Сontent,
	NotFoundWrapper,
	Permissions,
	Header,
	TitleSkeleton,
	ButtonWrapper,
	PermissionsWrapper,
	Label,
} from "./styles";

export const NotificationDetailPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(null);
	const [isNoAccess, setIsNoAccess] = useState(false);
	const [permissions, setPermissions] = useState<NotificationSettings | null>(
		null,
	);
	const request = useRequest;
	const { pathname } = useLocation();
	const linkParamId = pathname.split("/").at(-1);
	const apiUrl = `${baseApiUrl}/admin/notifications/settings/${linkParamId}/`;
	const [inputProps, setInputProps] = useState<InputDataProps[]>([]);
	const [permissiomsOptions, setPermissionsOptions] = useState<
		NotificationOption[]
	>([]);

	useLayoutEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		sendRequest();
	}, []);

	useEffect(() => {
		if (permissions) {
			setPermissionsOptions(permissions.options);
		}
	}, [permissions]);

	const sendRequest = () => {
		request(apiUrl).then((res) => {
			const { data, status } = res;
			if (status < 400) {
				setPermissions(data);
			} else {
				if (status === 404 || status === 500) {
					setIsError(status);
				} else {
					setIsNoAccess(true);
				}
			}
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		});
	};

	const changeValue: InputOnChange = ({ field, value }) => {
		const newInputProps = inputProps.map((item) =>
			field === item.field ? { ...item, value, invalid: false } : item,
		);
		setInputProps(newInputProps);
	};

	const changeNotice: InputOnChange = ({ field, value }) => {};

	return (
		<WhiteLayer>
			{isError || isNoAccess ? (
				<NotFoundWrapper>
					{isError ? (
						<ErrorResult code={isError} url={`/`} />
					) : (
						<NoАccess url={`/`} />
					)}
				</NotFoundWrapper>
			) : (
				<>
					<Header>
						<BackIconButton />
						{permissions && !isLoading ? (
							<Typography component='h1' variant='h2'>
								{permissions.name}
							</Typography>
						) : (
							<TitleSkeleton variant='rounded' />
						)}
					</Header>
					<Сontent>
						<SwitchTransition mode='out-in'>
							<CSSTransition
								key={permissions ? "content" : "loader"}
								timeout={300}
								classNames='fade'>
								{permissions && !isLoading ? (
									<Permissions>
										{permissiomsOptions.map((option) => (
											<PermissionsWrapper>
												<Checkbox
													id={option.id}
													field={option.codeName}
													label={option.name}
													checked={option.checked}
													onChange={changeNotice}
													className='permission-title'
												/>
												{option.options.map((item) => (
													<PermissionsCreateOrDelete
														permission={item}
														fieldType={
															item.codeName === "receive_notice_type" &&
															option.codeName !== "deadline_object"
																? "radio"
																: "chekbox"
														}
													/>
												))}
											</PermissionsWrapper>
										))}
										{/* <PermissionsWrapper>
											<Checkbox
												id={11}
												field='createObject'
												label={permissions.createObject.name}
												checked={false}
												onChange={changeNotice}
												className='permission-title'
											/>
											<PermissionsCreateOrDelete
												data={permissions.createObject}
												type='create'
											/>
										</PermissionsWrapper>

										<PermissionsWrapper>
											<Checkbox
												id={12}
												field='deleteObject'
												label={permissions.deleteObject.name}
												checked={false}
												onChange={changeNotice}
												className='permission-title'
											/>
											<PermissionsCreateOrDelete
												data={permissions.deleteObject}
												type='delete'
											/>
										</PermissionsWrapper>

										<PermissionsWrapper>
											<Checkbox
												id={13}
												field='deadlineObject'
												label={permissions.deadlineObject.name}
												checked={false}
												onChange={changeNotice}
												className='permission-title'
											/>
											<Label>Укажите срок рассмотрения заявки, дней</Label>
											<Input
												id={1}
												field='deadline'
												value={0}
												onChange={changeValue}
											/>
											<Checkbox
												id={2}
												field='notice'
												label='Повторные уведомления за день до истечения срока'
												checked={false}
												onChange={changeValue}
											/>
										</PermissionsWrapper> */}
										<ButtonWrapper>
											<Button
												preloader={{ loading: isLoading }}
												disabled={isLoading}
												onClick={() => {}}>
												Сохранить изменения
											</Button>
											<Button variant='outlined' onClick={() => {}}>
												Отменить
											</Button>
										</ButtonWrapper>
									</Permissions>
								) : (
									<Preloader active={isLoading} />
								)}
							</CSSTransition>
						</SwitchTransition>
					</Сontent>
				</>
			)}
		</WhiteLayer>
	);
};

export default NotificationDetailPage;
