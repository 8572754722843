import { useState, useLayoutEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Typography } from "@mui/material";
import { useRequest } from "utils/hooks";
import { ErrorResult } from "components/ErrorResult";
import { NoАccess } from "components/NoАccess";
import { Preloader } from "components/Preloader";
import { LinkWrapper } from "components/Wrappers";
import { IconButton } from "components/IconButton";
import { Container } from "components/PageTemplate/styles";
import { WhiteLayer } from "styles/common";
import { baseApiUrl } from "utils/constants";
import { NotificationItem } from "utils/types";
import { ArrowIcon } from "assets/icons";
import { Сontent, NotFoundWrapper, ListWrapper } from "./styles";

export const NotificationsSettingsPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(null);
	const [isNoAccess, setIsNoAccess] = useState(false);
	const [list, setList] = useState<NotificationItem[]>([]);
	const request = useRequest;
	const apiUrl = `${baseApiUrl}/admin/notifications/settings/`;

	useLayoutEffect(() => {
		sendRequest();
	}, []);

	const sendRequest = () => {
		request(apiUrl).then((res) => {
			const { data, status } = res;
			if (status < 400) {
				setList(data.results);
				setTimeout(() => {
					setIsLoading(false);
				}, 250);
			} else {
				if (status === 404 || status === 500) {
					setIsError(status);
				} else {
					setIsNoAccess(true);
				}
			}
		});
	};

	return (
		<Container>
			{isError || isNoAccess ? (
				<NotFoundWrapper>
					{isError ? (
						<ErrorResult code={isError} url={`/`} />
					) : (
						<NoАccess url={`/`} />
					)}
				</NotFoundWrapper>
			) : (
				<>
					<Typography component='h1' variant='h2'>
						Настройка уведомлений
					</Typography>
					<Сontent>
						<SwitchTransition mode='out-in'>
							<CSSTransition
								key={list ? "content" : "loader"}
								timeout={300}
								classNames='fade'>
								{list && !isLoading ? (
									<ListWrapper>
										{list.map(({ id, name }) => (
											<LinkWrapper
												url={`/notifications/${id}`}
												className='notice-item'>
												<WhiteLayer className='notice-item-wr'>
													<Typography variant='h4'>{name}</Typography>
													<IconButton className='arrow-button'>
														<ArrowIcon />
													</IconButton>
												</WhiteLayer>
											</LinkWrapper>
										))}
									</ListWrapper>
								) : (
									<Preloader active={isLoading} />
								)}
							</CSSTransition>
						</SwitchTransition>
					</Сontent>
				</>
			)}
		</Container>
	);
};

export default NotificationsSettingsPage;
