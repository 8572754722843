import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { liteGrey } from "styles/colors";
import { robotoRegular } from "styles/fonts";

export const Container = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
`;

export const Adornment = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 20px;
	color: ${liteGrey};
	margin-left: 12px;
`;
