import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 8px;
`;

export const Users = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 4px;
`;

export const AvatarWrapper = styled(Box)`
	position: relative;
	width: 30px;
	height: 30px;
`;
