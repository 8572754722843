export const getPathByType = (type?: string) => {
	let path = "";

	switch (type) {
		case "expert_opinion":
			path = "/expert-opinions/";
			break;
		case "commission_control":
			path = "/protocols-control-commission/";
			break;
		case "agenda":
			path = "/questions-agenda/";
			break;
		case "presidium":
			path = "/protocols-presidium/";
			break;
		case "act":
			path = "/aсts-issued/";
			break;
		default:
			path = "/applications/";
			break;
	}

	return path;
};

export const getTypeByPath = (path?: string) => {
	let type = "";

	switch (path) {
		case "expert-opinions":
			type = "expert_opinion";
			break;
		case "protocols-control-commission":
			type = "commission_controls";
			break;
		case "questions-agenda":
			type = "agenda";
			break;
		case "protocols-presidium":
			type = "presidium";
			break;
		case "aсts-issued":
			type = "act";
			break;
		default:
			type = "applications";
			break;
	}

	return type;
};

export const getApiByType = (type?: string) => {
	let url = "";

	switch (type) {
		case "expert_opinion":
			url = "expert_opinions";
			break;
		case "commission_control":
			url = "commission_controls";
			break;
		case "presidium":
			url = "presidium";
			break;
		case "agenda":
			url = "agenda";
			break;
		case "act":
			url = "acts";
			break;
		default:
			url = "applications";
			break;
	}

	return url;
};
export const formElems = [
	{ type: "uploader" },
	{ type: "textarea", field: "comment" },
	{
		type: "select",
		input: {
			field: "priceManyContract",
			label: "Признак исправления",
			options: [
				{
					id: 1,
					value: "45",
					name: "ЧекKek",
				},
				{
					id: 2,
					value: "",
					name: "",
				},
			],
		},
	},
];
