import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { liteGrey } from "styles/colors";

interface ButtomBlockProps {
	indicator?: string;
}

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 600px;
	position: relative;
`;

export const CustomForm = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const ListWrapper = styled(Box)`
	position: relative;
	left: 2px;
	width: calc(100% - 4px);
	height: 430px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const BottomBlock = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: ${({ indicator }: ButtomBlockProps) =>
		indicator === "true" ? "space-between" : "flex-end"};
`;

export const Empty = styled(Box)`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
