import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const List = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 8px;

	.application-item {
		padding: 0 20px;
	}
`;
