import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicationDetailProps, ApplicationDetailType } from "utils/types";
import { RootState } from "../generateState";

interface ApplicationContentProps {
	applicationContent: ApplicationDetailProps<ApplicationDetailType>;
}

const initialState: ApplicationContentProps = {
	applicationContent: {
		common: null,
		attachmentFiles: [],
		history: [],
		maxFilesSize: undefined,
		table: [],
		relatedApplications: null,
	},
};

const slice = createSlice({
	name: "applicationContent",
	initialState,
	reducers: {
		setApplicationContent: (
			state,
			{ payload }: PayloadAction<ApplicationDetailProps<ApplicationDetailType>>,
		) => {
			state.applicationContent = payload;
		},
	},
});

export const { setApplicationContent } = slice.actions;

export const selectApplicationContent = (state: RootState) =>
	state.applicationContent;

export const selectApplicationId = (state: RootState) =>
	state.applicationContent.applicationContent.common?.id;

export default slice.reducer;
