import { BackIcon } from "assets/icons";
import { Button } from "components/FormElements/Button";
import { LinkWrapper } from "components/Wrappers";

interface BackButtonProps {
	link: string;
}

export const BackButton = ({ link }: BackButtonProps) => {
	return (
		<LinkWrapper url={link}>
			<Button variant='outlined' icon={<BackIcon />} secondary>
				Назад
			</Button>
		</LinkWrapper>
	);
};
