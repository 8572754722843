import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import { blue, grey, line } from "styles/colors";
import { robotoRegular } from "styles/fonts";

export const OneTab = styled(Box)`
	position: relative;
	background: transparent;
	padding-bottom: 14px;
	cursor: pointer;
	border-bottom: transparent solid 2px;

	span {
		font-family: ${robotoRegular};
		font-size: 15px;
		line-height: 130%;
		color: ${grey};
	}

	&.tabs-active {
		border-bottom: ${blue} solid 2px;

		span {
			color: ${blue};
		}
	}
`;
export const Container = styled(Box)`
	display: flex;
	border-bottom: 1px solid ${line};
	padding-bottom: 0px;
	gap: 36px;

	> a {
		text-decoration: none;
	}
`;

export const Wrapper = styled(Box)`
	position: relative;
	width: 100%;
	height: 100%;
	margin-top: 32px;
	display: grid;
	gap: 40px;
	grid-template-rows: 36px auto;
`;
