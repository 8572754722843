import { FormSectionDataProps } from "utils/types";

export const profileCompany: FormSectionDataProps[] = [
	{
		id: "company",
		title: "Информация о компании",
		inputs: [
			{
				id: 10,
				value: "",
				field: "inn",
				label: "ИНН",
				type: "dadata",
				suggest: "party",
				minLength: 10,
				maxLength: 12,
				mask: "999999999999",
				required: true,
				changeable: true,
			},
			{
				id: 11,
				value: 1,
				field: "organizationForm",
				type: "radio",
				options: [
					{ id: 1, name: "Юр. лицо", value: 1 },
					{ id: 2, name: "ИП", value: 2 },
				],
			},
			{
				id: 12,
				value: "",
				field: "fullName",
				label: "Полное наименование юридического лица",
				required: true,
				changeable: true,
			},
			{
				id: 13,
				value: "",
				field: "managerPhone",
				label: "Телефон руководителя",
				mask: "+7 999 999 99 99",
				required: true,
			},
			{
				id: 14,
				value: "",
				field: "shortName",
				label: "Сокращенное наименование юридического лица",
				required: true,
				dateProps: {
					disableFuture: true,
				},
				changeable: true,
			},
			{
				id: 15,
				value: "",
				field: "ogrn",
				label: "ОГРН",
				minLength: 13,
				mask: "9999999999999",
				required: true,
				changeable: true,
			},
			{
				id: 16,
				value: "",
				field: "site",
				label: "Адрес сайта",
			},
		],
	},
	{
		id: "levels",
		title: "Уровни ответственности",
		inputs: [
			{
				id: 17,
				value: [],
				label: "Стоимость осуществляемая по одному договору",
				field: "responsibilityLevelOneContract",
				placeholder: "",
				replaceName: true,
				disabled: true,
			},
			{
				id: 18,
				value: [],
				label: "Стоимость договоров строительного подряда",
				field: "responsibilityLevelManyContract",
				placeholder: "",
				replaceName: true,
				disabled: true,
			},
		],
	},
];
