import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { ButtonContainer as ButtonContainerParent } from "components/Form/styles";
import { liteGrey } from "styles/colors";
import { robotoMedium } from "styles/fonts";

interface InputWrapperProps {
	width?: string;
}

export const Text = styled(Typography)`
	font-family: ${robotoMedium};
	font-size: 18px;
	line-height: 130%;
	text-align: center;
	color: ${liteGrey};
	margin: 8px 0 32px;
`;

export const Title = styled(Typography)`
	margin: 32px 0;
`;

export const FormContent = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

export const InputWrapper = styled(Box)`
	width: ${({ width }: InputWrapperProps) => (width ? width : "100%")};
`;

export const ButtonContainer = styled(ButtonContainerParent)`
	display: flex;
	gap: 8px;
	padding-top: 8px;
`;
