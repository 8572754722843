import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Pagination as PaginationMui, PaginationItem } from "@mui/material";

interface PaginationProps {
	count: number;
}

export const Pagination = ({ count }: PaginationProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const defaultNumber = (page ? parseInt(page) : null) || 1;
	const [currentPage, setCurrentPage] = useState<number>(defaultNumber);

	useEffect(() => {
		if (!page) {
			setCurrentPage(1);
		}
	}, [searchParams, page]);

	const togglePage = (newPage: number) => {
		// @ts-ignore
		const currentParams = Object.fromEntries([...searchParams]);
		setSearchParams({ ...currentParams, page: `${newPage}` });
		setCurrentPage(newPage);
	};
	return (
		<PaginationMui
			count={count}
			page={currentPage}
			onChange={(e, page) => togglePage(page)}
			variant='outlined'
			shape='rounded'
			renderItem={({ type, ...other }) => (
				<PaginationItem
					{...other}
					type={type}
					className={type === "previous" || type === "next" ? type : undefined}
				/>
			)}
		/>
	);
};
