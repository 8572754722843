import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { StatusVariant, StatusPropType } from "utils/types";
import { rgba } from "styles/mixins";
import { red, grey, green, blue, line, yellow, liteGrey, purple } from "styles/colors";
import { robotoMedium, robotoRegular } from "styles/fonts";

interface StatusProps {
	variant: StatusVariant;
	type?: StatusPropType;
	rounded?: string;
}

export const StatusContainer = styled(Box)`
	display: flex;
	align-items: center;
	font-family: ${robotoMedium};
	text-transform: uppercase;
	border-radius: ${({ rounded }: StatusProps) => (rounded ? "20px" : "6px")};
	white-space: nowrap;
	font-size: 14px;
	padding: 5px 8px 3px;

	&.small {
		font-size: 12px;
	}

	> svg {
		display: flex;
		align-items: center;
		width: 13px;
		height: 13px;
		margin-right: 5px;
	}

	${({ variant, type }: StatusProps) => {
		let typeStyles;
		let variantStyles;

		switch (type) {
			case "vote":
				typeStyles = `
					padding: 6px 8px 4px;
                `;
				break;
			case "standart":
				typeStyles = `
					font-family: ${robotoRegular};
					text-transform: none;
					
                `;
				break;
			case "application":
				typeStyles = `
					font-size: 10px;
					line-height: 13px;
					padding: 4px 8px;
				`;
				break;
			default:
				typeStyles = ``;
				break;
		}

		switch (variant) {
			case "green":
				variantStyles = `
                    background: ${rgba(green, 0.1)};
                    color: ${green};
					stroke: ${green};
                `;
				break;
			case "red":
				variantStyles = `
                    background: ${rgba(red, 0.1)};
                    color: ${red};
					stroke: ${red};
                `;
				break;
			case "yellow":
				variantStyles = `
                    background: ${rgba(yellow, 0.1)};
                    color: ${yellow};
					stroke: ${yellow};
                `;
				break;
				case "purple":
				variantStyles = `
                    background: ${rgba(purple, 0.1)};
                    color: ${purple};
					stroke: ${purple};
                `;
				break;
			
			case "grey":
				variantStyles = `
				background: ${rgba(liteGrey, 0.3)};
				color: ${grey};
				stroke: ${grey};
			`;
				break;
			default:
				variantStyles =
					type === "application"
						? `
							background: ${rgba(blue, 0.1)};
							color: ${blue};
							stroke: ${blue};
							`
						: `
							background: ${line};
							color: ${grey};
                	`;
				break;
		}

		return typeStyles + variantStyles;
	}}
`;
