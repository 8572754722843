import { ListItemContentProps } from "utils/types";
import { LinkWrapper } from "components/Wrappers";
import { WhiteLayer } from "styles/common";
import { ListItemContent } from "./ListItemContent";

interface ListItemProps {
	url?: string;
	wrapperClass?: string;
	content: ListItemContentProps;
	from?: any;
	size?: "small";
	light?: boolean;
}

export const ListItem = ({
	url,
	wrapperClass,
	content,
	from,
	size,
	light,
}: ListItemProps) => {
	return (
		<LinkWrapper url={url} state={{ from }}>
			<WhiteLayer className={wrapperClass}>
				<ListItemContent {...content} url={url} size={size} light={light} />
			</WhiteLayer>
		</LinkWrapper>
	);
};
