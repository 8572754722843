import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { RoleDetailProps } from "utils/types";
import { RootState } from "../generateState";

interface RoleContentProps {
	roleContent: RoleDetailProps | null;
}

const initialState: RoleContentProps = {
	roleContent: null,
};

const slice = createSlice({
	name: "roleContent",
	initialState,
	reducers: {
		setRoleContent: (
			state,
			{ payload }: PayloadAction<RoleDetailProps | null>,
		) => {
			state.roleContent = payload;
		},
	},
});

export const { setRoleContent } = slice.actions;

export const selectRoleContent = (state: RootState) => state.roleContent;

export default slice.reducer;
