import { useEffect } from "react";
import { addTitlePage } from "utils/helpers";
import { PassRecoveryForm } from "components/Auth/PassRecoveryForm";

const PasswordRecovery = () => {
	useEffect(() => {
		addTitlePage("/login/password-pecovery");
	}, []);

	return <PassRecoveryForm />;
};

export default PasswordRecovery;
