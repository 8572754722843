import { profilePassword } from "assets/data";
import { UserPageProps } from "utils/types";
import { ProfileForm } from "components/ProfileForm";

export const UserPassword = ({ detail }: UserPageProps) => {
	return (
		<ProfileForm
			name='password'
			content={profilePassword}
			link='/auth/change-password/'
			method='PATCH'
			detail={detail}
		/>
	);
};

export default UserPassword;
