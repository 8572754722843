import { NavLink } from "./styles";

interface SideMenuLink {
	url: string;
	title: string;
	icon: any;
	state?: { preloader?: boolean; commonPath?: string };
	active?: boolean;
}

export const SideMenuLink = ({
	url,
	title,
	icon,
	state,
	active,
}: SideMenuLink) => {
	const Icon = icon;

	return (
		<NavLink to={url} state={state} className={active ? "active" : ""}>
			<Icon />
			{title}
		</NavLink>
	);
};
