import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addTitlePage } from "utils/helpers";
import { useRequest } from "utils/hooks";
import { baseApiUrl } from "utils/constants";
import { useAuthData } from "utils/store";
import { PassCreateForm } from "components/Auth/PassCreateForm";
import { Preloader } from "components/Preloader";

const NewPasswordPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isReset, setIsReset] = useState(false);
	const [token, setToken] = useState("");
	const authData = useAuthData();
	const navigate = useNavigate();
	const request = useRequest;
	const location = useLocation();
	const { state, search } = location;
	const isRedirect = !authData || !state || !state.from;
	const showPage = !isRedirect || search;

	useEffect(() => {
		addTitlePage("/create-password");
		if (!showPage) {
			navigate("/login");
		}
		if (!isRedirect) {
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		}
		if (search) {
			setIsReset(true);
			const token = search.split("=")[1];
			setToken(token);
			const options = {
				method: "POST",
				data: { token },
			};
			request(`${baseApiUrl}/auth/reset-password/confirm/`, options, true).then(
				(res) => {
					const { status } = res;
					if (status >= 200 && status < 300) {
						setTimeout(() => {
							setIsLoading(false);
						}, 250);
					} else {
						navigate("/login");
					}
				},
			);
		}
	}, []);

	return (
		<>
			{showPage ? (
				<Preloader active={isLoading}>
					<PassCreateForm
						title='Придумайте пароль'
						buttonName={authData ? 'Зарегистрироваться': 'Сохранить пароль'}
						link={state && state.from}
						params={authData || { token: token }}
						isReset={isReset}
					/>
				</Preloader>
			) : null}
		</>
	);
};

export default NewPasswordPage;
