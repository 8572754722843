import { ReactElement } from "react";
import { Container } from "./styles";

interface ChipProps {
	label: string;
	small?: boolean;
	icon?: ReactElement;
}

export const Chip = ({ label, small, icon }: ChipProps) => {
	return (
		<Container
			icon={icon}
			label={label}
			size='small'
			variant='outlined'
			small={`${small}`}
		/>
	);
};
