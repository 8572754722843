import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { white } from "styles/colors";

interface ContainerProps {
	nopadding?: string;
}
export const Container = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: ${({ nopadding }: ContainerProps) =>
		nopadding === "true" ? 0 : "16px 32px"};
	background-color: ${white};
	border-radius: 10px;
`;

export const LeftBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const RightBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const AvatarWrapper = styled(Box)`
	width: 50px;
	height: 50px;
	margin-right: 14px;
`;

export const ColBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
`;

export const RolesBlock = styled(Box)`
	display: flex;
	gap: 10px;
`;
