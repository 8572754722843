import { ListLayout } from "components/ListLayout";

const PresidiumPage = () => {
	return (
		<ListLayout
			title='Реестр протоколов президиума'
			url='protocols-presidium'
			api='presidium'
			type='applications'
		/>
	);
};

export default PresidiumPage;
