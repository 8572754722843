import { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { FileObject } from "material-ui-dropzone";
import { FileItemProps } from "utils/types";
import { Button } from "components/FormElements/Button";
import { UploadScreen } from "./UploadScreen";
import { UploadItem } from "./UploadItem";
import { UploadList, Container } from "./styles";

interface InputFilesProps {
	value: string;
	files: FileList;
}

interface UploaderProps {
	multiple?: boolean;
	accept?: string[];
	maxCount?: number;
	maxSize?: number;
	screen?: boolean;
	onChange: (data: FileItemProps[]) => void;
	updateValues: boolean;
}

export const Uploader = ({
	multiple = false,
	accept,
	maxCount,
	maxSize,
	screen,
	onChange,
	updateValues,
}: UploaderProps) => {
	const [selectedFile, setSelectedFile] = useState<FileItemProps[]>([]);
	const [fileObjects, setFileObjects] = useState<FileObject[]>([]);
	const [counter, setCounter] = useState(11);
	const [inputValue, setInputValues] = useState<InputFilesProps>({
		value: "",
		files: new DataTransfer().files,
	});

	useEffect(() => {
		if (updateValues) {
			setInputValues({ value: "", files: new DataTransfer().files });
			setSelectedFile([]);
			onChange([]);
		}
	}, [updateValues]);

	const updateFiles = (files: any, value?: string) => {
		let newFiles: FileItemProps[] = selectedFile.length > 0 ? selectedFile : [];
		let count = counter;

		[...files].map((file) => {
			const has = selectedFile.find((item) => item.file.name === file.name);
			if (!has) {
				newFiles.push({ ...file, id: count });

				if (!multiple && selectedFile.length > 1) {
					newFiles = newFiles.slice(1);
				}

				count++;
			}
		});

		setCounter(counter + count);
		setSelectedFile(newFiles);
		onChange(newFiles);
		if (value)
			setInputValues({
				value: value,
				files: files,
			});
	};

	const onFileChange = (event: any) => {
		const target = event.target;
		const { files, value } = target;
		updateFiles(files, value);
	};

	const onDropFilesChange = (files: any) => {
		updateFiles(files);
		setFileObjects([...fileObjects, ...files]);
	};

	const onRemove = (id: number) => {
		const newFiles = selectedFile.splice(0);
		const ind = newFiles.findIndex((item) => item.id === id);

		if (ind !== -1) {
			let newobj: FileObject[] = [];

			newFiles.splice(ind, 1); // @ts-ignore
			newFiles.map(({ file, data }) => {
				// @ts-ignore
				newobj.push({ file, data });
			});

			setSelectedFile(newFiles);
			setFileObjects(newobj);

			if (newFiles.length === 0) {
				setInputValues({ value: "", files: new DataTransfer().files });
			}

			onChange(newFiles);
		}
	};

	return (
		<Container>
			{screen ? (
				<UploadScreen
					onChange={onDropFilesChange}
					accept={accept}
					files={fileObjects}
				/>
			) : (
				<Button
					variant='outlined'
					uploade
					uploadeProps={{
						...inputValue,
						multiple,
						onChange: onFileChange,
					}}>
					Загрузить файлы
				</Button>
			)}
			{selectedFile.length > 0 && (
				<UploadList>
					<TransitionGroup component={null}>
						{selectedFile.map(({ file, id }, index) => {
							const delay = index * 100;

							return (
								<CSSTransition key={id} classNames='grow' timeout={250 + delay}>
									<UploadItem
										file={file}
										id={id}
										onRemove={onRemove}
										delay={delay}
									/>
								</CSSTransition>
							);
						})}
					</TransitionGroup>
				</UploadList>
			)}
		</Container>
	);
};
