import { tabsGenerate } from "./generators";
import { downloadResponse } from "./request";
import { getUserIdByJwt } from "./jwtDecode";
import {
	getPosition,
	formatBytes,
	declinationOfNumber,
	convertDateToFormat,
	convertTimeToFormat,
} from "./converting";
import {
	getFormValues,
	getInputsFromSections,
	hideErrorOnSections,
	setSectionsPropsFromInputs,
	addedErrorOnField,
	addedErrorOnSections,
	getValueFromArray,
	filterCheckedValues,
	getFormPureValues,
	addedPureErrorOnSections,
} from "./forms";
import { getApiByType, getPathByType, getTypeByPath } from "./links";
import {
	clearLocalStorage,
	setTokenFromStorage,
	getTokensFromStorage,
} from "./localStorage";

import { addTitlePage } from "./addTitle";
export {
	addTitlePage,
	clearLocalStorage,
	getApiByType,
	getFormValues,
	getPathByType,
	getInputsFromSections,
	getValueFromArray,
	filterCheckedValues,
	getPosition,
	formatBytes,
	hideErrorOnSections,
	setTokenFromStorage,
	setSectionsPropsFromInputs,
	addedErrorOnField,
	addedErrorOnSections,
	getUserIdByJwt,
	getTokensFromStorage,
	downloadResponse,
	tabsGenerate,
	declinationOfNumber,
	getTypeByPath,
	getFormPureValues,
	addedPureErrorOnSections,
	convertDateToFormat,
	convertTimeToFormat,
};
