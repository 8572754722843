import { RoleItemProps } from "utils/types";
import { LinkWrapper } from "components/Wrappers";
import { IconButton } from "components/IconButton";
import { WhiteLayer } from "styles/common";
import { ArrowIcon } from "assets/icons";
import { Container, Name, Count } from "./styles";

export const RoleItem = ({ id, countUsers, name }: RoleItemProps) => {
	return (
		<LinkWrapper url={`/roles/${id}`} className='role-item'>
			<WhiteLayer className='role-item-wr'>
				<Container>
					<Name>{name}</Name>
					<Count>{countUsers} чел</Count>
				</Container>
				<IconButton className='arrow-button'>
					<ArrowIcon />
				</IconButton>
			</WhiteLayer>
		</LinkWrapper>
	);
};
