import { profileCompany } from "assets/data";
import { UserPageProps } from "utils/types";
import { ProfileForm } from "components/ProfileForm";

export const UserCompany = ({ detail }: UserPageProps) => {
	return (
		<ProfileForm
			name='company'
			content={profileCompany}
			detail={detail}
			withLevel
		/>
	);
};

export default UserCompany;
