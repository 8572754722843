import {
	TransitionGroup,
	SwitchTransition,
	CSSTransition,
} from "react-transition-group";
import { Typography } from "@mui/material";
import { baseApiUrl } from "utils/constants";
import { useRequest } from "utils/hooks";
import { downloadResponse } from "utils/helpers";
import { FileAttachedProps } from "utils/types";
import { LoadUpIcon } from "assets/icons";
import { FileItem } from "components/FileItem";
import { Text } from "styles/common";
import { Content, Header, DownloadAll, FilesList } from "./styles";

interface AttachedFilesProps {
	files: FileAttachedProps[];
	name?: string;
	id?: number;
}
export const AttachedFiles = ({ files, id, name }: AttachedFilesProps) => {
	const request = useRequest;
	const url = `${baseApiUrl}/applications/${id}/zip-archive-download/`;

	const getZip = () => {
		const config = {
			data: {},
			responseType: "blob",
		};

		request(url, config).then((res) => {
			downloadResponse(res, name);
		});
	};

	return (
		<Content>
			<Header>
				<Typography component='h3' variant='h3'>
					Прикреплённые файлы
				</Typography>
				{files.length > 0 && (
					<DownloadAll onClick={getZip}>
						<LoadUpIcon />
						<Typography component='span'>Скачать всё</Typography>
					</DownloadAll>
				)}
			</Header>
			<SwitchTransition mode='out-in'>
				<CSSTransition
					key={files.length > 0 ? "list" : "empty"}
					timeout={300}
					classNames='fade'>
					{files.length > 0 ? (
						<FilesList>
							<TransitionGroup component={null}>
								{files.map(({ id, ...other }) => (
									<CSSTransition key={id} classNames='grow' timeout={250}>
										<FileItem id={id} {...other}></FileItem>
									</CSSTransition>
								))}
							</TransitionGroup>
						</FilesList>
					) : (
						<Text>Пока что нет прикреплённых файлов</Text>
					)}
				</CSSTransition>
			</SwitchTransition>
		</Content>
	);
};

export default AttachedFiles;
