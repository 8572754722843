import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { baseApiUrl } from "utils/constants";
import { useRequest } from "utils/hooks";
import { setApplicationContent } from "utils/store";
import {
	VotingObjProps,
	ApplicationType,
	ApplicationSubТуpe,
} from "utils/types";
import { AvatarLink } from "components/Avatar";
import { Button } from "components/FormElements";
import { Container, ButtonWrapper, Users, AvatarWrapper } from "./styles";
import { useEffect, useState } from "react";

interface VotingProps {
	id: number;
	api: string;
	voting?: VotingObjProps;
	type: "commission_controls" | "presidium";
	appType?: ApplicationType;
	subType?: ApplicationSubТуpe;
}

const votes = [
	{
		id: 1,
		title: "За",
		name: "votedFor",
		value: true,
	},
	{
		id: 2,
		title: "Против",
		name: "against",
		value: false,
	},
	{
		id: 3,
		title: "Воздержаться",
		name: "abstained",
		value: null,
	},
];

export const Voting = ({
	id,
	api,
	voting,
	type,
	appType,
	subType,
}: VotingProps) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const request = useRequest;
	const url = `${baseApiUrl}/${api}/add-vote/`;
	const [variants, setVariants] = useState(votes);

	useEffect(() => {
		if (
			type === "commission_controls" ||
			appType === "vv" ||
			appType === "odo" ||
			appType === "oo" ||
			subType === "odo_cut" ||
			subType === "odo_increase" ||
			subType === "odo_receiving" ||
			subType === "odo_termination" ||
			subType === "vv_increase"
		) {
			setVariants(votes.filter((item) => item.id !== 3));
		} else {
			setVariants(votes);
		}
	}, [type]);

	const addVote = (value: boolean | null) => {
		const params = {
			method: "PUT",
			data: {
				id,
				vote: value,
			},
		};

		request(url, params).then((res) => {
			const { data, status } = res;
			closeSnackbar();

			if (status >= 200 && status < 300) {
				dispatch(setApplicationContent(data));
			} else {
				const { errors } = data;

				enqueueSnackbar("При отправке запроса возникла ошибка!", {
					variant: "error",
					description: errors[0] ? errors[0].message : `Error ${status}`,
					autoHideDuration: null,
				});
			}
		});
	};

	return (
		<Container className='voting'>
			{variants.map(({ id, title, name, value }) => (
				<ButtonWrapper key={id}>
					<Button
						variant='outlined'
						onClick={() => addVote(value)}
						className={`vote ${
							voting && voting[name]
								? voting[name].checked
									? "checked"
									: "disabled"
								: ""
						}`}
						secondary
						fullWidth>
						{title}
					</Button>
					{voting && voting[name] && (
						<Users>
							{voting[name].users.map(({ fullName, id, color, avatar }) => (
								<AvatarWrapper key={id}>
									<AvatarLink
										image={avatar}
										color={color}
										name={fullName}
										id={id}
									/>
								</AvatarWrapper>
							))}
						</Users>
					)}
				</ButtonWrapper>
			))}
		</Container>
	);
};
