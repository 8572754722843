import { ListLayout } from "components/ListLayout";

const ActsPage = () => {
	return (
		<ListLayout
			title='Реестр актов для выписки'
			url='aсts-issued'
			api='acts'
			type='applications'
		/>
	);
};

export default ActsPage;
