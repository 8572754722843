import { styled } from "@mui/material/styles";
import {
	Box,
	Typography,
	LinearProgress as LinearProgressMui,
} from "@mui/material";
import { robotoRegular } from "styles/fonts";
import { black, grey, line, blue } from "styles/colors";

interface ContainerProps {
	delay?: number;
}

interface ProgressProps {
	show: string;
}

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: all 0.25s ease-in-out;
	margin: 20px 0;
	height: 52px;

	&.grow-enter {
		opacity: 0;
		height: 0;
		${({ delay }: ContainerProps) =>
			delay ? `transition-delay: ${delay}ms;` : `transition-delay: 0;`}
	}

	&.grow-enter.grow-enter-active {
		opacity: 1;
		height: 52px;
	}

	&.grow-exit {
		opacity: 0;
		height: 0;
		margin: 0;
	}

	&.grow-exit.fade-exit-active {
		opacity: 0;
		height: 0;
		margin: 0;
	}
`;

export const Content = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 0;
`;

export const LeftBlock = styled(Box)`
	display: grid;
	grid-template-columns: 52px auto;
	align-items: center;
`;

export const RightBlock = styled(Box)`
	display: flex;
	align-items: center;
`;

export const Title = styled(Typography)`
	font-family: ${robotoRegular};
	font-size: 15px;
	line-height: 130%;
	color: ${black};
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const Description = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 3px;
	overflow: hidden;
`;

export const ImageWrapper = styled(Box)`
	width: 34px;
	height: 34px;
	margin-right: 18px;
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${grey};
	overflow: hidden;
	height: ${({ show }: ProgressProps) => (show === "true" ? "100%" : 0)};
	transition: all 0.25s ease-in-out;
`;

export const ProgressValue = styled(Typography)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 140%;
	color: ${grey};
	margin-right: 12px;
	opacity: ${({ show }: ProgressProps) => (show === "true" ? 1 : 0)};
	transition: all 0.25s ease-in-out;
`;

export const LinearProgress = styled(LinearProgressMui)`
	height: 2px;
	background-color: ${line};
	border-radius: 4px;
	opacity: ${({ show }: ProgressProps) => (show === "true" ? 1 : 0)};
	transition: all 0.25s ease-in-out;

	.MuiLinearProgress-bar {
		background-color: ${blue};
	}
`;
