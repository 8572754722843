import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { black } from "styles/colors";

export const PopupContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	gap: 30px;
	width: 700px;
`;

export const Label = styled(Box)`
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0em;
	color: ${black};
	margin-bottom: -20px;
`;
