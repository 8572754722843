import { styled } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

export const Avatar = styled(Skeleton)`
	width: 50px;
	height: 50px;
	margin-right: 14px;
`;
export const Title1 = styled(Skeleton)`
	height: 28px;
	width: 420px;
`;

export const Title2 = styled(Skeleton)`
	height: 24px;
	width: 420px;
`;

export const Chip1 = styled(Skeleton)`
	height: 24px;
	width: 140px;
`;

export const Chip2 = styled(Skeleton)`
	height: 24px;
	width: 200px;
`;

export const Button = styled(Skeleton)`
	width: 30px;
	height: 30px;
	margin-right: 8px;
`;
