import styled from "styled-components";
import { line, white } from "styles/colors";
import { mediumDesktop } from "styles/media";

const Wrapper = styled.div`
	min-width: 265px;
	max-width: 265px;
	height: 100vh;
	background: ${white};
	border-right: 1px solid ${line};
	position: sticky;
	top: 0;
	z-index: 100;

	@media ${mediumDesktop} {
		min-width: 280px;
		max-width: 280px;
	}
`;

const Navigation = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 0;
`;

export { Wrapper, Navigation };
