import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Typography } from "@mui/material";
import { HistoryItemProps } from "utils/types";
import { Scrollbar } from "components/Scrollbar";
import { HistoryItem } from "./HistoryItem";
import { Wrapper, Content, Header, HistoryList } from "./styles";

interface HistoryProps {
	list: HistoryItemProps[];
	isEmployee: boolean;
}

export const History = ({ list, isEmployee }: HistoryProps) => {
	return (
		<Wrapper>
			<Content>
				<Header>
					<Typography component='h3' variant='h3'>
						История статусов
					</Typography>
				</Header>
				<Scrollbar height={433} width='calc(100% - 6px)' noContentHeight>
					{list && list.length > 0 && (
						<HistoryList>
							<TransitionGroup component={null}>
								{list.map(({ id, ...other }) => (
									<CSSTransition key={id} classNames='grow' timeout={250}>
										<HistoryItem id={id} {...other} avatarLink={isEmployee} />
									</CSSTransition>
								))}
							</TransitionGroup>
						</HistoryList>
					)}
				</Scrollbar>
			</Content>
		</Wrapper>
	);
};

export default History;
