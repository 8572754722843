import styled from "styled-components";
import { robotoRegular } from "styles/fonts";
import { grey, black } from "styles/colors";

const memberStyles = {
	Wrapper: styled.div`
		width: 100%;
		padding: 8px 0;
		display: flex;
		align-items: center;
	`,
	MemberInfo: styled.div`
		font-family: ${robotoRegular};
		margin-left: 8px;
	`,
	ImageWrapper: styled.div`
		position: relative;
		width: 38px;
		min-width: 38px;
		height: 38px;
		display: flex;
    flex-shrink: 0;
	`,
	Title: styled.div`
		font-size: 15px;
		line-height: 23px;
		color: ${black};
	`,
	SubTitle: styled.div`
		font-size: 12px;
		line-height: 17px;
		color: ${grey};
	`,
};

export default memberStyles;
