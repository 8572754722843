import { useEffect } from "react";
import { addTitlePage } from "utils/helpers";
import { LoginForm } from "components/Auth/LoginForm";

const Login = () => {
	useEffect(() => {
		addTitlePage("/login");
	}, []);

	return <LoginForm />;
};

export default Login;
