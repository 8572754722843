import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "utils/hooks/useApiRequest";
import authDataReducer from "./slices/authData";
import mainLoaderReducer from "./slices/mainLoader";
import userReducer from "./slices/user";
import userDetailReducer from "./slices/userDetail";
import applicationContentReducer from "./slices/applicationContent";
import roleContentReducer from "./slices/roleContent";

const store = configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		authData: authDataReducer,
		mainLoader: mainLoaderReducer,
		user: userReducer,
		applicationContent: applicationContentReducer,
		userDetail: userDetailReducer,
		roleContent: roleContentReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
