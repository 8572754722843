import { useEffect } from "react";
import { Typography } from "@mui/material";
import { addTitlePage } from "utils/helpers";
import { useUser } from "utils/store/hooks";
import { privacyPolicy } from "assets/data/privacyPolicy";
import { Container, Content } from "./styles";

export const PrivacyPolicy = () => {
	const { title, content } = privacyPolicy;
	const user = useUser();

	useEffect(() => {
		addTitlePage("/privacy-policy");
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const createMarkup = (content: string) => ({ __html: content });

	return (
		<Container auth={`${user !== null}`}>
			<Typography component='h2' variant='h2'>
				{title}
			</Typography>
			<Content dangerouslySetInnerHTML={createMarkup(content)} />
		</Container>
	);
};

export default PrivacyPolicy;
