import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
	selectApplicationContent,
	selectApplicationId,
} from "utils/store/slices/applicationContent";

export const useApplicationContent = () => {
	const { applicationContent } = useSelector(selectApplicationContent);
	return useMemo(() => applicationContent, [applicationContent]);
};

export const useApplicationId = () => {
	const id = useSelector(selectApplicationId);
	return useMemo(() => id, [id]);
};
