import styled from "styled-components";
import { robotoRegular } from "styles/fonts";
import { grey } from "styles/colors";

const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
`;

const Content = styled.div`
	font-family: ${robotoRegular};
	font-size: 12px;
	line-height: 140%;
	color: ${grey};
`;

export { Container, Content };
