export const applicationsTypes = [
	{
		id: 1,
		value: "vv",
		title: "Заявка ВВ",
		description:
			"Увеличение возможной стоимости выполнения работ в рамках частных контрактов",
		inputs: [
			{
				id: 11,
				value: "",
				label: "Уровень ответственности",
				field: "level",
				type: "select",
				placeholder: "",
				replaceName: true,
				options: [],
				required: true,
			},
			{
				id: 12,
				value: false,
				field: "priceManyContractExtraParam",
				type: "checkbox",
				label:
					"в т.ч. особо опасных, технически сложных и уникальных объектов капитального строительства, за исключением объектов использования атомной энергии",
			},
		],
	},
	{
		id: 2,
		value: "odo",
		title: "Заявка ОДО",
		description:
			"Приобретение и увеличение права выполнения работ в рамках бюджетных контрактов",
		inputs: [
			{
				id: 21,
				value: "",
				label: "Подтип",
				field: "subtype",
				type: "select",
				placeholder: "Приобретение",
				options: [
					{ value: "Приобретение", name: "Приобретение" },
					{ value: "Увеличение", name: "Увеличение" },
					{ value: "Прекращение", name: "Прекращение" },
					{ value: "Сокращение", name: "Сокращение" },
				],
			},
			{
				id: 22,
				value: "",
				label: "Уровень ответственности",
				field: "level",
				type: "select",
				replaceName: true,
				placeholder: "",
				options: [],
			},
			{
				id: 23,
				value: false,
				field: "priceManyContractExtraParam",
				type: "checkbox",
				label:
					"в т.ч. особо опасных, технически сложных и уникальных объектов капитального строительства, за исключением объектов использования атомной энергии",
			},
		],
	},
	{
		id: 3,
		value: "oo",
		title: "Заявка ОО",
		description:
			"Приобретение права выполнения работ на особо опасных и технически сложных объектах",
		inputs: [
			{
				id: 31,
				value: "",
				label: "Подтип",
				field: "subtype",
				type: "select",
				placeholder: "Приобретение",
				options: [
					{ value: "Приобретение", name: "Приобретение" },
					{ value: "Прекращение", name: "Прекращение" },
				],
			},
		],
	},
	{
		id: 4,
		value: "extract",
		title: "Получение выписки из реестра членов СРО",
	},
	{
		id: 5,
		value: "termination",
		title: "Прекращение членства",
	},
];

export const odoLevel = {
	id: 22,
	value: "",
	label: "Уровень ответственности",
	field: "level",
	type: "select",
	placeholder: "",
	replaceName: true,
	options: [],
};

export const odoLevelExtraParam = {
	id: 23,
	value: false,
	field: "priceManyContractExtraParam",
	type: "checkbox",
	label:
		"в т.ч. особо опасных, технически сложных и уникальных объектов капитального строительства, за исключением объектов использования атомной энергии",
};

export const extractInputs = [
	{
		id: 1,
		value: "",
		field: "fullNameEmployee",
		label: "ФИО работника",
		required: true,
	},
	{
		id: 2,
		value: "",
		field: "idEmployee",
		label: "Идентификационный номер работника",
		required: true,
	},
	{
		id: 3,
		value: "",
		field: "positionEmployee",
		label: "Должность работника",
		required: true,
	},
	{
		id: 4,
		type: "date",
		value: "",
		field: "dateRegistryEmployee",
		label: "Дата внесения в НРС",
		required: true,
	},
];
