import { ListLayout } from "components/ListLayout";

const EmployeesPage = () => {
	return (
		<ListLayout
			title='Сотрудники'
			url='staff'
			api='users/employees'
			type='users'
		/>
	);
};

export default EmployeesPage;
