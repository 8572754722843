import { Box } from "@mui/material";
import { HistoryItemProps } from "utils/types";
import { FileItem } from "components/FileItem";
import { Avatar, AvatarLink } from "components/Avatar";
import { Status } from "components/Status";
import {
	Content,
	Wrapper,
	Header,
	ImageWrapper,
	Title,
	Text,
	TopBlock,
	BottomBlock,
	FilesList,
	Link,
	Comment,
} from "./styles";

interface HistoryItemComponentProps extends HistoryItemProps {
	avatarLink: boolean;
}

export const HistoryItem = ({
	createdAt,
	user: { fullName, avatar, color, id },
	status,
	comment,
	files,
	type,
	avatarLink,
}: HistoryItemComponentProps) => {
	const date = new Date(createdAt);
	const title =
		type && type === "status" ? "Статус изменён на" : "Загружен документ(ы)";
	const avatarProps = {
		image: avatar,
		name: fullName,
		color,
	};

	return (
		<Wrapper>
			<Header>
				<Content>
					<TopBlock>
						<Text>
							{date.toLocaleDateString()} {date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
						</Text>
						<Text>{fullName}</Text>
					</TopBlock>
					<BottomBlock>
						<Title>{title}</Title>
						{status && <Status status={status} type='application' rounded />}
					</BottomBlock>
				</Content>
				<ImageWrapper>
					<Avatar {...avatarProps} />
				</ImageWrapper>
			</Header>
			<Box>
				{files && (
					<FilesList>
						{files.map(({ id, url, ...other }) => (
							<Link key={id} underline='none'>
								<FileItem type='link' id={id} url={url} {...other} />
							</Link>
						))}
					</FilesList>
				)}
				{comment && <Comment>{comment}</Comment>}
			</Box>
		</Wrapper>
	);
};

export default HistoryItem;
