import { ReactNode } from "react";
import { IconButtonMui } from "./styles";

interface IconButtonProps {
	children: ReactNode;
	color?: "error";
	onClick?: (value: any) => void;
	url?: string;
	className?: string;
}

export const IconButton = ({
	children,
	color,
	onClick,
	url,
	className,
}: IconButtonProps) => {
	const buttonToggle = () => {
		if (onClick) {
			onClick("onClick");
		}
	};

	return (
		<IconButtonMui color={color} onClick={buttonToggle} className={className}>
			{children}
		</IconButtonMui>
	);
};
