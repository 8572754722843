import { StatusPropType, StatusVariant, StatusProps } from "utils/types";
import { ClockRoundIcon, CheckRoundIcon, WarningRoundIcon } from "assets/icons";
import { StatusContainer } from "./styles";

interface StatusComponentProps {
	status: StatusProps;
	type?: StatusPropType;
	rounded?: boolean;
	size?: string;
}

export const Status = ({
	status,
	type,
	rounded,
	size,
}: StatusComponentProps) => {
	let variant: StatusVariant;
	let Icon;
	const isAppType = type && type === "application";
	switch (status.type) {
		case "no":
		case "deny":
		case "inactive":
		case "outdated":
			variant = "red";
			if (isAppType && status.type !== "outdated") Icon = WarningRoundIcon;
			break;
		case "act_closed":
		case "agenda_closed":
		case "presidium_closed":
		case "application_closed":
		case "yes":
		case "standart":
		case "expert_opinion_ready":
		case "commission_control_ready":
		case "accept":
		case "active":
			variant = "green";
			if (isAppType) Icon = CheckRoundIcon;
			break;
		case "agenda_created":
		case "act_created":
		case "presidium_created":
		case "application_created":
		case "expert_opinion_created":
		case "commission_control_created":
			variant = "yellow";
			if (isAppType) Icon = WarningRoundIcon;
			break;
		case "postponed":
			variant = "grey";
			break;
		case "application_redo":
			variant = "purple";
			if (isAppType) Icon = ClockRoundIcon;
			break;
		default:
			variant = "default";
			if (isAppType) Icon = ClockRoundIcon;
			break;
	}

	return (
		<StatusContainer
			className={size}
			type={type}
			variant={variant}
			rounded={rounded ? `${rounded}` : undefined}>
			{isAppType && Icon ? <Icon /> : null}
			{status.name}
		</StatusContainer>
	);
};
