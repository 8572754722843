import { ThemeProvider } from "@mui/material";
import { useUser } from "utils/store/hooks";
import { theme } from "styles/theme";
import { Header } from "components/Header";
import { NavMenu } from "components/NavMenu";
import { Footer } from "components/Footer";
import { Logotype } from "components/Logotype";
import { LayoutContent } from "./LayoutContent";
import { layoutStyles } from "./styles";

const { Container, Wrapper, HeaderAuth } = layoutStyles;

export const Layout = () => {
	const isAuth = useUser();

	return (
		<ThemeProvider theme={theme}>
			{isAuth ? (
				<Wrapper>
					<NavMenu />
					<Container>
						<Header />
						<LayoutContent />
					</Container>
				</Wrapper>
			) : (
				<Container>
					<HeaderAuth>
						<Logotype />
					</HeaderAuth>
					<LayoutContent auth />
					<Footer />
				</Container>
			)}
		</ThemeProvider>
	);
};
