import { styled } from "@mui/material";
import { Badge, Box } from "@mui/material";
import { robotoMedium, robotoRegular } from "styles/fonts";
import {
	white,
	black,
	background,
	liteGrey,
	red,
	line,
	grey,
} from "styles/colors";
import {
	Button as ButtonParent,
	Icon as IconHeader,
} from "components/Header/styles";

export const Counter = styled(Badge)`
	position: absolute;
	top: 4px;
	right: 4px;
	background: ${red};
	border: 2px solid ${white};
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.25s ease-in-out;

	.MuiBadge-badge {
		height: 17px;
		min-width: 17px;
		padding: 0 4px;
		font-family: ${robotoMedium};
		font-size: 9px;
		line-height: 9px;
		color: ${white};
		position: static;
		transform: none;
	}
`;

export const Button = styled(ButtonParent)`
	&.active {
		background: ${background};
		border: 1px solid ${liteGrey};

		.icon {
			svg {
				stroke: ${black};
			}
		}

		.count {
			border: 2px solid ${background};
		}
	}
`;

export const Icon = styled(IconHeader)`
	transform: translateX(0);
`;

export const Content = styled(Box)`
	width: 500px;
	position: relative;

	&::before {
		content: "";
		display: block;
		width: 22px;
		height: 22px;
		position: absolute;
		right: 26px;
		top: -7px;
		background: #ffffff;
		transform: rotate(-45deg);
	}
`;

export const InfoBlock = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid ${line};
	padding: 16px;
`;

export const Text = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 20px;
	color: ${grey};
`;

export const Status = styled(Box)`
	display: flex;
	gap: 8px;
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 19px;
	color: ${white};
	background-color: ${red};
	padding: 2px 10px;
	border-radius: 30px;

	.MuiBadge-badge {
		position: static;
		transform: none;
		padding: 0;
		min-width: auto;
	}
`;

export const Empty = styled(Box)`
	font-family: ${robotoRegular};
	font-size: 14px;
	line-height: 20px;
	color: ${grey};
	text-align: center;
	padding: 30px 0;
`;
