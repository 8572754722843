import {
	CandidateIcon,
	MemberIcon,
	CopyIcon,
	StaffIcon,
	ClipboardIcon,
	CertificateIcon,
	MeetingIcon,
	TooltipRoundIcon,
	ArticleIcon,
	EnvelopeIcon,
	CardIcon,
	BellRingIcon,
} from "assets/icons";

export const navigation = [
	{
		id: 1,
		title: "Кандидаты в члены СРО",
		url: "/candidates",
		icon: CandidateIcon,
		codename: "view_candidate_sro_section",
	},
	{
		id: 2,
		title: "Члены СРО",
		url: "/members",
		icon: MemberIcon,
		codename: "view_member_sro_section",
	},
	{
		id: 3,
		title: "Реестр заявок",
		url: "/applications",
		icon: CopyIcon,
		codename: "view_application_registry_section",
	},
	{
		id: 4,
		title: "Сотрудники",
		url: "/staff",
		icon: StaffIcon,
		codename: "view_employees_section",
	},
	{
		id: 5,
		title: "Реестр ЭЗ",
		url: "/expert-opinions",
		icon: ClipboardIcon,
		codename: "view_expert_opinion_registry_section",
	},
	{
		id: 6,
		title: "Реестр ПКК",
		url: "/protocols-control-commission",
		icon: CertificateIcon,
		codename: "view_commission_control_registry_section",
	},
	{
		id: 7,
		title: "Реестр ПП",
		url: "/protocols-presidium",
		icon: MeetingIcon,
		codename: "view_presidium_protocol_registry_section",
	},
	{
		id: 8,
		title: "Реестр ВП",
		url: "/questions-agenda",
		icon: TooltipRoundIcon,
		codename: "view_agenda_registry_section",
	},
	{
		id: 9,
		title: "Реестр актов для выписки",
		url: "/aсts-issued",
		icon: ArticleIcon,
		codename: "view_acts_section",
	},
	{
		id: 10,
		title: "Роли",
		url: "/roles",
		icon: CardIcon,
		codename: "view_settings_role_section",
	},
	{
		id: 11,
		title: "Уведомления",
		url: "/notifications",
		icon: BellRingIcon,
		codename: "view_settings_notification_section",
	},
];

export const navigation2 = [
	{
		id: 1,
		title: "Мои заявки",
		url: "/applications",
		icon: CopyIcon,
		codename: "view_myapplication_section",
	},
	{
		id: 2,
		title: "Профиль",
		url: "/profile",
		icon: MemberIcon,
		codename: "view_profile_section",
	},
];
