import { ArrowIcon } from "assets/icons";
import { NoticeItemProps } from "utils/types";
import { getPathByType } from "utils/helpers";
import { Status } from "components/Status";
import { IconButton } from "components/IconButton";
import {
	Wrapper,
	Conteiner,
	LeftBlock,
	LeftTopBlock,
	Title,
	Text,
} from "./styles";

interface NoticeItemConponentProps extends NoticeItemProps {
	onClick: () => void;
}

export const NoticeItem = ({
	createdAt,
	contentObject,
	status,
	type,
	onClick,
}: NoticeItemConponentProps) => {
	const date = new Date(createdAt);
	const url = `${getPathByType(type)}${contentObject.id}`;

	return (
		<Wrapper to={url} onClick={onClick}>
			<Conteiner>
				<LeftBlock>
					<LeftTopBlock>
						<Title className='list-item-content'>{contentObject.name}</Title>
						<Status status={status} type='application' rounded />
					</LeftTopBlock>
					<Text>
						{date.toLocaleDateString()}, {date.toLocaleTimeString()}
					</Text>
				</LeftBlock>
				<IconButton className='arrow-button'>
					<ArrowIcon />
				</IconButton>
			</Conteiner>
		</Wrapper>
	);
};
